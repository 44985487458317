import { Typography } from '@mui/material';
import clsx from 'clsx';

export const TextWithLabel = ({ children, title, childrenClasses, titleClasses, ...props }) => {
  return (
    <div {...props} className="mb-16">
      <Typography className={clsx('text-slate-300 text-base font-light', titleClasses)} gutterBottom>
        {title}
      </Typography>
      <Typography className={clsx('text-slate-300 text-xl', childrenClasses)} gutterBottom>
        {children}
      </Typography>
    </div>
  );
};
