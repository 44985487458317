import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { findNotifications } from 'src/app/api/admin';

export const getNotifications = createAsyncThunk('notificationPanel/getData', async () => {
  const response = await findNotifications();
  const data = await response?.data;
  const filteredData = await data.map((notification) => {
    return _.omit(notification, [
      'targetModel',
      'targetId',
      'targetPlatform',
      'updated_at',
      'deleted_at',
      'response',
      'attempts',
    ]);
  });
  return filteredData;
});
