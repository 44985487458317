import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

export const InputSearch = ({
  onChange = () => {},
  value,
  onClear = () => {},
  onSearch = () => {},
  unlink = false,
  helper,
  placeholder,
  sx,
}) => {
  // const [searchValue, setSearchValue] = useState('');

  const handleChange = ({ target }) => {
    onChange(target.value);
  };

  const handleClear = () => {
    onChange('');
    onClear();
  };

  return (
    <Paper
      variant="outlined"
      component="form"
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '4.2rem',
        borderColor: '#BBB',
      }}
    >
      <IconButton sx={{ p: '10px' }} aria-label="menu" onClick={() => onSearch()}>
        <SearchIcon />
      </IconButton>
      <InputBase
        sx={sx}
        size="small"
        fullWidth
        value={value}
        onChange={handleChange}
        placeholder={placeholder || 'Buscar'}
        className="text-base mt-3"
        inputProps={{ 'aria-label': 'Buscar' }}
        align="center"
      />
      {helper && (
        <>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          {helper}
        </>
      )}
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton
        size="small"
        type="button"
        sx={{ p: '10px' }}
        aria-label="search"
        onClick={() => handleClear()}
      >
        <ClearIcon />
      </IconButton>
    </Paper>
  );
};
