import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRequest } from 'src/app/hooks/useRequest';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { styled } from '@mui/material/styles';
import { Chip, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {
  BasicSelect,
  ButtonWithExpand,
  ComposedHeader,
  ComposedTable,
  InputSearch,
  Paginator,
} from 'src/app/components';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import Button from '@mui/material/Button';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import { useSimpleAlert } from 'src/app/hooks/useSimpleAlert';
import { formatDate } from 'src/app/utils/dates';
import {
  searchLicenses,
  catalogLocation,
  catalogStatus,
  catalogTypes,
  exportFileLicense,
} from 'src/app/api/torniquet';
import { useExtractSelector } from 'src/app/hooks/useExtractSelector';
import { useDownloadBlob } from 'src/app/hooks/useDownloadBlob';
import { typeDownloadEnum } from 'src/app/utils/enums';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {},
  '& .FusePageSimple-content': {},
}));

const TERMINAL_TYPE = 'torniquet';

const tableRowScheme = [
  {
    title: 'Clave',
    minWidth: '200px',
  },
  {
    title: 'Nombre',
    minWidth: '200px',
  },
  {
    title: 'Estatus',
    minWidth: '140px',
  },
  {
    title: 'Tipo',
    minWidth: '180px',
  },
  {
    title: 'ID orden',
    minWidth: '180px',
  },
  {
    title: 'ID licencia',
    minWidth: '180px',
  },
  {
    title: 'Clave sucursal',
    minWidth: '200px',
  },
  {
    title: 'Clave terminal',
    minWidth: '200px',
  },
  {
    title: 'Nombre terminal',
    minWidth: '180px',
  },
  {
    title: 'Fecha de Registro',
    minWidth: '180px',
  },
];

export const Licenses = () => {
  const Dispatch = useDispatch();
  const openAlert = useSimpleAlert();
  // ------ States correspondiente a componentes de filtrado -------
  const [dataList, setDataList] = useState([]);
  const [querySearch, setQuerySearch] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentItemsPerPage, setCurrentItemsPerPage] = useState(25);
  const [datePickerValue, setDatePickerValue] = useState([null, null]);
  const [form, setForm] = useState({ query: '', initDate: '', endDate: '', paginate: undefined });
  // ----- Instancia de request que se usaran en la vista junto a sus valores de estado ---------
  const { makeRequest: tryGetData, isLoading, response } = useRequest(searchLicenses);
  // ------- Selects --------------
  const [statusSelect, setStatusSelect] = useState('');
  const [locationSelect, setLocationSelect] = useState('');
  const [typeSelect, setTypeSelect] = useState('');
  // ----- Customs States ----------
  const [reactiveTableScheme, setReactiveTableScheme] = useState(tableRowScheme);

  // ------ Requests ---------------------------------
  const { dataSelect: locationDataSelect, makeRequest: locationTryRequest } = useExtractSelector(
    catalogLocation,
    {
      emptyOption: true,
    }
  );
  const { dataSelect: statusDataSelect, makeRequest: statusTryRequest } = useExtractSelector(
    catalogStatus,
    {
      emptyOption: true,
    }
  );
  const { dataSelect: typeDataSelect, makeRequest: typeTryRequest } = useExtractSelector(
    catalogTypes,
    {
      emptyOption: true,
    }
  );

  // ----- File List exports -----
  const { catalogFormats, downloadBlobFile } = useDownloadBlob(typeDownloadEnum, exportFileLicense);

  const handleExportFile = async (format = 'txt') => {
    await downloadBlobFile(format, 'contactos');
  };

  // ---- Borra todos los filtros ------
  const handleClearAllFilters = () => {
    setQuerySearch('');
    setCurrentPage(1);
    setCurrentItemsPerPage(25);
    setDatePickerValue([null, null]);
    // ---- Selects
    setStatusSelect('');
    setLocationSelect('');
    setTypeSelect('');
  };

  // ----- Actualiza el formulario acorde cambio de filtros
  useEffect(() => {
    setForm((current) => {
      return {
        ...current,
        query: querySearch,
        paginate: currentItemsPerPage,
        initDate: datePickerValue[0] === null ? '' : datePickerValue[0],
        endDate: datePickerValue[1] === null ? '' : datePickerValue[1],
        statusActive: statusSelect === '' ? null : statusSelect.toString(),
        location_key: locationSelect === '' ? null : locationSelect.toString(),
        type: typeSelect === '' ? null : typeSelect.toString(),
        terminalType: TERMINAL_TYPE,
      };
    });
  }, [querySearch, currentItemsPerPage, datePickerValue, statusSelect, locationSelect, typeSelect]);

  // ------ Actualiza la información de la tabla después de la petición -------
  useEffect(() => {
    console.log(response);
    if (!response) {
      return;
    }
    setDataList(response.data);
    setTotalPages(response.last_page);
    setTotalItems(response.total);
    setCurrentPage(response.current_page);
  }, [response]);

  // -------- Realiza la petición de datos al haber un cambio en el formulario -----
  useEffect(() => {
    if (form === undefined) {
      return;
    }
    console.log('FROM | QUERY => ', form);
    tryGetData(form, currentPage);
  }, [currentPage, form]);

  // ------ Inicialización de la vista con toda la información disponible -------
  useEffect(() => {
    tryGetData(form);
    return () => {
      setDataList([]);
    };
  }, []);

  // ------ Funciones para actualizar los filtros ------
  const handleSearchQuery = (query = '') => {
    if (!query.length > 3) {
      return;
    }
    setQuerySearch(query);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleChangeItems = (items) => {
    setCurrentItemsPerPage(items);
  };

  return (
    <Root
      header={
        <ComposedHeader
          breadcrumb
          title="Licencias"
          description="Información general de grupos registrados"
        >
          <div className="px-4 py-4">
            <ButtonWithExpand
              title="Descargar"
              className="whitespace-nowrap mx-4"
              variant="contained"
              color="success"
              startIcon={<SimCardDownloadOutlinedIcon />}
            >
              {catalogFormats.map((option, index) => (
                <ButtonWithExpand.Item
                  key={`${index}_${option.value}_doc`}
                  text={`Documento ${option.label}`}
                  onClick={() => handleExportFile(option.value)}
                />
              ))}
            </ButtonWithExpand>
          </div>
        </ComposedHeader>
      }
      content={
        <Grid container className="px-32 pb-32 pt-0 flex flex-row w-full justify-center">
          <Grid item xs={12} md={12} className="flex w-full">
            <Card className="pb-24 px-24 mt-16 w-full">
              <CardContent className="flex w-full h-full flex-col justify-between">
                <div className="flex flex-col mt-24">
                  <Grid container className="w-full flex justify-center items-center py-8 mb-28">
                    <Grid item xs={12} md={7}>
                      <div className="flex flex-row flex-wrap justify-start items-center">
                        <div className="ml-4 mr-8 my-2 w-8/12 md:my-0">
                          <InputSearch
                            value={querySearch}
                            onChange={(query) => handleSearchQuery(query)}
                          />
                        </div>
                        <div className="ml-4 my-2 md:my-0">
                          <Button
                            fullWidth
                            size="medium"
                            variant="contained"
                            color="primary"
                            onClick={handleClearAllFilters}
                          >
                            Limpiar filtros
                          </Button>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <div className="flex flex-row justify-center md:justify-end items-center md:mt-0 mt-8">
                        <LocalizationProvider>
                          <DateRangePicker
                            slotProps={{ textField: { size: 'small' } }}
                            localeText={{ start: 'Fecha inicial', end: 'Fecha final' }}
                            value={datePickerValue}
                            onChange={(current) => setDatePickerValue(current)}
                            disableFuture
                          />
                        </LocalizationProvider>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="p-2">
                    <Grid item xs={12} md={6} className="p-8 mb-24">
                      <div className="flex flex-row flex-wrap justify-start items-center">
                        <div className="px-4">
                          <BasicSelect
                            data={locationDataSelect}
                            onChange={(data) => setLocationSelect(data)}
                            title="Sucursal"
                            value={locationSelect}
                            size="small"
                          />
                        </div>
                        <div className="px-4">
                          <BasicSelect
                            data={statusDataSelect}
                            onChange={(data) => setStatusSelect(data)}
                            title="Estatus"
                            value={statusSelect}
                            size="small"
                          />
                        </div>
                        <div className="px-4">
                          <BasicSelect
                            data={typeDataSelect}
                            onChange={(data) => setTypeSelect(data)}
                            title="Tipo"
                            value={typeSelect}
                            size="small"
                          />
                        </div>
                        {/*
                          Agrega más selects
                        */}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} className="p-8 mb-24">
                      <div className="flex flex-row justify-end">
                        <Paginator
                          totalPages={totalPages}
                          totalItems={totalItems}
                          onChangeCurrentPage={(page) => handleChangePage(page)}
                          onChangeItemsPerPage={(items) => handleChangeItems(items)}
                          currentPage={currentPage}
                          currentItemsPerPage={currentItemsPerPage}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <div className="flex flex-col justify-center">
                    <ComposedTable
                      isLoading={isLoading}
                      className="w-full"
                      headers={reactiveTableScheme}
                      data={dataList}
                    >
                      <ComposedTable.Column content={({ key }) => key} />
                      <ComposedTable.Column content={({ name }) => name} />
                      <ComposedTable.Column
                        content={({ statusActive }) => (
                          <Chip
                            label={statusActive}
                            color={statusActive === 'Activo' ? 'success' : 'error'}
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                      <ComposedTable.Column content={({ type }) => type} />
                      <ComposedTable.Column content={({ orderNumber }) => orderNumber} />
                      <ComposedTable.Column content={({ licenseId }) => licenseId} />
                      <ComposedTable.Column content={(item) => item.location_key} />
                      <ComposedTable.Column content={(item) => item.terminal_key} />
                      <ComposedTable.Column content={({ terminalName }) => terminalName} />
                      <ComposedTable.Column content={(item) => formatDate(item.created_at)} />
                    </ComposedTable>
                  </div>
                </div>
                <Grid container spacing={1} className="p-2 mt-24 flex">
                  <Grid item xs={12} md={12} className="py-8">
                    <div className="flex flex-row justify-end">
                      <Paginator
                        totalPages={totalPages}
                        totalItems={totalItems}
                        onChangeCurrentPage={(page) => handleChangePage(page)}
                        onChangeItemsPerPage={(items) => handleChangeItems(items)}
                        currentPage={currentPage}
                        currentItemsPerPage={currentItemsPerPage}
                      />
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      }
    />
  );
};
