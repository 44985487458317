import { Create, Edit, Groups } from './Children';

export const GroupsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      id: 'groupsGroup',
      path: 'groups',
      scopes: ['admin-group-show', 'admin-group-update'],
      children: [
        {
          scopes: ['admin-group-show'],
          id: 'groupsGroup.groups',
          path: '',
          element: <Groups />,
        },
        {
          scopes: ['admin-group-update'],
          id: 'groupsGroup.edit',
          path: 'edit/:id',
          element: <Edit />,
        },
      ],
    },
  ],
};
