import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { ComposedBreadcrumb } from '../ComposedBreadcrumb/ComposedBreadcrumb';

export const ComposedHeader = ({ helper, title, description, children, disableRoute = false, breadcrumb = false, ...props }) => {
  return (
    <div className="flex flex-col flex-1 w-full items-center justify-center space-y-8 sm:space-y-0 py-32 px-24 md:px-32">
      <div
        {...props}
        className="flex flex-col sm:flex-row flex-1 w-full items-center sm:items-end justify-between"
      >
        <div className="flex flex-col items-center sm:items-start space-y-8 sm:space-y-0 md:w-1/3 sm:max-w-full min-w-0">
          {breadcrumb && (
            <motion.div
              className="flex flex-row items-center mb-8 sm:items-start min-w-0 mx-8 sm:mr-14 sm:ml-10"
              initial={{ x: 20, opacity: 0 }}
              animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
            >
              <ComposedBreadcrumb disableRoute={disableRoute} />
            </motion.div>
          )}

          <div className="flex items-center max-w-full">
            <motion.div
              className="flex flex-col items-center sm:items-start min-w-0 mx-8 sm:mx-16"
              initial={{ x: -20, opacity: 0 }}
              animate={{ x: 0, transition: { delay: 0.3 }, opacity: 1 }}
            >
              <Typography className="text-16 sm:text-24 truncate font-semibold">{title}</Typography>
              <Typography variant="caption" className="font-medium">
                {description}
              </Typography>
            </motion.div>
          </div>
        </div>
        <motion.div
          className="md:w-2/3 sm:max-w-full min-w-0 h-full flex flex-auto flex-row"
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
        >
          <div className="w-full flex flex-wrap flex-row justify-center sm:justify-end">
            {children}
          </div>
        </motion.div>
      </div>
      {helper && (
        <motion.div
          className="flex w-full flex-col pt-16 pl-14"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 0.3 } }}
        >
          {helper}
        </motion.div>
      )}
    </div>
  );
};
