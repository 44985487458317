import { useParams } from 'react-router-dom';
import history from '@history';
import _ from 'src/@lodash/@lodash';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { ButtonWithLoader, ComposedHeader, FormCardLayout } from 'src/app/components';
import { useRequest } from 'src/app/hooks/useRequest';
import { updatePassword } from 'src/app/api/admin';

const Root = styled(FusePageSimple)(({ theme }) => ({
  backgroundColor: '#FFF',
  '& .FusePageSimple-header': {},
  '& .FusePageSimple-content': {},
}));

const schema = yup.object().shape({
  password: yup
    .string()
    .min(4, 'Contraseña muy corta - debe contener al menos 4 caracteres.')
    .nullable()
    .required(),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Las contraseñas no coinciden')
    .required(),
});

const defaultValues = {
  password: '',
  password_confirmation: '',
};

export const PasswordRecovery = () => {
  const { id: previousId } = useParams();
  const Dispatch = useDispatch();
  // ----- Inicializacion de validadores de formulario
  const { control, formState, handleSubmit, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  // ------ Request Store New Password ------
  const { makeRequest: tryRecoveryPassword, isLoading } = useRequest(updatePassword);

  const onSubmit = (form) => {
    const recoveryForm = {
      ...form,
    };
    console.log(JSON.stringify(recoveryForm, null, 3));
    /* tryRecoveryPassword(recoveryForm, previousId).then(({ isOk }) => {
      if (isOk) {
        Dispatch(
          showMessage({
            message: 'Información actualizada correctamente',
            autoHideDuration: 4000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            variant: 'success',
          })
        );
        history.replace('/admin/access');
      }
    }); */
  };

  return (
    <Root
      header={
        <ComposedHeader
          breadcrumb
          // title="Administradores"
          // description="Información general de accesos autorizados"
        />
      }
      content={
        <Box
          className="flex justify-center items-start"
          sx={{
            width: '100%',
            height: '100%',
          }}
        >
          <FormCardLayout title="Cambiar contraseña">
            <form
              autoComplete="off"
              name="adminRecoveryForm"
              noValidate
              className="flex flex-col justify-center w-full mt-8"
            >
              <FormCardLayout.FullColum>
                <div className="flex flex-col">
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-32"
                        label="Contraseña"
                        type="password"
                        error={!!errors.password}
                        helperText={errors?.password?.message}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="password_confirmation"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-32"
                        label="Confirmar contraseña"
                        type="password"
                        error={!!errors.password_confirmation}
                        helperText={errors?.password_confirmation?.message}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </div>
              </FormCardLayout.FullColum>
              <FormCardLayout.RowButton>
                <Button
                  onClick={() => history.back()}
                  variant="contained"
                  className="w-full md:w-auto"
                  color="info"
                >
                  Cancelar
                </Button>
                <ButtonWithLoader
                  onClick={handleSubmit(onSubmit)}
                  color="success"
                  aria-label="Cambiar contraseña"
                  variant="contained"
                  disabled={_.isEmpty(dirtyFields) || !isValid}
                  type="submit"
                  isLoading={isLoading}
                >
                  Cambiar
                </ButtonWithLoader>
              </FormCardLayout.RowButton>
            </form>
          </FormCardLayout>
        </Box>
      }
    />
  );
};
