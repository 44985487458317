import { Access, Configuration, Dashboard, Events, Licenses, Terminals } from './Children';

export const KiosksConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      id: 'kiosksGroup',
      path: 'kiosks',
      scopes: ['admin-kiosk-show', 'admin-kiosk-config'],
      children: [
        {
          id: 'kiosksGroup.dashboard',
          path: 'dashboard',
          element: <Dashboard />,
        },
        {
          id: 'kiosksGroup.access',
          path: 'access',
          element: <Access />,
        },
        {
          id: 'kiosksGroup.events',
          path: 'events',
          element: <Events />,
        },
        {
          scopes: ['admin-kiosk-show'],
          id: 'kiosksGroup.terminals',
          path: 'terminals',
          element: <Terminals />,
        },
        {
          id: 'kiosksGroup.licenses',
          path: 'licenses',
          element: <Licenses />,
        },
        {
          scopes: ['admin-kiosk-show'],
          id: 'kiosksGroup.configuration',
          path: 'configuration',
          element: <Configuration />,
        },
      ],
    },
  ],
};
