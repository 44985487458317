import { createSlice } from '@reduxjs/toolkit';

const modalsInitialState = {
  accessModalVisibility: false,
};

const modalsSlice = createSlice({
  name: 'modals',
  initialState: modalsInitialState,
  reducers: {
    setAccessModalVisibility: (state, { payload }) => {
      state.accessModalVisibility = payload.accessModalVisibility;
    },
  },
});

export default modalsSlice.reducer;
export const { setAccessModalVisibility } = modalsSlice.actions;
