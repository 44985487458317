import { Dashboard } from './Dashboard';

export const DashboardConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      id: 'main.dashboard',
      path: 'dashboard',
      element: <Dashboard />,
    },
  ],
}
