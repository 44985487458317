import { useEffect, useState, useLayoutEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { matchRoutes } from 'react-router-dom';
import withRouter from '@fuse/core/withRouter';
import history from '@history';
import { filterAsyncRoutes, hasPermission } from 'src/app/utils/routes';
import { clearRoutes, storeRoutes } from 'app/store/routes';
import {
  asyncRoutes,
  defaultLoginRedirect,
  defaultLogoutRedirect,
  whiteList,
} from 'app/configs/routesConfig';
import { matchNavigationRoutes } from 'app/store/fuse/navigationSlice';
import { checkAuthentication } from 'app/store/auth';
import { getAdminInfo } from 'src/app/api/admin';
import { removeAdminInfo, setAdminInfo } from 'app/store/userInfo';

function RoutesScope(props) {
  const Dispatch = useDispatch();
  const { location, userRole, userScopes } = props;
  const { pathname } = location;
  const { routes } = useSelector((state) => state.routes);
  const { isCheckingAuth, token } = useSelector((state) => state.auth);

  useEffect(() => {
    Dispatch(checkAuthentication());
  }, []);

  useEffect(() => {
    if (token !== undefined && token !== '' && token !== null) {
      getAdminInfo().then(({ success, data }) => {
        if (success && data) Dispatch(setAdminInfo(data));
      });
    } else {
      Dispatch(removeAdminInfo());
    }
  }, [token]);

  useLayoutEffect(() => {
    if (!isCheckingAuth) {
      if (token !== null) {
        // Si el token no es nulo, agrega rutas restantes de asyncRoutes
        const remainingRoutes = asyncRoutes.filter((route) => !whiteList.includes(route.path));
        const totalRoutes = filterAsyncRoutes(remainingRoutes, userScopes);
        Dispatch(storeRoutes(totalRoutes));
        setTimeout(() => history.replace(defaultLoginRedirect), 0);
      } else {
        // Si el token es nulo limpia las rutas
        Dispatch(clearRoutes());
        setTimeout(() => history.replace(defaultLogoutRedirect), 0);
      }
      Dispatch(matchNavigationRoutes());
    }
  }, [token, isCheckingAuth, userScopes]);

  useEffect(() => {
    const matchedRoutes = matchRoutes(routes, pathname);
    const matched = matchedRoutes ? matchedRoutes[0] : false;
    const userHasPermission = matched && hasPermission(matched.route, userScopes);
    // console.log('matched? ', matched.route);

    if (!userHasPermission) {
      if (token !== null) {
        setTimeout(() => history.replace(defaultLoginRedirect), 0);
      } else {
        setTimeout(() => history.replace(defaultLogoutRedirect), 0);
      }
    }
  }, [pathname, userRole, token]);

  return props.children;
}

export default withRouter(RoutesScope);
