import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { ComposedHeader } from 'src/app/components';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {},
  '& .FusePageSimple-content': {},
}));

export const Configuration = () => {
  return (
    <Root
      header={
        <ComposedHeader
          title="Configuracion general"
          description="Configuracion de manager"
          breadcrumb
        />
      }
      content={
        <Grid container spacing={6} className="p-32">
          <Grid item xs={12} md={12}>
            <div className="bg-orange-500" ><p><span>Put your content here</span></p></div>
          </Grid>
        </Grid>
      }
    />
  );
};
