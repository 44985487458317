import { Children, cloneElement, isValidElement, useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export const DashboardWidgets = ({ children }) => {
  return (
    <Paper className="flex flex-col flex-auto p-24 shadow rounded-2xl overflow-hidden">
      {children}
    </Paper>
  );
};

const SingleTable = ({ headers, data = {}, children }) => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-1 grid-flow-row gap-24 w-full mt-32 sm:mt-16">
      <TableContainer sx={{ margin: 0, padding: 0 }}>
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              {headers.map(({ title, width, maxWidth, align = 'center' }, index) => (
                <TableCell align={align} key={`${index}_${title}`} style={{ width, maxWidth }}>
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              {data &&
                Children.map(children, (child) => {
                  return cloneElement(child, { data });
                })}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const Header = ({ children, title }) => {
  return (
    <div className="flex flex-col md:flex-row sm:flex-col items-center justify-between">
      <Typography className="text-lg font-medium tracking-tight leading-6 truncate">
        {title}
      </Typography>
      {children}
    </div>
  );
};

const Column = ({ content = () => { }, data }) => {
  const handleRenderCol = () => {
    const rendered = content(data);
    return rendered;
  };
  return <TableCell align="center">{handleRenderCol()}</TableCell>;
};

const CardColumn = ({ children, ...props }) => {
  return <div {...props}>{children}</div>;
};

const CardsContainer = ({ children }) => {
  useEffect(() => {
    if (!children) return;
    const areAllChildrenCardColumn = Children.toArray(children).every(
      (child) => isValidElement(child) && child.type === CardColumn
    );

    if (!areAllChildrenCardColumn) {
      throw new Error('Todos los hijos de CardsContainer deben ser del tipo CardColumn');
    }
  }, [children]);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-1 grid-flow-row gap-24 w-full mt-32 sm:mt-16">
      <div className="flex flex-col">
        <div className="flex-auto grid grid-cols-4 gap-16 mt-24">{children}</div>
      </div>
    </div>
  );
};

DashboardWidgets.Header = Header;
DashboardWidgets.CardColumn = CardColumn;
DashboardWidgets.CardsContainer = CardsContainer;
DashboardWidgets.Column = Column;
DashboardWidgets.SingleTable = SingleTable;
