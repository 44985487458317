import { Edit, Users } from './Children';

export const UsersConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      id: 'usersGroup',
      path: 'users',
      scopes: ['admin-user-show', 'admin-user-update'],
      children: [
        {
          scopes: ['admin-user-show'],
          id: 'usersGroup.users',
          path: '',
          element: <Users />,
        },
        {
          scopes: ['admin-user-update'],
          id: 'usersGroup.edit',
          path: 'edit/:id',
          element: <Edit />,
        },
      ],
    },
  ],
};
