import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { styled } from '@mui/material/styles';
import history from '@history';
import IconButton from '@mui/material/IconButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Typography from '@mui/material/Typography';
import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { closeNotificationPanel, getNotifications, toggleNotificationPanel } from 'app/store/notifications';
import { NotificationCard } from './NotificationCard';

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: theme.palette.background.default,
    width: 320,
  },
}));

export const NotificationPanel = memo((props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { notifications, panel } = useSelector((state) => state.notifications);
  const { visibility } = panel;

  useEffect(() => {
    if (visibility) {
      dispatch(closeNotificationPanel());
    }
    // eslint-disable-next-line
	}, [location]);

  const handleClose = () => {
    dispatch(closeNotificationPanel());
  };

  const handleDismiss = (notificationId) => {
    console.log('Dismiss', notificationId);
    // dispatch(dismissItem(id));
  };

  const handleDismissAll = () => {
    console.log('Dismiss All');
    // dispatch(dismissAll());
  };

  const handleRedirect = (uriNotification) => {
    console.log('redirect to', uriNotification);
    history.push(uriNotification);
  };

  return (
    <StyledSwipeableDrawer
      open={visibility}
      anchor="right"
      onOpen={(ev) => {}}
      onClose={(ev) => dispatch(toggleNotificationPanel())}
      disableSwipeToOpen
    >
      <IconButton className="m-4 absolute top-0 right-0 z-999" onClick={handleClose} size="large">
        <FuseSvgIcon color="action">heroicons-outline:x</FuseSvgIcon>
      </IconButton>
      {notifications.length > 0 ? (
        <FuseScrollbars className="p-16">
          <div className="flex flex-col">
            <div className="flex justify-between items-end pt-136 mb-36">
              <Typography className="text-28 font-semibold leading-none">Notifications</Typography>
              <Typography
                className="text-12 underline cursor-pointer"
                color="primary"
                onClick={handleDismissAll}
              >
                Borrar todo
              </Typography>
            </div>
            {notifications.map((item) => (
              <NotificationCard
                key={item._id}
                className="mb-16"
                item={item}
                onClose={handleDismiss}
                onClick={handleRedirect}
              />
            ))}
          </div>
        </FuseScrollbars>
      ) : (
        <div className="flex flex-1 items-center justify-center p-16">
          <Typography className="text-24 text-center" color="text.secondary">
            No hay notificaciones por ahora.
          </Typography>
        </div>
      )}
    </StyledSwipeableDrawer>
  );
});
