import { createSlice } from '@reduxjs/toolkit';
import { asyncRoutes, staticRoutes, whiteList } from 'app/configs/routesConfig';
import _ from 'lodash';

const getAsyncFilteredRoutes = () => {
  const temporal = asyncRoutes.filter((route) => whiteList.includes(route.path));
  const mergedRoutes = _.unionBy([...staticRoutes, ...temporal], 'path');
  return mergedRoutes;
};
// ------ Authentication --------
const userInitialState = {
  routes: getAsyncFilteredRoutes(),
};
const routesSlice = createSlice({
  name: 'routes',
  initialState: userInitialState,
  reducers: {
    setRoutes: (state, { payload }) => {
      state.routes = payload.asyncNewRoutes;
    },
    resetRoutes: (state) => {
      state.routes = getAsyncFilteredRoutes();
    },
  },
});

export default routesSlice.reducer;
export const { setRoutes, resetRoutes } = routesSlice.actions;
