import request, { prefix } from '../services/request';

export function search(params) {
  return request({
    url: `/${prefix}/registry/search`,
    method: 'POST',
    params,
  });
}

export function searchLicenses(params) {
  return request({
    url: `/${prefix}/registry/license/search`,
    method: 'POST',
    params,
  });
}

export function searchPage(params, page) {
  return request({
    url: `/${prefix}/registry/search?page=${page}`,
    method: 'POST',
    params,
  });
}

export function indexRegistry(params = '') {
  return request({
    url: `/${prefix}/registry?${params}`,
    method: 'GET',
  });
}

export function getRegistryById(id, data) {
  return request({
    url: `/${prefix}/registry/${id}`,
    method: 'GET',
    data,
  });
}

export function exportFile(params) {
  return request({
    url: `/${prefix}/registry/export`,
    method: 'POST',
    responseType: 'blob',
    params,
  });
}

export function exportFileLicense(params) {
  return request({
    url: `/${prefix}/registry/export/license`,
    method: 'POST',
    responseType: 'blob',
    params,
  });
}

// -------------- Catalogos para combobox ------------------------

export function catalogLocation() {
  return request({
    url: `/${prefix}/registry/catalog/locations`,
    method: 'GET',
  });
}

export function catalogTypes() {
  return request({
    url: `/${prefix}/registry/catalog/liceses/types`,
    method: 'GET',
  });
}

export function catalogStatus() {
  return request({
    url: `/${prefix}/registry/catalog/liceses/status`,
    method: 'GET',
  });
}
