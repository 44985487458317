import request, { prefix } from '../services/request';

export function requestLogin(data) {
  return request({
    url: `/${prefix}/admin/login`,
    method: 'POST',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export function requestLogout() {
  return request({
    url: `/${prefix}/admin/logout`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}
