import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import history from '@history';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FusePageSimple from '@fuse/core/FusePageSimple';
import {
  ButtonWithLoader,
  ComposedHeader,
  FormCardLayout,
  ImagePicker,
  ListCheckers,
  MetadataExpandForm,
} from 'src/app/components';
import { showMessage } from 'app/store/fuse/messageSlice';
import _ from 'src/@lodash/@lodash';
import { regexTitle } from 'src/app/utils/validations';
import { useRequest } from 'src/app/hooks/useRequest';
import { useWithApiValidation } from 'src/app/hooks/useWithApiValidation';
import { validateEmail } from 'src/app/api/validations';
import { getUserById, updateUser } from 'src/app/api/users';

const Root = styled(FusePageSimple)(({ theme }) => ({
  backgroundColor: '#FFF',
  '& .FusePageSimple-header': {},
  '& .FusePageSimple-content': {},
}));

const locationsData = [
  {
    label: 'San Jerónimo',
    value: 'SJ',
  },
  {
    label: 'Santa Fé',
    value: 'STAFE',
  },
  {
    label: 'Satélite',
    value: 'SAT',
  },
  {
    label: 'Del Valle',
    value: 'VALLE',
  },
];

const schema = yup.object().shape({
  name: yup
    .string()
    .required('El nombre no puede quedar vacío')
    .min(3, 'El nombre debe contener al menos 3 letras')
    .matches(regexTitle, 'Nombre inválido'),
  email: yup
    .string()
    .email('Debes ingresar un correo válido')
    .required('El correo no puede quedar vacío'),
  phone: yup
    .string()
    // .required('El número telefónico no puede quedar vacío')
    .notRequired()
    .min(10, 'Verifique que el número tenga 10 cifras al menos'),
  address: yup.string(),
  locations: yup.array().min(1, 'Debes elegir al menos una opción'),
  status: yup.boolean(),
});

const defaultValues = {
  name: '',
  email: '',
  phone: '',
  address: '',
  locations: [],
  status: true,
};

export const Edit = () => {
  const { id: previousId } = useParams();
  const Dispatch = useDispatch();
  const [metadata, setMetadata] = useState({});
  const [metadataError, setMetadataError] = useState(false);
  const [avatar, setAvatar] = useState(undefined);
  const [previousImage, setPreviousImage] = useState(undefined);
  const [previousEmail, setPreviousEmail] = useState('');
  // ----- Inicializacion de validadores de formulario
  const { control, formState, handleSubmit, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  // ------ Request Get Group ------
  const { makeRequest: getPreviousData, response: responsePreviousData } = useRequest(getUserById);
  // ------ Request Update Group ------
  const { makeRequest: tryUpdate, isLoading } = useRequest(updateUser);

  // ------ Funcion para cargar los datos obtenidos de request
  const handleLoadToForm = (previousData) => {
    setPreviousImage(previousData.image);
    setPreviousEmail(previousData.email);
    const result = _.omit(previousData, [
      'updated_at',
      'created_at',
      '_id',
      'avatar',
      'image',
      'metadata',
    ]);
    console.log(result);
    const objKeys = Object.keys(result);
    objKeys.forEach((objKey) => {
      setValue(objKey, result[objKey], { shouldValidate: true, shouldDirty: true });
    });
  };

  // ------ Realiza request para cargar información del admin
  useEffect(() => {
    console.log('PARAMS ', previousId);
    getPreviousData(previousId);
  }, []);

  // ------ Escucha response de request de Admin
  useEffect(() => {
    console.log(responsePreviousData);
    if (!responsePreviousData) {
      return;
    }
    handleLoadToForm(responsePreviousData);
  }, [responsePreviousData]);

  // ------ handlers ------------
  const handleLoadImage = (currentImage) => {
    setAvatar(currentImage);
  };

  const handleUpdate = (form) => {
    const builtForm = {
      ...form,
      avatar,
      metadata,
      status: form.status.toString(),
    };
    console.log(JSON.stringify(builtForm, null, 3));
    tryUpdate(builtForm, previousId).then(({ isOk }) => {
      if (isOk) {
        Dispatch(
          showMessage({
            message: 'Usuario actualizado correctamente',
            autoHideDuration: 4000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            variant: 'success',
          })
        );
        history.replace('/users');
      }
    });
  };

  const {
    isUnique: uniqueEmail,
    errorMessage: errorMessageEmail,
    validateField: validateFieldEmail,
  } = useWithApiValidation('phone', validateEmail);

  return (
    <Root
      header={<ComposedHeader breadcrumb />}
      content={
        <Box
          className="flex justify-center items-start"
          sx={{
            width: '100%',
            height: '100%',
          }}
        >
          <FormCardLayout title="Editar usuario">
            <FormCardLayout.FullColum>
              <ImagePicker
                onAfterUpload={(imageLoaded) => handleLoadImage(imageLoaded)}
                setImage={previousImage}
                folder="users"
                baseUri={window.envpaths.prod.apiHost}
              />
            </FormCardLayout.FullColum>
            <FormCardLayout.FullColum>
              <form
                name="adminCreateForm"
                noValidate
                className="flex flex-col justify-center w-full"
                autoComplete="off"
              >
                <div className="flex flex-col">
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-32"
                        label="Nombre"
                        autoFocus
                        type="text"
                        error={!!errors.name}
                        helperText={errors?.name?.message}
                        variant="outlined"
                        required
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-32"
                        label="Correo electrónico"
                        autoFocus
                        type="email"
                        error={!!errors.phone || !uniqueEmail}
                        helperText={errors?.phone?.message || errorMessageEmail}
                        variant="outlined"
                        fullWidth
                        onChange={(current) => {
                          field.onChange(current);
                          if (previousEmail !== current.target.value.toString()) {
                            validateFieldEmail(current);
                          }
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-32"
                        label="Número telefónico"
                        autoFocus
                        type="number"
                        error={!!errors.phone}
                        helperText={errors?.phone?.message}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          inputProps: {
                            style: {
                              WebkitAppearance: 'none',
                              margin: 0,
                              MozAppearance: 'textfield',
                            },
                          },
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="address"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-32"
                        label="Dirección"
                        autoFocus
                        type="text"
                        error={!!errors.address}
                        helperText={errors?.address?.message}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={2}
                      />
                    )}
                  />
                  <Controller
                    name="locations"
                    control={control}
                    render={({ field }) => (
                      <ListCheckers
                        {...field}
                        setHorizontal
                        onChange={field.onChange}
                        value={field.value}
                        className="mb-32"
                        title="Sucursales permitidas"
                        error={!!errors.locations}
                        helperText={errors?.locations?.message}
                        data={locationsData}
                      />
                    )}
                  />
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <>
                        <FormLabel component="legend">Estatus de usuario</FormLabel>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={field.value}
                              {...field}
                              inputProps={{ 'aria-label': 'Estatus' }}
                            />
                          }
                          label={field.value ? 'Habilitado' : 'Deshabilitado'}
                        />
                      </>
                    )}
                  />
                </div>
              </form>
            </FormCardLayout.FullColum>
            <FormCardLayout.FullColum>
              <div>
                <MetadataExpandForm
                  onChange={(metafields) => setMetadata(metafields)}
                  onError={(metaError) => setMetadataError(metaError)}
                  initialValue={responsePreviousData ? responsePreviousData.metadata : undefined}
                />
              </div>
            </FormCardLayout.FullColum>
            <FormCardLayout.RowButton>
              <Button
                onClick={() => history.push('/users')}
                variant="contained"
                className="w-full md:w-auto"
                color="error"
              >
                Cancelar
              </Button>
              <ButtonWithLoader
                onClick={handleSubmit(handleUpdate)}
                color="success"
                aria-label="Agregar grupo"
                variant="contained"
                disabled={_.isEmpty(dirtyFields) || !isValid || metadataError}
                type="submit"
                isLoading={isLoading}
              >
                Guardar
              </ButtonWithLoader>
            </FormCardLayout.RowButton>
          </FormCardLayout>
        </Box>
      }
    />
  );
};
