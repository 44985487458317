import request, { prefix } from '../services/request';

export function search(params) {
  return request({
    url: `/${prefix}/admin/search`,
    method: 'POST',
    params,
  });
}

export function searchPage(params, page) {
  return request({
    url: `/${prefix}/admin/search?page=${page}`,
    method: 'POST',
    params,
  });
}

export function getAdminInfo() {
  console.log('current url = ', request.defaults.baseURL);
  return request({
    url: `/${prefix}/admin/info`,
    method: 'GET',
  });
}

export function storeAdmin(data) {
  return request({
    url: `/${prefix}/admin`,
    method: 'POST',
    data,
  });
}

export function updateAdmin(data, id) {
  return request({
    url: `/${prefix}/admin/${id}`,
    method: 'PUT',
    data,
  });
}

export function deleteAdmin(id) {
  return request({
    url: `/${prefix}/admin/${id}`,
    method: 'DELETE',
  });
}

export function getAdminById(id) {
  return request({
    url: `/${prefix}/admin/${id}`,
    method: 'GET',
  });
}

export function getScopes() {
  return request({
    url: `/${prefix}/admin/scopes`,
    method: 'GET',
  });
}

export function updatePassword(data, id) {
  return request({
    url: `/${prefix}/admin/password/${id}`,
    method: 'PUT',
    data,
  });
}

export function exportFile(params) {
  return request({
    url: `/${prefix}/admin/export`,
    method: 'POST',
    responseType: 'blob',
    params,
  });
}

export function findNotifications() {
  return request({
    url: `/${prefix}/admin/notifications`,
    method: 'GET',
  });
}
