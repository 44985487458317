import { useLayoutEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import history from '@history'; // Suponiendo que history está correctamente configurado

function BrowserRouter({ basename, children, window }) {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(({location, action}) => setState({ location, action })), [history]);

  return (
    <Router
      basename={basename}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    >
      {children}
    </Router>
  );
}

export default BrowserRouter;
