import { Button, ButtonGroup, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import _ from 'lodash';

const Fielder = styled('input')(({ theme }) => ({
  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  '&[type="number"]': {
    MozAppearance: 'textfield',
  },
}));

const roundedSmallStyle = {
  boxShadow: 'none',
  '& .MuiButton-root': {
    minWidth: '10px',
    fontSize: '1em',
    borderRadius: '100px',
  },
};

const paddingSmall = {
  boxShadow: 'none',
  '& .MuiButton-root': {
    minWidth: '10px',
    fontSize: '0.8em',
    borderRadius: '100px',
    padding: '1px',
  },
};

const paddingDefault = {
  boxShadow: 'none',
  '& .MuiButton-root': {
    minWidth: '10px',
    fontSize: '1em',
    borderRadius: '100px',
    padding: '6px',
  },
};

export const QuantityField = ({
  small = false,
  initialValue = 0,
  value,
  rounded = false,
  onChange = () => {},
  steps = 1,
  ...props
}) => {
  const handleStopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleAddProduct = (e) => {
    e.stopPropagation();
    const plusQuant = value + steps;
    onChange(plusQuant);
  };

  const handleRemoveProduct = (e) => {
    e.stopPropagation();
    if (value <= 0) return;
    const minusQuant = value - steps;
    onChange(minusQuant);
  };

  const handleSetProduct = (newQuant) => {
    if (newQuant < 0 || newQuant === undefined) return;
    onChange(newQuant);
  };

  const getPercent = () => {
    const resize = small ? '3.8rem' : '5.2rem';
    return resize;
  };

  const getFont = () => {
    const resize = small ? '14px' : '20px';
    return resize;
  };

  return (
    <ButtonGroup
      variant="contained"
      size={small ? 'small' : 'large'}
      aria-label="product-quantity-selector"
      sx={rounded ? small ?  paddingSmall :  paddingDefault : { maxWidth: '12rem' }}
      {...props}
    >
      <Button variant="contained" onClick={handleRemoveProduct}>
        <RemoveIcon sx={{ color: (_theme) => _theme.palette.common.white }} />
      </Button>
      <Fielder
        onClick={handleStopPropagation}
        value={value || initialValue}
        onChange={(input) => handleSetProduct(input.target.value)}
        type="number"
        className="shadow-none border-1 text-center"
        style={{
          fontSize: getFont(),
          maxWidth: getPercent(),
          marginLeft: rounded ? '10px' : undefined,
          marginRight: rounded ? '10px' : undefined,
        }}
      />
      <Button variant="contained" onClick={handleAddProduct}>
        <AddIcon sx={{ color: (_theme) => _theme.palette.common.white }} />
      </Button>
    </ButtonGroup>
  )
};
