import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import FusePageSimple from '@fuse/core/FusePageSimple';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {},
  '& .FusePageSimple-content': {},
}));

export const Access = () => {
  return (
    <Root
      header={
        <div>
          <h1>Put your header components here</h1>
        </div>
      }
      content={
        <Grid container spacing={6} className="p-32">
          <Grid item xs={12} md={12}>
            <div className="bg-orange-500" ><p><span>Put your content here</span></p></div>
          </Grid>
        </Grid>
      }
    />
  );
};
