import _ from '@lodash';
import FuseUtils from '@fuse/utils';

function NotificationModel(data) {
  data = data || {};

  return _.defaults(data, {
    _id: FuseUtils.generateGUID(),
    icon: 'heroicons-solid:star',
    // -------------
    title: 'Notificacion',
    description: undefined,
    priority: 'normal',
    status: '',
    image: undefined,
    uri: undefined,
    isClicked: undefined,
    payload: undefined,
    created_at: new Date().toISOString(),
    // -----------------
    variant: 'default',
  });
}

export default NotificationModel;
