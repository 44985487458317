import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import CardContent from '@mui/material/CardContent';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import ImageUploading from 'react-images-uploading';
import { showMessage } from 'app/store/fuse/messageSlice';
import { uploadImageFile } from 'src/app/api/image';
import { useRequest } from 'src/app/hooks/useRequest';
import _ from 'lodash';

const DragZoneSpace = ({ onUploaded = () => {}, onShowGallery = () => {}, hasGallery = false }) => {
  return (
    <div className="flex flex-col h-full flex-auto justify-center items-center flex-wrap">
      <AddPhotoAlternateIcon
        sx={{ width: '8rem', height: '8rem', color: '#9ca3af', paddingBottom: '16px' }}
      />
      <Typography align="center">
        Arrastra sobre el recuadro una imagen de tipo (JPEG, JPG o PNG) o bien:
      </Typography>
      <div
        className="mt-32 flex flex-row justify-center items-center flex-wrap"
        style={{ zIndex: 10000 }}
      >
        <div className="mx-8">
          <Button variant="outlined" size="small" onClick={() => onUploaded()}>
            Subir desde computadora
          </Button>
        </div>
        {hasGallery && (
          <div className="mx-8 mt-8 md:mt-0">
            <Button variant="outlined" size="small" onClick={() => onShowGallery()}>
              Seleccionar desde galería
            </Button>
          </div>
        )}
      </div>
    </div>
  )
};

export const ImagePicker = ({
  hasGallery = false,
  onAfterUpload = () => {},
  adminMode = false,
  baseUri,
  setImage,
  folder,
  category,
  description,
  saveInDB = false,
  overwrite = false,
  reuploadButton = false,
}) => {
  const Dispatch = useDispatch();
  const [images, setImages] = useState([]);
  const [canvasImage, setCanvasImage] = useState(undefined);
  const [imageResponse, setImageResponse] = useState(undefined);
  const [wasSavedToServer, setWasSavedToServer] = useState(false);
  const [isAdmin, setIsAdmin] = useState(adminMode);

  const {
    makeRequest: storeImage,
    isLoading: isLoadingStore,
    response: responseStore,
    errorResponse,
    status: statusStore,
  } = useRequest(uploadImageFile);

  useEffect(() => {
    if (!setImage) {
      return;
    }
    setImageResponse(setImage);
    setWasSavedToServer(true);
  }, [setImage]);

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList[0]);
    setImages(imageList);
    setCanvasImage(imageList[0]);
  };

  const validateImage = (file) => {
    const LENGTH_IMG = 2000;
    const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg';
    const isPNG = file.type === 'image/png';
    const isLt2000K = file.size / 1024 < LENGTH_IMG;
    if (!isJPG && !isPNG) {
      Dispatch(
        showMessage({
          message: 'La imagen debe estar en formato JPG o PNG!',
          autoHideDuration: 6000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          variant: 'error',
        })
      );
      return false;
    }
    if (!isLt2000K) {
      Dispatch(
        showMessage({
          message: 'La imagen excede los 900kB!',
          autoHideDuration: 6000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          variant: 'warning',
        })
      );
      return false;
    }
    return true;
  };

  const handleUpload = async (image) => {
    const formData = new FormData();
    formData.append('image', image.file);
    formData.append('workspace', isAdmin ? 'Admin' : 'User');
    formData.append('disk', 'public');
    formData.append('format', 'jpg');
    formData.append('folder', isAdmin ? '/images/admins/avatars' : folder || '/images/all');
    formData.append('quality', '80');
    // formData.append('resize', '100');
    formData.append('category', isAdmin ? 'profile' : category || 'general');
    formData.append('thumb', '40');
    formData.append('alt', `Imagen de ${isAdmin ? 'perfil de administrador' : description || 'aplicacion'}`);
    formData.append('isSave', isAdmin ? 'false' : saveInDB.toString());
    formData.append('isOverwrite', overwrite.toString());

    console.log('trying uploading');
    storeImage(formData).then(({ isOk }) => {
      if (isOk) {
        console.log('image upload successfuly');
      }
    });
  };

  const handleOnError = (errors, files) => {
    console.log(errors, files);
    validateImage(files[0]);
  };

  useEffect(() => {
    if (!responseStore && statusStore !== 'SUCCESS') {
      setWasSavedToServer(false);
    } else {
      setImageResponse(responseStore);
      setWasSavedToServer(true);
    }
  }, [responseStore, statusStore]);

  useEffect(() => {
    return () => {
      setCanvasImage(undefined);
      setImages([]);
      setImageResponse(undefined);
      setWasSavedToServer(false);
    };
  }, []);

  useEffect(() => {
    if (canvasImage !== undefined && canvasImage.file !== '') {
      handleUpload(canvasImage);
    }
  }, [canvasImage]);

  useEffect(() => {
    onAfterUpload(imageResponse);
  }, [imageResponse]);

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <Card variant="outlined" className="p-0 border-dashed rounded-lg border-2">
        <CardContent className="p-0 m-0 overflow-hidden flex justify-center items-center">
          <ImageUploading
            multiple={false}
            value={images}
            onChange={onChange}
            onError={(errors, files) => handleOnError(errors, files)}
            dataURLKey="data_url"
            acceptType={['jpg', 'jpeg', 'png']}
            style={{ padding: 0, margin: 0 }}
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              <>
                <Box
                  className="flex flex-col justify-center items-center px-24 pt-24 m-0"
                  sx={{ minHeight: '24rem', cursor: 'grab' }}
                >
                  {isLoadingStore ? (
                    <CircularProgress />
                  ) : wasSavedToServer ? (
                    <div className="flex flex-col h-full flex-auto justify-center items-center flex-wrap">
                      <Box className="flex justify-center" sx={{ height: '16rem', width: '24rem' }}>
                        <img
                          style={{ backgroundSize: 'contain', height: '100%', width: 'auto' }}
                          src={`${baseUri || ''}${baseUri ? '/' : ''}${
                            imageResponse?.fullPathThumb || imageResponse?.fullPath
                          }`}
                          alt={imageResponse.alt}
                        />
                      </Box>
                      <div className="mt-4 flex flex-wrap flex-row">
                        <Typography variant="caption" display="block" gutterBottom>
                          {imageResponse.title}
                        </Typography>
                      </div>
                      <div className="mt-8 flex flex-row justify-center">
                        <Button
                          size="small"
                          className="sm:mr-8 z-50"
                          variant="outlined"
                          color="error"
                          startIcon={<DeleteIcon />}
                        >
                          Eliminar imagen
                        </Button>
                        {reuploadButton && (
                          <Button
                            onClick={(ev) => {
                              ev.preventDefault();
                              onImageUpload();
                            }}
                            size="small"
                            className="sm:ml-8 z-50"
                            variant="outlined"
                            color="primary"
                            startIcon={<DeleteIcon />}
                          >
                            Subir otra imagen
                          </Button>
                        )}
                      </div>
                    </div>
                  ) : (
                    <DragZoneSpace
                      onUploaded={() => onImageUpload()}
                      hasGallery={hasGallery}
                      onShowGallery={() => console.log('gallery')}
                    />
                  )}
                </Box>
                {!isLoadingStore && (
                  <Box
                    {...dragProps}
                    className="absolute top-0 left-0 rounded-lg"
                    sx={{
                      width: '100%',
                      height: '100%',
                      cursor: 'pointer',
                      backgroundColor: isDragging ? 'rgba(62, 30, 164, 0.25)' : undefined,
                    }}
                  />
                )}
              </>
            )}
          </ImageUploading>
        </CardContent>
      </Card>
    </div>
  );
};

ImagePicker.propTypes = {
  hasGallery: PropTypes.bool,
  onAfterUpload: PropTypes.func,
  whereUpload: PropTypes.func,
  adminMode: PropTypes.bool,
};
