import Card from '@mui/material/Card';
import Grow from '@mui/material/Grow';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import { Children, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useThemeMediaQuery } from '@fuse/hooks';

const Container = styled('div')(({ theme, noSpaces }) => ({
  margin: noSpaces ? undefined : '2rem',
}));

export const FormCardLayout = ({ style, widthAuto, children, title, leftItem, leftItemClasses, maxWidth, className, noSpaces = false, ...props }) => {
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [faded, setFaded] = useState(false);
  useEffect(() => {
    const statedTimer = setTimeout(() => {
      setFaded(true);
    }, 400);
    return () => {
      clearTimeout(statedTimer);
      setFaded(false);
    };
  }, []);

  return (
    <Container
      {...props}
      noSpaces={noSpaces}
      className={clsx(widthAuto ? '' : 'w-11/12', className)}
      sx={{ maxWidth: widthAuto ? undefined : maxWidth || '77.8rem' }}
      style={style}
    >
      <Grow in={faded}>
        <Card sx={{ height: '100%', width: '100%' }}>
          <CardContent style={{ padding: !noSpaces ? undefined : 0 }}>
            <div className={clsx('w-1/12 absolute', leftItemClasses)}>{leftItem}</div>
            <Grid
              container
              className={clsx('flex justify-center items-start', !noSpaces ? 'pt-8 pb-4' : '')}
            >
              {title && (
                <Grid item xs={12} md={12} className={clsx('flex flex-row items-center justify-center', !noSpaces ? 'mb-8' : '')}>
                  <div>
                    <Typography variant="h6" gutterBottom>
                      {title}
                    </Typography>
                  </div>
                </Grid>
              )}
              {children}
            </Grid>
          </CardContent>
        </Card>
      </Grow>
    </Container>
  );
};

const FullColum = ({ className, style, noSpace = false, children, ...props }) => {
  return (
    <Grid
      {...props}
      item
      xs={12}
      md={12}
      className={clsx(className, !noSpace ? 'px-16 py-16' : '')}
      sx={{ minHeight: '1rem' }}
      style={style}
    >
      <div>{children}</div>
    </Grid>
  );
};

const HalfColum = ({ noSpace = false, children, ...props }) => {
  return (
    <Grid
      {...props}
      item
      xs={12}
      md={6}
      className={clsx(!noSpace ? 'px-16 py-16' : '')}
      sx={{ minHeight: '1rem' }}
    >
      <div>{children}</div>
    </Grid>
  );
};

const RowButton = ({ children, ...props }) => {
  const childrenArray = Children.toArray(children);
  return (
    <Grid
      {...props}
      item
      xs={12}
      md={12}
      className="flex px-16 mb-8 mt-16 flex-wrap justify-center items-center flex-grow"
      sx={{ minHeight: '6rem' }}
    >
      {childrenArray.map((child, key) => {
        return (
          <div key={`buttonForm_${key}`} className="px-16 py-4">
            {child}
          </div>
        );
      })}
    </Grid>
  );
};

FormCardLayout.HalfColum = HalfColum;
FormCardLayout.FullColum = FullColum;
FormCardLayout.RowButton = RowButton;
