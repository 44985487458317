import { styled } from '@mui/material/styles';
import history from '@history';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import _ from '@lodash';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import { useAuthentication } from 'src/app/hooks/useAuthentication';
import BiometricLogo from '../../static/icons/biometrics_logo_large.svg';

const IconBadge = styled('div')(({ theme }) => ({
  className: 'badge flex items-start py-4 px-8 mx-8 rounded',
  '& .login-icon': {
    height: '4.3rem',
    width: 'auto',
  },
}));
/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup
    .string()
    .email('Debes ingresar un correo válido')
    .required('El campo correo no puede quedar vacío'),
  password: yup
    .string()
    .required('Ingrese su contraseña')
    .min(6, 'Contraseña muy corta - debe contener al menos 4 caracteres.'),
});

const defaultValues = {
  email: '',
  password: '',
  remember: true,
};

function SignInPage() {
  const { handleLogin } = useAuthentication();
  const [errMessage, setErrMessage] = useState(undefined);
  const [hasError, setHasError] = useState(false);
  const Dispatch = useDispatch();
  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    setValue('email', '', { shouldDirty: false, shouldValidate: false });
    setValue('password', '', { shouldDirty: false, shouldValidate: false });
  }, [setValue]);

  function onSubmit({ email, password }) {
    console.log(`email: ${email} | password: ${password}`);
    handleLogin(email, password)
      .then((isOk) => {
        if (isOk) {
          setHasError(false);
          setErrMessage(undefined);
        }
      })
      .catch((errMsg) => {
        setHasError(true);
        setErrMessage(errMsg);
      });
  }

  const handleRecovery = () => {
    history.push('/account-recovery');
  };

  return (
    <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
      <Paper className="h-full sm:h-auto md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          {/* <img className="w-48" src="assets/images/logo/logo.svg" alt="logo" /> */}

          <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
            Iniciar sesión
          </Typography>
          <form
            name="loginForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Email"
                  autoFocus
                  type="email"
                  error={!!errors.email || hasError}
                  helperText={errors?.email?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Password"
                  type="password"
                  error={!!errors.password || hasError}
                  helperText={errors?.password?.message || errMessage}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <div className="flex flex-row items-center justify-end space-x-4">
              <div className="flex flex-row items-center justify-end space-x-4">
                <Button className="text-md font-medium" color="secondary" onClick={() => handleRecovery()}>
                  ¿Olvidaste tu contraseña?
                </Button>
              </div>
            </div>

            <Button
              variant="contained"
              color="secondary"
              className=" w-full mt-16"
              aria-label="Iniciar sesión"
              disabled={_.isEmpty(dirtyFields) || !isValid}
              size="large"
              type="submit"
            >
              Entrar
            </Button>
          </form>
        </div>
      </Paper>

      <Box
        className="relative hidden md:flex flex-auto items-center justify-center h-full p-64 lg:px-112 overflow-hidden"
        sx={{ backgroundColor: 'primary.main' }}
      >
        <svg
          className="absolute inset-0 pointer-events-none"
          viewBox="0 0 960 540"
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMax slice"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Box
            component="g"
            sx={{ color: 'primary.light' }}
            className="opacity-20"
            fill="none"
            stroke="currentColor"
            strokeWidth="100"
          >
            <circle r="234" cx="196" cy="23" />
            <circle r="234" cx="790" cy="491" />
          </Box>
        </svg>
        <Box
          component="svg"
          className="absolute -top-64 -right-64 opacity-20"
          sx={{ color: 'primary.light' }}
          viewBox="0 0 220 192"
          width="220px"
          height="192px"
          fill="none"
        >
          <defs>
            <pattern
              id="837c3e70-6c3a-44e6-8854-cc48c737b659"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect x="0" y="0" width="4" height="4" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
        </Box>

        <div className="z-10 relative w-full max-w-2xl">
          <IconBadge>
            <img className="login-icon" src={BiometricLogo} alt="logo de biometrics" />
          </IconBadge>
          <div className="mt-24 text-lg tracking-tight leading-6 text-gray-400">
            "Nos enfocamos a la constante innovación promoviendo una mayor seguridad con una calidad
            autentica".
          </div>
          {/* <div className="flex items-center mt-32">
            <AvatarGroup
              sx={{
                '& .MuiAvatar-root': {
                  borderColor: 'primary.main',
                },
              }}
            >
              <Avatar src="assets/images/avatars/female-18.jpg" />
              <Avatar src="assets/images/avatars/female-11.jpg" />
              <Avatar src="assets/images/avatars/male-09.jpg" />
              <Avatar src="assets/images/avatars/male-16.jpg" />
            </AvatarGroup>

            <div className="ml-16 font-medium tracking-tight text-gray-400">
              More than 17k people joined us, it's your turn
            </div>
          </div> */}
        </div>
      </Box>
    </div>
  );
}

export default SignInPage;
