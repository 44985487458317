import { useEffect, useState } from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { set } from 'lodash';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/**
 * Select based on MUI Select.
 * #### Require external useState() controller
 *
 * @component
 * @param {Object} props - Generic object of functional component
 * @param {boolean} props.error - if has been ocurred an error during selecting option, set flag to true
 * @param {string} props.helperText - Error text to show.
 * @param {title} props.title - Select label to show
 * @param {array} props.data - Data to show as options, is Array of Object [{label, value},...]
 * @param {any} props.value - Set the initial value or controled value from state
 * @param {string} props.size - MUI Select Size | small | medium | large |
 * @param {string} props.staticWidth - Set a static With to select in | px | rem | em |
 * @param {function} props.onChange - Event to listen state change to current value selected.
 * @returns {JSX.Element} Returns JSX Elements render of MUI Select.
 *
 * @example
 * // Render a select with data
 * const options = [
 *  {
 *    label: 'Opcion 1'
 *    value: 'opt_1'
 *  },
 * {
 *    label: 'Opcion 1'
 *    value: 'opt_2'
 *  },
 * ]
 * <BasicSelect title="Simple select" data={options} />
 */
export const BasicSelect = ({
  error = false,
  helperText,
  title,
  data = [],
  value = '',
  size,
  staticWidth,
  onChange = () => {},
  ...props
}) => {
  const [values, setValues] = useState(data);
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    onChange(selectedValue);
  };

  useEffect(() => {
    if (data === undefined) return;
    setValues(data);
  }, [data]);

  return (
    <Box>
      <FormControl error={error} size={size}>
        <InputLabel id="basic-label-id">{title}</InputLabel>
        <Select
          sx={{ minWidth: '12rem', width: staticWidth }} 
          labelId="basic-label-id"
          id="basic-label"
          value={value}
          label={title}
          MenuProps={MenuProps}
          onChange={handleChange}
          {...props}
        >
          {values.map((current, index) => (
            <MenuItem
              key={`${current.label}_${index}_item`}
              value={current.value === undefined || current.value === null ? '' : current.value}
            >
              {current.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </Box>
  );
};

/* BasicSelect.propTypes = {
  value: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  title: PropTypes.string.isRequired,
  clear: PropTypes.bool,
  onChange: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
}; */
