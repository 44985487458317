import { useContext, useEffect, useState } from 'react';
import _ from 'src/@lodash/@lodash';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import FusePageSimple from '@fuse/core/FusePageSimple';
import Divider from '@mui/material/Divider';
import { ButtonWithLoader, ComposedHeader, ComposedTags, FormCardLayout } from 'src/app/components';
import { regexName, regexURL } from 'src/app/utils/validations';

const Root = styled(FusePageSimple)(({ theme }) => ({
  backgroundColor: '#FFF',
  '& .FusePageSimple-header': {},
  '& .FusePageSimple-content': {},
}));

const tabsContent = [
  {
    label: 'General',
    key: 'General',
  },
  {
    label: 'Notificaciones',
    key: 'notifications',
  },
];

const notificationsSchema = yup.object().shape({
  notifySocketPath: yup
    .string()
    .required('El campo no puede quedar vacío')
    .matches(regexURL, 'No es una dirección válida'),
  notificationsStatus: yup.boolean(),
  notificationsOverOS: yup.boolean(),
});

const generalSchema = yup.object().shape({
  test: yup
    .string()
    .required('El campo no puede quedar vacío')
    .matches(regexName, 'No es un string valido'),
});

const defaultNotificationsValues = {
  notifySocketPath: 'config config',
  notificationsStatus: true,
};

const defaultGeneralValues = {
  test: '',
};

const NotificationsFormTab = ({ onUpdate }) => {
  // ----- Inicializacion de validadores de formulario
  const { control, formState, handleSubmit, setValue } = useForm({
    mode: 'onChange',
    defaultValues: defaultNotificationsValues,
    resolver: yupResolver(notificationsSchema),
  });
  const { isValid, dirtyFields, errors } = formState;

  return (
    <>
      <FormCardLayout.FullColum>
        <form
          autoComplete="off"
          name="notificationConfigForm"
          noValidate
          className="flex flex-col justify-center w-full"
        >
          <div className="flex flex-col">
            <Controller
              name="notifySocketPath"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-32"
                  label="URL API de notificaciónes"
                  autoFocus
                  type="url"
                  error={!!errors.notifySocketPath}
                  helperText={errors?.notifySocketPath?.message}
                  variant="outlined"
                  required
                  fullWidth
                  inputProps={{
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
              )}
            />
            <div className="flex w-full flex-row justify-around items-center flex-wrap">
              <div
                className="flex px-32 py-16 md:py-0 justify-center items-center flex-col"
                style={{ height: '10rem' }}
              >
                <Controller
                  name="notificationsStatus"
                  control={control}
                  render={({ field }) => (
                    <>
                      <FormLabel component="legend">Notificaciones de aplicación</FormLabel>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={field.value}
                            {...field}
                            inputProps={{ 'aria-label': 'notificationsStatus' }}
                          />
                        }
                        label={field.value ? 'Habilitado' : 'Deshabilitado'}
                      />
                    </>
                  )}
                />
              </div>
            </div>
          </div>
        </form>
      </FormCardLayout.FullColum>
      <FormCardLayout.RowButton>
        <ButtonWithLoader
          onClick={handleSubmit(onUpdate)}
          color="success"
          aria-label="Agregar administrador"
          variant="contained"
          disabled={_.isEmpty(dirtyFields) || !isValid}
          type="submit"
          // isLoading={isLoading}
        >
          Actualizar cambios
        </ButtonWithLoader>
      </FormCardLayout.RowButton>
    </>
  );
};

const GeneralFormTab = ({ onUpdate = () => {}, onChangeEnvValue = () => {}, envValue }) => {
  // ----- Inicializacion de validadores de formulario
  const { control, formState, handleSubmit, setValue } = useForm({
    mode: 'onChange',
    defaultValues: defaultGeneralValues,
    resolver: yupResolver(generalSchema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const [envManager, setEnvManager] = useState(false);

  useEffect(() => {
    if (envValue === undefined) return;
    setEnvManager(envValue);
  }, [envValue]);

  const handleSubmitValidate = (form) => {
    console.log(form);
    onUpdate(form);
  };

  return (
    <>
      <FormCardLayout.FullColum>
        <form
          autoComplete="off"
          name="generalConfigForm"
          noValidate
          className="flex flex-col justify-center w-full"
        >
          <div className="flex flex-col">
            <Controller
              name="test"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-32"
                  label="Test Input"
                  type="text"
                  error={!!errors.test}
                  helperText={errors?.test?.message}
                  variant="outlined"
                  required
                  fullWidth
                  inputProps={{
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
              )}
            />
            <Divider variant="middle" />
            <div className="flex w-full flex-row justify-around items-center flex-wrap">
              <div
                className="flex px-32 py-16 md:py-0 justify-center items-center flex-col"
                style={{ height: '10rem' }}
              >
                <FormLabel component="legend">Entorno de Manager</FormLabel>
                <FormControlLabel
                  control={
                    <Switch
                      checked={envManager}
                      onChange={({ target }) => onChangeEnvValue(target.checked)}
                      inputProps={{ 'aria-label': 'envManager' }}
                    />
                  }
                  label={envManager ? 'Desarrollo' : 'Produccion'}
                />
              </div>
            </div>
          </div>
        </form>
      </FormCardLayout.FullColum>
      <FormCardLayout.RowButton>
        <ButtonWithLoader
          onClick={handleSubmit(handleSubmitValidate)}
          color="success"
          aria-label="Agregar administrador"
          variant="contained"
          disabled={_.isEmpty(dirtyFields) || !isValid}
          type="submit"
          // isLoading={isLoading}
        >
          Actualizar cambios
        </ButtonWithLoader>
      </FormCardLayout.RowButton>
    </>
  );
};

export const Configuration = () => {
  return (
    <Root
      header={
        <ComposedHeader
          breadcrumb
          title="Configuración"
          description="Información general de terminal de registro"
        />
      }
      content={
        <Box
          className="flex justify-center items-start"
          sx={{
            width: '100%',
            height: '100%',
          }}
        >
          <FormCardLayout>
            <ComposedTags
              tabs={tabsContent}
              color="secondary"
              variant="fullWidth"
              fontSize="1.6rem"
            >
              <ComposedTags.Element index={0}>
                <GeneralFormTab />
              </ComposedTags.Element>
              <ComposedTags.Element index={1}>
                <NotificationsFormTab />
              </ComposedTags.Element>
            </ComposedTags>
          </FormCardLayout>
        </Box>
      }
    />
  );
};
