import { createSlice } from '@reduxjs/toolkit';
import themesConfig from 'app/configs/themesConfig';

// ------ App Identity | Tiene la informacion del tema customizado e identidad de Company Biometrics --------
const userInitialState = {
  keyName: 'GENERIC_KEY_COMPANY',
  name: '',
  image: {},
  isMultiAccess: false,
  isGroupActive: false,
  metadata: {},
  resources: {
    theme: {
      main: themesConfig.default,
      navbar: themesConfig.defaultDark,
      toolbar: themesConfig.default,
      footer: themesConfig.defaultDark,
    },
  },
};

const identitySlice = createSlice({
  name: 'identity',
  initialState: userInitialState,
  reducers: {
    setIdentityState: (state, { payload }) => {
      state.keyName = payload.keyName;
      state.name = payload.name;
      state.image = payload.image;
      state.isMultiAccess = payload.isMultiAccess;
      state.isGroupActive = payload.isGroupActive;
      state.metadata = payload.metadata;
      state.resources = payload.resources;
    },
    resetIdentity: (state) => {
      state = userInitialState;
    },
  },
});

export default identitySlice.reducer;
export const { setIdentityState, resetIdentity } = identitySlice.actions;
