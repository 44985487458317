import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import WifiTetheringOffIcon from '@mui/icons-material/WifiTetheringOff';
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import { socketConnectionEnum } from 'src/app/utils/enums';

export const NotificationBar = () => {

  return (
    <div className="flex flex-wrap justify-start items-center">
      {/** Pendiente */}
    </div>
  );
};
