import { Configuration, Dashboard, Events, Licenses, Terminals } from './Children';

export const UserRegistersConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      id: 'userRegistersGroup',
      path: 'user-registers',
      scopes: ['admin-registry-show', 'admin-registry-config'],
      children: [
        {
          id: 'userRegistersGroup.dashboard',
          path: 'dashboard',
          element: <Dashboard />,
        },
        {
          id: 'userRegistersGroup.events',
          path: 'events',
          element: <Events />,
        },
        {
          scopes: ['admin-registry-show'],
          id: 'userRegistersGroup.terminals',
          path: 'terminals',
          element: <Terminals />,
        },
        {
          id: 'userRegistersGroup.licenses',
          path: 'licenses',
          element: <Licenses />,
        },
        {
          scopes: ['admin-registry-config'],
          id: 'userRegistersGroup.configuration',
          path: 'configuration',
          element: <Configuration />,
        },
      ],
    },
  ],
};
