import ClickAwayListener from '@mui/material/ClickAwayListener';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import clsx from 'clsx';
import _ from '@lodash';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';

const Root = styled('div')(({ theme }) => ({
  '& .SearchSuggestion-container': {
    position: 'relative',
  },

  '& .SearchSuggestion-suggestionsContainerOpen': {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(),
    left: 0,
    right: 0,
  },

  '& .SearchSuggestion-suggestion': {
    display: 'block',
  },

  '& .SearchSuggestion-suggestionsList': {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },

  '& .SearchSuggestion-input': {
    transition: theme.transitions.create(['background-color'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short,
    }),
    '&:focus': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

const renderInputComponent = (inputProps) => {
  const { variant, inputRef = () => { }, ref, ...other } = inputProps;
  return (
    <div className="w-full relative">
      {variant === 'basic' ? (
        // Outlined
        <>
          <TextField
            fullWidth
            InputProps={{
              inputRef: (node) => {
                ref(node);
                inputRef(node);
              },
              classes: {
                input: 'SearchSuggestion-input py-0 px-16 h-40 md:h-48 ltr:pr-48 rtl:pl-48',
                notchedOutline: 'rounded-8',
              },
            }}
            variant="outlined"
            {...other}
          />
          <FuseSvgIcon
            className="absolute top-0 ltr:right-0 rtl:left-0 h-40 md:h-48 w-48 p-12 pointer-events-none"
            color="action"
          >
            heroicons-outline:search
          </FuseSvgIcon>
        </>
      ) : (
        // Standard
        <TextField
          fullWidth
          InputProps={{
            disableUnderline: true,
            inputRef: (node) => {
              ref(node);
              inputRef(node);
            },
            classes: {
              input: 'SearchSuggestion-input py-0 px-16 h-48 md:h-64',
            },
          }}
          variant="standard"
          {...other}
        />
      )}
    </div>
  );
}

const renderSuggestion = (suggestion, { query, isHighlighted }, propSuggestionDisplay = 'title') => {
  const hasThere = _.has(suggestion, propSuggestionDisplay);
  if (!hasThere) {
    throw new Error(`The prop propSuggestionDisplay = '${propSuggestionDisplay}' is undefined at Suggestion item`);
  }
  const matches = match(suggestion[propSuggestionDisplay], query);
  const parts = parse(suggestion[propSuggestionDisplay], matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <ListItemIcon className="min-w-40">
        {suggestion.icon ? (
          <FuseSvgIcon>{suggestion.icon}</FuseSvgIcon>
        ) : (
          <span className="text-20 w-24 font-semibold uppercase text-center">
            {suggestion[propSuggestionDisplay][0]}
          </span>
        )}
      </ListItemIcon>
      <ListItemText
        primary={parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 600 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          )
        )}
      />
    </MenuItem>
  );
}

const getSuggestions = (value, data) => {
  if (!value) return [];
  const inputValue = _.deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : data.filter((suggestion) => {
        const keep = count < 10 && match(suggestion.title, inputValue).length > 0;

        if (keep) {
          count += 1;
        }
        return keep;
      });
}

export const SearchSuggestion = memo(
  ({
    suggestionsData,
    placeholder,
    variant,
    className,
    externalApi,
    onSelected = () => { },
    propSuggestionDisplay = 'title',
  }) => {
    const [hasExternalApi, setHasExternalApi] = useState(false);

    const [staticSuggestions, setStaticSuggestions] = useState([]);
    const [foundSuggestions, setFoundSuggestions] = useState([]);
    const [noSuggestions, setNoSuggestions] = useState(false);
    const [opened, setOpened] = useState(false);
    const [searchText, setSearchText] = useState('');

    const suggestionsNode = useRef(null);
    const popperNode = useRef(null);
    const buttonNode = useRef(null);

    useEffect(() => {
      if (suggestionsData === undefined) return;
      setStaticSuggestions(suggestionsData);
    }, [suggestionsData]);

    useEffect(() => {
      const isExternal = typeof externalApi === 'function';
      setHasExternalApi(isExternal);
    }, [externalApi]);

    const handleClearStates = () => {
      setFoundSuggestions([]);
      setNoSuggestions(false);
      setSearchText('');
    };

    const handleSearchSuggestionsInExternal = (query) => {
      externalApi({ query, paginate: 50 })
        .then((response) => {
          if (response && response.success && response.data) {
            setFoundSuggestions(response.data.data);
            setNoSuggestions(true);
          } else {
            setFoundSuggestions([]);
            setNoSuggestions(false);
          }
        })
        .catch((err) => {
          setFoundSuggestions([]);
          setNoSuggestions(false);
          console.log(err);
        });
    };

    const handleSearchSuggestionsInternal = (query) => {
      if (query === undefined) return;
      const matched = getSuggestions(query, staticSuggestions);
      const isInputBlank = query.trim() === '';
      const thereIsNotSuggestions = !isInputBlank && matched.length === 0;
      setFoundSuggestions(matched);
      setNoSuggestions(thereIsNotSuggestions);
    };

    function escFunction(event) {
      if (event.keyCode === 27) {
        hideSearch();
      }
    }

    const showSearch = (ev) => {
      ev.stopPropagation();
      setOpened(true);
      document.addEventListener('keydown', escFunction, false);
    };

    const hideSearch = () => {
      setOpened(false);
      document.removeEventListener('keydown', escFunction, false);
    };

    const handleSuggestionsFetchRequested = ({ value }) => {
      if (!hasExternalApi) {
        handleSearchSuggestionsInternal(value);
      } else {
        handleSearchSuggestionsInExternal(value);
      }
    };

    const handleSuggestionSelected = (event, { suggestion }) => {
      event.preventDefault();
      event.stopPropagation();
      onSelected(suggestion);
      handleClearStates();
      hideSearch();
    };

    const handleSuggestionsClearRequested = () => {
      setFoundSuggestions([]);
      setNoSuggestions(false);
    };

    const handleChange = ({ target }) => {
      if (target === undefined) return;
      setSearchText(target.value);
    };

    const handleClickAway = (event) => {
      return (
        opened &&
        (!suggestionsNode.current || !suggestionsNode.current.contains(event.target)) &&
        hideSearch()
      );
    };

    const handleGetSuggestionValue = useCallback(
      (suggestion) => {
        return suggestion[propSuggestionDisplay];
      },
      [propSuggestionDisplay]
    );

    const handleRenderSuggestion = useCallback((suggest, options) => {
      return renderSuggestion(suggest, options, propSuggestionDisplay);
    },
      [propSuggestionDisplay]
    );

    const autosuggestProps = {
      renderInputComponent,
      highlightFirstSuggestion: true,
      suggestions: foundSuggestions,
      onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: handleSuggestionsClearRequested,
      onSuggestionSelected: handleSuggestionSelected,
      getSuggestionValue: handleGetSuggestionValue,
      renderSuggestion: handleRenderSuggestion,
    };

    switch (variant) {
      case 'basic': {
        return (
          <div className={clsx('flex items-center w-full', className)} ref={popperNode}>
            <Autosuggest
              {...autosuggestProps}
              inputProps={{
                variant,
                placeholder,
                value: searchText,
                onChange: handleChange,
                onFocus: showSearch,
                InputLabelProps: {
                  shrink: true,
                },
                autoFocus: false,
              }}
              theme={{
                container: 'flex flex-1 w-full',
                suggestionsList: 'SearchSuggestion-suggestionsList',
                suggestion: 'SearchSuggestion-suggestion',
              }}
              renderSuggestionsContainer={(options) => (
                <Popper
                  anchorEl={popperNode.current}
                  open={Boolean(options.children) || noSuggestions}
                  popperOptions={{ positionFixed: true }}
                  className="z-9999"
                >
                  <div ref={suggestionsNode}>
                    <Paper
                      className="shadow-lg rounded-8 overflow-hidden"
                      {...options.containerProps}
                      style={{ width: popperNode.current ? popperNode.current.clientWidth : null }}
                    >
                      {options.children}
                      {noSuggestions && (
                        <Typography className="px-16 py-12">No hay resultados...</Typography>
                      )}
                    </Paper>
                  </div>
                </Popper>
              )}
            />
          </div>
        );
      }
      case 'full': {
        return (
          <Root className={clsx('flex', className)}>
            <Tooltip title="Click para búsqueda" placement="bottom">
              <div
                onClick={showSearch}
                onKeyDown={showSearch}
                role="button"
                tabIndex={0}
                ref={buttonNode}
              >
                <IconButton className="w-40 h-40" size="large">
                  <FuseSvgIcon>heroicons-outline:search</FuseSvgIcon>
                </IconButton>
              </div>
            </Tooltip>

            {opened && (
              <ClickAwayListener onClickAway={handleClickAway}>
                <Paper className="absolute left-0 right-0 top-0 h-full z-9999 shadow-0" square>
                  <div className="flex items-center w-full h-full" ref={popperNode}>
                    <Autosuggest
                      {...autosuggestProps}
                      inputProps={{
                        placeholder,
                        value: searchText,
                        onChange: handleChange,
                        InputLabelProps: {
                          shrink: true,
                        },
                        autoFocus: true,
                      }}
                      theme={{
                        container: 'flex flex-1 w-full',
                        suggestionsList: 'SearchSuggestion-suggestionsList',
                        suggestion: 'SearchSuggestion-suggestion',
                      }}
                      renderSuggestionsContainer={(options) => (
                        <Popper
                          anchorEl={popperNode.current}
                          open={Boolean(options.children) || noSuggestions}
                          popperOptions={{ positionFixed: true }}
                          className="z-9999"
                        >
                          <div ref={suggestionsNode}>
                            <Paper
                              className="shadow-lg"
                              square
                              {...options.containerProps}
                              style={{
                                width: popperNode.current ? popperNode.current.clientWidth : null,
                              }}
                            >
                              {options.children}
                              {noSuggestions && (
                                <Typography className="px-16 py-12">No hay resultados..</Typography>
                              )}
                            </Paper>
                          </div>
                        </Popper>
                      )}
                    />
                    <IconButton onClick={hideSearch} className="mx-8" size="large">
                      <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
                    </IconButton>
                  </div>
                </Paper>
              </ClickAwayListener>
            )}
          </Root>
        );
      }
      default: {
        return null;
      }
    }
  });

SearchSuggestion.propTypes = {};
SearchSuggestion.defaultProps = {
  suggestionsData: [],
  externalApi: undefined,
  propSuggestionDisplay: 'title',
  variant: 'full',
  placeholder: 'Buscar',
  noResults: 'No hay resultados..',
};
