import _ from 'lodash';

export function getErrMessages(errRequest) {
  let messageArr = [];
  if (errRequest.response && errRequest.response.data) {
    if (_.has(errRequest.response.data, 'messages')) {
      messageArr = errRequest.response.data.messages;
    } else {
      const genericMsg = errRequest.response.data.message;
      messageArr = [genericMsg];
    }
  } else {
    messageArr = [errRequest.response];
  }
  return messageArr;
};
