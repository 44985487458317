import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { lighten, useTheme } from '@mui/material/styles';

/**
 * Card to represent state value on dashboards.
 *
 * @component
 * @param {Object} props - Generic object of functional component
 * @param {string} props.title - Descriptive text of Card
 * @param {string} props.value - Value to represent current state
 * @param {string} props.color - Color of the card based on MUI Pallete | Primary | Secondary | Error | Etc...
 * @returns {JSX.Element} Returns JSX Elements render of MUI.
 *
 */
export const CardNumberColored = ({ title, value, color }) => {
  const theme = useTheme();
  return (
    <Paper
      className="flex flex-col items-center justify-center py-32 px-4 rounded-2xl"
      variant="outlined"
      style={{
        backgroundColor: color
          ? `${theme.palette[`${color}`].main}19`
          : `${theme.palette.primary.main}19`,
      }}
    >
      <Typography
        className="text-5xl sm:text-7xl font-semibold leading-none tracking-tight"
        sx={{
          color: (_theme) =>
            _theme.palette.mode !== 'light'
              ? lighten(theme.palette.text.primary, 0.02)
              : color
              ? theme.palette[`${color}`].main
              : theme.palette.primary.main,
        }}
      >
        {value}
      </Typography>
      <Typography
        className="mt-4 text-sm sm:text-lg font-medium"
        sx={{
          color: (_theme) =>
            _theme.palette.mode !== 'light'
              ? lighten(theme.palette.text.primary, 0.02)
              : color
              ? theme.palette[`${color}`].main
              : theme.palette.primary.main,
        }}
      >
        {title}
      </Typography>
    </Paper>
  );
};
