import { setVisibility } from './notificationsSlice';

export const toggleNotificationPanel = () => {
  return async (dispatch, getState) => {
    const { notifications } = getState();
    const { panel } = notifications;
    dispatch(setVisibility({ visibility: !panel.visibility }));
  };
};

export const openNotificationPanel = () => {
  return async (dispatch) => {
    dispatch(setVisibility({ visibility: true }));
  };
};

export const closeNotificationPanel = () => {
  return async (dispatch) => {
    dispatch(setVisibility({ visibility: false }));
  };
};
