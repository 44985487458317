import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import { InputSearch } from '../InputSearch/InputSearch';
import { BasicSelect } from '../BasicSelect/BasicSelect';
import { ButtonWithExpand } from '../ButtonWithExpand/ButtonWithExpand';
import { ComposedTable } from '../ComposedTable/ComposedTable';

const statusAdminsData = [
  {
    label: 'Todo',
    value: '',
  },
  {
    label: 'Activo',
    value: 'true',
  },
  {
    label: 'Inactivo',
    value: 'false',
  },
];

const accessScheme = [
  {
    title: 'Nombre',
  },
  {
    title: 'Descripción',
  },
  {
    title: 'Parent',
  },
  {
    title: 'Opciones',
  },
];

const data = [
  {
    name: 'Uriel',
    description: 'CEO',
    parent: '12343',
    options: ''
  },
  {
    name: 'Josue',
    description: 'Backend',
    parent: '32423424',
    options: ''
  },
  {
    name: 'David',
    description: 'Backend',
    parent: '1322343',
    options: ''
  },
  {
    name: 'Gus gus',
    description: 'Front-End',
    parent: '852453',
    options: ''
  }
]

export const Report = () => {
  return (
    <Card sx={{ display: 'flex-auto p-24' }}>
      <CardContent>
        <Grid
          container
          className="pt-24 px-24"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} md={3}>
            <div className="flex flex-row justify-center">
              <InputSearch /> 
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="flex w-100 flex-row flex-wrap items-center items-center justify-center sm:justify-start">
              <div className="px-4">
                <BasicSelect
                  data={statusAdminsData}
                  // onChange={(data) => setAdminStatus(data)}
                  // value={adminStatus}
                  title="Terminal"
                  size="small"
                />
              </div>
              <div className="px-4">
                <BasicSelect
                  data={statusAdminsData}
                  // onChange={(data) => setAdminStatus(data)}
                  // value={adminStatus}
                  title="Estatus"
                  size="small"
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonWithExpand
              title="Descargar"
              className="whitespace-nowrap mx-4"
              variant="contained"
              color="success"
              onClick={() => console.log('Export excel...')}
              startIcon={<SimCardDownloadOutlinedIcon />}
            >
              <ButtonWithExpand.Item text="Documento PDF" />
              <ButtonWithExpand.Item text="Documento Excel" />
            </ButtonWithExpand>
          </Grid>
        </Grid>
        <div className="flex flex-col justify-center mt-24">
          <ComposedTable className="w-full" headers={accessScheme} data={data} size="medium">
            <ComposedTable.Column content={(item) => item.name} />
            <ComposedTable.Column content={(item) => item.description} />
            <ComposedTable.Column content={(item) => item.parent} />
            <ComposedTable.Column content={(item) => item.options} />
          </ComposedTable>
        </div>
      </CardContent>
    </Card>
  );
};
