import { styled } from '@mui/material/styles';
import './NavBarLogo.css';
import { useSelector } from 'react-redux';
import { selectFuseNavbar } from 'app/store/fuse/navbarSlice';
import { selectFuseCurrentLayoutConfig } from 'app/store/fuse/settingsSlice';
import BiometricLogo from '../../static/icons/biometrics_logo_large.svg';
import BiometricCompactLogo from '../../static/icons/biometrics_logo_small.svg';

const Root = styled('div')(({ theme }) => ({
  '& > .logo-icon': {
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeIn,
    }),
  },
  '& > .badge': {
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
}));

export const NavBarLogo = () => {
  const config = useSelector(selectFuseCurrentLayoutConfig);
  const navbar = useSelector(selectFuseNavbar);
  const { foldedOpen } = navbar;

  return (
    <Root className="flex items-center">
      {!foldedOpen && config.navbar.style === 'style-2' && (
        <div className="primary-logo-container">
          <img className="primary-logo" src={BiometricCompactLogo} alt="logo" />
        </div>
      )}
      <div
        className={`badge flex items-center py-4 rounded
          ${config.navbar.style === 'style-2' ? 'px-8 mx-8' : 'pr-24 mr-24'}
        `}
      >
        <img
          className={`react-badge
            ${config.navbar.style === 'styled-2' ? 'secondary-logo' : 'secondary-logo--style1'}
          `}
          src={BiometricLogo}
          alt="logo de biometrics"
        />
      </div>
    </Root>
  );
};
