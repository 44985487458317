import { memo, useEffect, useState } from 'react';
import Fade from '@mui/material/Fade';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import FormLabel from '@mui/material/FormLabel';
import { Grid, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { validateObjectKey } from 'src/app/utils/validations';
import _ from 'lodash';

const errMessages = {
  empty: 'El campo no debe estar vacío',
  key: 'Solo letras y guiones medios - o bajos _ como separadores',
};

const MetadataItem = memo(
  ({
    onRemove = () => {},
    field = {},
    onChangeKey = () => {},
    onChangeValue = () => {},
    onChangeError = () => {},
  }) => {
    const [objKeyHasStringError, setObjKeyStringError] = useState(false);
    const [objKeyIsEmpty, setObjKeyIsEmpty] = useState(false);
    const [objValueIsEmpty, setObjValueIsEmpty] = useState(false);

    const handleOnChangeObjKey = (objKeyState) => {
      let currentValue = '';
      currentValue = objKeyState.target.value;
      onChangeKey(currentValue);
      const hasStringError = validateObjectKey(currentValue);
      setObjKeyStringError(!hasStringError);
      const isEmptyString = currentValue.length < 2 && currentValue === '';
      setObjKeyIsEmpty(isEmptyString);
    };

    const handleOnChangeObjValue = (objValueState) => {
      let currentValue = '';
      currentValue = objValueState.target.value;
      onChangeValue(currentValue);
      const isEmptyString = currentValue.length < 2 && currentValue === '';
      setObjValueIsEmpty(isEmptyString);
    };

    useEffect(() => {
      const fieldsErrors = objKeyHasStringError || objKeyIsEmpty || objValueIsEmpty;
      onChangeError(fieldsErrors);
    }, [objKeyHasStringError, objKeyIsEmpty, objValueIsEmpty]);

    return (
      <>
        <Grid container>
          <Grid item xs={10} md={11}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <div className="flex flex-row flex-auto justify-center mt-0 sm:mt-16 md:mt-0">
                  <TextField
                    size="small"
                    className="w-11/12"
                    label="Key"
                    value={field.objKey.replace(/\s/g, '_')}
                    onChange={handleOnChangeObjKey}
                    error={objKeyHasStringError || objKeyIsEmpty}
                    helperText={
                      (objKeyHasStringError && errMessages.key) ||
                      (objKeyIsEmpty && errMessages.empty)
                    }
                    required
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="flex flex-row flex-auto justify-center mt-16 md:mt-0">
                  <TextField
                    size="small"
                    className="w-11/12"
                    label="Valor"
                    value={field.objValue}
                    onChange={handleOnChangeObjValue}
                    error={objValueIsEmpty}
                    helperText={objValueIsEmpty && errMessages.empty}
                    required
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} md={1}>
            <div className="flex w-full h-full justify-center items-center flex-row">
              <IconButton aria-label="delete" onClick={onRemove}>
                <DeleteIcon />
              </IconButton>
            </div>
          </Grid>
        </Grid>
        <Divider className="my-16" />
      </>
    );
  }
);

export const MetadataExpandForm = ({ onChange = () => {}, onError = () => {}, initialValue }) => {
  const [metadataFieldList, setMetadataFieldList] = useState([]);
  const [metadataObject, setMetadataObject] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [errors, setErrors] = useState(false);

  const handleTransformObjToArr = (initialData) => {
    const transformedMetadata = Object.keys(initialData).map((key) => ({
      objKey: key,
      objValue: initialData[key],
      error: false,
    }));
    setMetadataFieldList(transformedMetadata);
  };

  useEffect(() => {
    if (!initialValue && _.isEmpty(initialValue)) return;
    handleTransformObjToArr(initialValue);
    setExpanded(true);
  }, [initialValue]);

  const handleAddMetadataPair = () => {
    setMetadataFieldList((currentList) => {
      return [
        ...currentList,
        {
          objKey: '',
          objValue: '',
          error: false,
        },
      ];
    });
  };

  const handleDeleteMetadataPair = (indexField) => {
    const updatedMetadataFieldList = metadataFieldList.filter(
      (metadataItem, index) => index !== indexField
    );
    setMetadataFieldList(updatedMetadataFieldList);
  };

  const handleOnChangeField = (index, key, value) => {
    setMetadataFieldList((prevMetadataFieldList) => {
      const updatedMetadataFieldList = [...prevMetadataFieldList];

      updatedMetadataFieldList[index] = {
        ...updatedMetadataFieldList[index],
        [key]: value,
      };
      return updatedMetadataFieldList;
    });
  };

  const transformArrayFields = (arrayFields = []) => {
    return arrayFields.reduce((accumulator, item) => {
      if (item.objKey !== '' && item.objValue !== '' && arrayFields.length > 0) {
        accumulator[item.objKey] = item.objValue;
      }
      return accumulator;
    }, {});
  };

  const handleSetGeneralError = (metadataErrorsArray = []) => {
    let result = metadataErrorsArray[0]?.error;
    metadataErrorsArray.forEach((field) => {
      result = result || field.error;
    });
    setErrors(result);
  };

  useEffect(() => {
    // console.log(JSON.stringify(metadataFieldList, null, 2));
    handleSetGeneralError(metadataFieldList);
    const result = transformArrayFields(metadataFieldList);
    setMetadataObject(result);
  }, [metadataFieldList]);

  useEffect(() => {
    if (!metadataObject) return;
    onChange(metadataObject);
  }, [metadataObject]);

  useEffect(() => {
    onError(false);
  }, [])

  useEffect(() => {
    onError(errors);
  }, [errors]);

  const handleExpand = () => setExpanded((currentState) => !currentState);

  return (
    <div>
      <Accordion
        variant="outlined"
        expanded={expanded}
        onChange={handleExpand}
        // slots={{ transition: Fade }}
        // slotProps={{ transition: { timeout: 400 } }}
        sx={{
          '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
          '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon fontSize="large" />}
          aria-controls="Metadata_Form"
          id="metadata_form_fields_optional"
          className="border-b-neutral-600 border-b-1 border-solid"
          sx={{ backgroundColor: (_theme) => _theme.palette.background.default }}
        >
          <Typography variant="subtitle1">Información extra</Typography>
        </AccordionSummary>
        <AccordionDetails className="pt-16">
          <FormLabel component="legend">
            Añade más Información al formulario que requieras, no será tomada en cuenta para el
            funcionamiento del sitio
          </FormLabel>
          <form
            name="metadataForm"
            noValidate
            className="flex flex-col justify-center w-full pt-32"
            autoComplete="off"
          >
            {metadataFieldList &&
              metadataFieldList.map((field, index) => {
                return (
                  <MetadataItem
                    key={`${index}_field_metadata`}
                    field={field}
                    onChangeKey={(current) => handleOnChangeField(index, 'objKey', current)}
                    onChangeValue={(current) => handleOnChangeField(index, 'objValue', current)}
                    onRemove={() => handleDeleteMetadataPair(index)}
                    onChangeError={(current) => handleOnChangeField(index, 'error', current)}
                  />
                );
              })}
            <div className="flex flex-row justify-center items-center">
              <Button
                color="primary"
                variant="contained"
                onClick={handleAddMetadataPair}
                startIcon={<AddIcon />}
                size="small"
              >
                Agregar más
              </Button>
            </div>
          </form>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
