import axios from 'axios';
import history from '@history';
import { showMessage } from 'app/store/fuse/messageSlice';
import _ from 'lodash';
import { removeAuthentication } from 'app/store/auth';
import { defaultLogoutRedirect } from 'app/configs/routesConfig';
import store from '../store';
import { getEnvMode, getToken } from './localStorage';

export const prefix = process.env.REACT_APP_API_PREFIX || 'api';

export function getEnvironment() {
  return new Promise((resolve) => {
    const checkEnvManager = () => {
      if (
        window.envpaths &&
        window.envpaths.dev &&
        window.envpaths.dev.apiHost &&
        window.envpaths.prod &&
        window.envpaths.prod.apiHost
      ) {
        const envManager = getEnvMode();
        console.log(envManager);
        if (!envManager || !envManager.isDev) {
          console.log('IN PROD');
          resolve(window.envpaths.prod.apiHost);
        } else {
          console.log('IN DEV');
          resolve(window.envpaths.dev.apiHost);
        }
      } else {
        console.log('waiting');
        // Si window.envpaths.dev.apiHost no está definido, esperamos un breve período de tiempo antes de verificar nuevamente
        setTimeout(checkEnvManager, 300);
      }
    };
    checkEnvManager();
  });
}

// create an axios instance
const service = axios.create({
  baseURL: '',
  timeout: 30000, // request timeout
});

service.interceptors.request.use(async (config) => {
  return getEnvironment().then((envPath) => {
    console.log('ENV PATH: ', envPath);
    config.baseURL = envPath;
    return config;
  });
});

// request interceptor
service.interceptors.request.use((config) => {
  config.headers.Accept = 'application/json';
  config.headers.Authorization = `Bearer ${getToken()}`;
  return config;
}, errorResponse);

// response interceptor
service.interceptors.response.use((response) => {
  const isBlob =
    response.config && response.config.responseType && response.config.responseType === 'blob';
  console.log(response.config.responseType, isBlob);
  if (isBlob) {
    return {
      success: true,
      data: response.data,
    };
  }
  return response.data;
}, errorResponse);

function errorResponse(error) {
  console.log(error);
  if (
    error.code === 'ERR_NETWORK' ||
    error.code === 'ERR_CERT_COMMON_NAME_INVALID' ||
    error.code === 'ERR_NAME_NOT_RESOLVED' ||
    error.code === 'ERR_CONNECTION_TIMED_OUT'
  ) {
    store.dispatch(showMessage({ message: `${error?.code?.toString()}: ${error.message}` }));
    // return Promise.reject(errDim);
    return false;
  }
  if (error.response && error.response.status === 401) {
    store.dispatch(removeAuthentication());
    showMessage({ message: 'Parece que la sesión ha caducado, intente de nuevo iniciar sesión' });
    history.replace(defaultLogoutRedirect);
    return false;
  }
  if (
    error.response &&
    _.inRange(error.response.status, 499, 600) &&
    error.response.status !== 502
  ) {
    console.log('RANGE ERROR 500 TO 600');
    const code = error.response.status ? error.response.status : error.code;
    const message = error.response.data.messages
      ? error.response.data.messages.join('\n')
      : error.response.data.message;
    store.dispatch(
      showMessage({
        message: `${code.toString()}: ${message}`,
        variant: 'danger',
      })
    );
    return false;
  }
  if (
    error.response &&
    _.inRange(error.response.status, 399, 498) &&
    error.response.status !== 422 &&
    error.response.status !== 403
  ) {
    const code = error.response.status ? error.response.status : error.code;
    const message = error.response.data.messages
      ? error.response.data.messages.join('\n')
      : error.response.data.message;
    store.dispatch(
      showMessage({
        message: `${code.toString()}: ${message}`,
        variant: 'danger',
      })
    );
  }
  return Promise.reject(error);
}

export default service;
