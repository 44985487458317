import { getToken, removeToken, setToken } from '../../services/localStorage';
import { checkingCredentials, loadTokenToStore, login, logout } from './authSlice';

const requestState = {
  success: 'SUCCESS',
  errorAuth: 'WRONG_CREDENTIALS',
  errorRequest: 'ERROR',
  waiting: 'WAITING',
};

export const checkAuthentication = () => {
  return async (dispatch) => {
    dispatch(checkingCredentials());
    const token = getToken();
    if (token !== undefined && token !== '' && token !== null) {
      dispatch(loadTokenToStore({ token, isAuthenticated: true }));
    } else {
      dispatch(logout());
      const errMessage = 'Token was not found';
    }
  };
};

export const forceAuthentication = () => {
  return async (dispatch) => {
    dispatch(loadTokenToStore({ token: 'ssn28290dn289swj8921892j892828', isAuthenticated: true }));
  };
};

export const setAuthentication = (token) => {
  return async (dispatch) => {
    setToken(token);
    dispatch(login({ token, isAuthenticated: true }));
  };
};

export const removeAuthentication = (token) => {
  return async (dispatch) => {
    removeToken();
    dispatch(logout());
  };
};
