import request, { prefix } from '../services/request';

export function search(params) {
  return request({
    url: `/${prefix}/torniquet/search`,
    method: 'POST',
    params,
  });
}

export function searchLicenses(params) {
  return request({
    url: `/${prefix}/torniquet/license/search`,
    method: 'POST',
    params,
  });
}

export function searchPage(params, page) {
  return request({
    url: `/${prefix}/torniquet/search?page=${page}`,
    method: 'POST',
    params,
  });
}

export function indexTorniquet(params = '') {
  return request({
    url: `/${prefix}/torniquet?${params}`,
    method: 'GET',
  });
}

export function getTorniquetById(id, data) {
  return request({
    url: `/${prefix}/torniquet/${id}`,
    method: 'GET',
    data,
  });
}

export function exportFile(params) {
  return request({
    url: `/${prefix}/torniquet/export`,
    method: 'POST',
    responseType: 'blob',
    params,
  });
}

export function exportFileLicense(params) {
  return request({
    url: `/${prefix}/torniquet/export/license`,
    method: 'POST',
    responseType: 'blob',
    params,
  });
}

// -------------- Catalogos para combobox ------------------------

export function catalogLocation() {
  return request({
    url: `/${prefix}/torniquet/catalog/locations`,
    method: 'GET',
  });
}

export function catalogTypes() {
  return request({
    url: `/${prefix}/torniquet/catalog/liceses/types`,
    method: 'GET',
  });
}

export function catalogStatus() {
  return request({
    url: `/${prefix}/torniquet/catalog/liceses/status`,
    method: 'GET',
  });
}
