import { useTheme } from '@mui/material/styles';
import ReactApexChart from 'react-apexcharts';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useState } from 'react';

export const GaugeCart = ({ data, title = 'No definido', color, chipTitle }) => {
  const theme = useTheme();

  const [serie, setSerie] = useState({
    name: 'Example',
    data: [31, 40, 28, 51, 42, 109, 100],
  });

  useEffect(() => {
    if (data === undefined) return;
    setSerie(data);
  },[data])

  const chartOptions = {
    chart: {
      animations: {
        enabled: false,
      },
      fontFamily: 'inherit',
      foreColor: 'inherit',
      height: '100%',
      type: 'area',
      sparkline: {
        enabled: true,
      },
    },
    colors: [color ? theme.palette[`${color}`].main : theme.palette.primary.main],
    fill: {
      colors: [color ? theme.palette[`${color}`].light : theme.palette.primary.light],
      opacity: 0.5,
    },
    stroke: {
      curve: 'smooth',
    },
    tooltip: {
      followCursor: true,
      theme: 'dark',
    },
    xaxis: {
      type: 'category',
      categories: title,
    },
  };

  return (
    <Paper className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden">
      <div className="flex items-start justify-between m-24 mb-0">
        <Typography className="text-lg font-medium tracking-tight leading-6 truncate">
          {title}
        </Typography>
        <div className="ml-8">
          {chipTitle && <Chip size="small" className="font-medium text-sm" label={chipTitle} />}
        </div>
      </div>
      <div className="flex flex-col lg:flex-row lg:items-center mx-24 mt-12">
        <Typography className="text-7xl font-bold tracking-tighter leading-tight">
          {serie.data ? serie.data[serie.data.length - 1].toLocaleString('en-US') : 0}
        </Typography>
        {/*
          <div className="flex lg:flex-col lg:ml-12">
          <FuseSvgIcon size={20} className="text-red-500">
            heroicons-solid:trending-down
          </FuseSvgIcon>
          <Typography
            className="flex items-center ml-4 lg:ml-0 lg:mt-2 text-md leading-none whitespace-nowrap"
            color="text.secondary"
          >
            <span className="font-medium text-red-500">2%</span>
            <span className="ml-4">below target</span>
          </Typography>
        </div>
        */}
      </div>
      <div className="flex flex-col flex-auto h-80">
        <ReactApexChart
          options={chartOptions}
          series={[serie]}
          type={chartOptions.chart.type}
          height={chartOptions.chart.height}
        />
      </div>
    </Paper>
  )
}
