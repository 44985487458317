import request, { prefix } from '../services/request';

export function search(params) {
  return request({
    url: `/${prefix}/user/search`,
    method: 'POST',
    params,
  });
}

export function searchPage(params, page) {
  return request({
    url: `/${prefix}/user/search?page=${page}`,
    method: 'POST',
    params,
  });
}

export function storeUser(data) {
  return request({
    url: `/${prefix}/user`,
    method: 'POST',
    data,
  });
}

export function deleteUser(id) {
  return request({
    url: `/${prefix}/user/${id}`,
    method: 'DELETE',
  });
}

export function getUserById(id) {
  return request({
    url: `/${prefix}/user/${id}`,
    method: 'GET',
  });
}

export function updateUser(data, id) {
  return request({
    url: `/${prefix}/user/${id}`,
    method: 'PUT',
    data,
  });
}

export function exportFile(params) {
  return request({
    url: `/${prefix}/user/export`,
    method: 'POST',
    responseType: 'blob',
    params,
  });
}

export function catalogLocation() {
  return request({
    url: `/${prefix}/group/catalog/locations`,
    method: 'GET',
  });
}
