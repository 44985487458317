import { Recovery } from './Recovery';
import authRoles from '../../auth/authRoles';

export const PasswordRecoveryConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: 'account-recovery',
      element: <Recovery />,
    },
  ],
};
