import { useDispatch } from 'react-redux';
import { removeAuthentication, setAuthentication } from 'app/store/auth';
import { showMessage } from 'app/store/fuse/messageSlice';
import { requestLogin, requestLogout } from '../api/auth';
import { useErrorResponse } from './useErrorResponse';

export const useAuthentication = () => {
  const Dispatch = useDispatch();
  const showError = useErrorResponse();

  const handleLogin = (email, password) => {
    return new Promise((resolve, reject) => {
      requestLogin({ email, password })
        .then(({ success, data }) => {
          if (success) {
            const token = data.access_token;
            Dispatch(setAuthentication(token));
          }
          resolve(success);
        })
        .catch((error) => {
          console.log('ERRORRRR ', error);
          let message = '';
          if (error.response.status === 403 && error.response.data.messages) {
            console.log('DSDSDS');
            const [msgErr] = error.response.data.messages;
            message = msgErr;
          } else {
            const { message: genericError } = error;
            message = genericError;
          }
          Dispatch(removeAuthentication());
          reject(message);
        });
    });
  };

  const handleLogout = async () => {
    try {
      await requestLogout();
      Dispatch(showMessage({ message: 'La sesión se ha finalizado correctamente' }));
    } catch (error) {
      showError(error);
    } finally {
      Dispatch(removeAuthentication());
    }
  };

  return { handleLogin, handleLogout };
};
