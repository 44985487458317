import request, { prefix } from '../services/request';

export function uploadImageFile(data, config) {
  return request({
    url: `/${prefix}/image`,
    method: 'post',
    data,
    ...config,
  });
}

export function updateImageFile(id, data) {
  return request({
    url: `/${prefix}/image/${id}`,
    method: 'put',
    data,
  });
}

// ----- borra la imagen y el registro de mongo asociado
export function destroyImageFile(id) {
  return request({
    url: `/${prefix}/image/${id}`,
    method: 'delete',
  });
}

// ---- borra la imagen sin registro usado para profile admin
export function deleteImageFile(data) {
  return request({
    url: `/${prefix}/image/delete`,
    method: 'post',
    data,
  });
}
