import { memo, useEffect, useState } from 'react';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { useDispatch, useSelector } from 'react-redux';
import history from '@history';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Grid } from '@mui/material';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
  BasicSelect,
  ButtonWithExpand,
  ComposedHeader,
  ComposedTable,
  InputSearch,
  Paginator,
} from 'src/app/components';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { deleteAdmin, exportFile, searchPage } from 'src/app/api/admin';
import { useSimpleAlert } from 'src/app/hooks/useSimpleAlert';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useRequest } from 'src/app/hooks/useRequest';
import { formatDate } from 'src/app/utils/dates';
import _ from 'lodash';
import { useDownloadBlob } from 'src/app/hooks/useDownloadBlob';
import { typeDownloadEnum } from 'src/app/utils/enums';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {},
  '& .FusePageSimple-content': {},
}));

const statusAdminsData = [
  {
    label: 'Todo',
    value: '',
  },
  {
    label: 'Activo',
    value: 'true',
  },
  {
    label: 'Inactivo',
    value: 'false',
  },
];

const tableRowScheme = [
  {
    title: 'ID',
    width: '80px',
  },
  {
    title: 'Perfil',
    width: '80px',
  },
  {
    title: 'Nombre',
    minWidth: '180px',
    width: '200px',
  },
  {
    title: 'Correo',
    minWidth: '120px',
  },
  {
    title: 'No. Telefónico',
    minWidth: '200px',
  },
  {
    title: 'Estatus',
    minWidth: '60px',
  },
  {
    title: 'Info. adicional',
    minWidth: '200px',
  },
  {
    title: 'Fecha de registro',
    minWidth: '150px',
  },
  {
    title: 'Opciones',
    minWidth: '240px',
    width: '240px',
    fixedRight: true,
  },
];

const OptionButtons = memo(({ authEmail = '', admin, onDelete, onUpdate }) => {
  return (
    <div className="flex w-full flex-wrap flex-row justify-between items-center">
      <Button
        className="my-3 mr-3"
        onClick={() => onUpdate(admin._id)}
        size="small"
        variant="contained"
        color="info"
        style={{ width: 'auto' }}
        startIcon={<EditOutlinedIcon />}
      >
        Editar
      </Button>
      <Button
        className="my-3 ml-3"
        onClick={() => onDelete(admin._id, admin.name)}
        size="small"
        variant="contained"
        color="error"
        style={{ width: 'auto' }}
        startIcon={<PersonOffOutlinedIcon />}
        disabled={admin.email === authEmail}
      >
        Deshabilitar
      </Button>
    </div>
  );
});

export const Access = () => {
  const { email: authenticatedAdminEmail } = useSelector((state) => state.user);
  const Dispatch = useDispatch();
  const openAlert = useSimpleAlert();
  // ------ States correspondiente a componentes de filtrado -------
  const [adminList, setAdminList] = useState([]);
  const [querySearch, setQuerySearch] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentItemsPerPage, setCurrentItemsPerPage] = useState(25);
  const [datePickerValue, setDatePickerValue] = useState([null, null]);
  // ------- Selects --------------
  const [adminStatus, setAdminStatus] = useState('');
  // ------ State general de formulario de buqueda ------------
  const [form, setForm] = useState({
    query: '',
    initDate: '',
    endDate: '',
    paginate: undefined,
  });
  // ----- Instancia de request que se usaran en la vista junto a sus valores de estado ---------
  const { makeRequest: tryGetAdmins, isLoading, response } = useRequest(searchPage);
  const { makeRequest: tryDisableAdmin } = useRequest(deleteAdmin);

  // ----- File List exports -----
  const { catalogFormats, downloadBlobFile } = useDownloadBlob(typeDownloadEnum, exportFile);

  const handleExportFile = async (format = 'txt') => {
    await downloadBlobFile(format, 'contactos');
  };

  // ---- Borra todos los filtros ------
  const handleClearAllFilters = () => {
    setQuerySearch('');
    setAdminStatus('');
    setCurrentPage(undefined);
    setCurrentItemsPerPage(25);
    setDatePickerValue([null, null]);
  };

  // ----- Actualiza el formulario acorde cambio de filtros
  useEffect(() => {
    setForm((current) => {
      return {
        ...current,
        query: querySearch,
        paginate: currentItemsPerPage,
        initDate: datePickerValue[0] === null ? '' : datePickerValue[0],
        endDate: datePickerValue[1] === null ? '' : datePickerValue[1],
        status: adminStatus === '' ? null : adminStatus.toString(),
      };
    });
  }, [querySearch, currentItemsPerPage, datePickerValue, adminStatus]);

  // ------ Actualiza la informacion de la tabla despues de la peticion -------
  useEffect(() => {
    if (!response) {
      return;
    }
    setAdminList(response.data);
    setTotalPages(response.last_page);
    setTotalItems(response.total);
    setCurrentPage(response.current_page);
  }, [response]);

  // -------- Realiza la peticion de datos al haber un cambio en el formulario -----
  useEffect(() => {
    if (form === undefined) {
      return;
    }
    console.log('FROM | QUERY => ', form);
    tryGetAdmins(form, currentPage);
  }, [currentPage, form]);

  // ------ Inicializacion de la vista con toda la informacion disponible -------
  useEffect(() => {
    tryGetAdmins(form);
    return () => {
      setAdminList([]);
    };
  }, []);

  // ------ Funciones para actualizar los filtros ------
  const handleSearchQuery = (query = '') => {
    if (!query.length > 3) {
      return;
    }
    setQuerySearch(query);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleChangeItems = (items) => {
    setCurrentItemsPerPage(items);
  };

  // ----- Acciones de botones en la tabla ----------
  const handleCreateAdmin = () => {
    history.push('/admin/create');
  };

  const handleDisable = async (id, name) => {
    openAlert({
      title: 'Atención',
      description: `Está seguro que desea deshabilitar al usuario ${name}?`,
    })
      .then((userResponse) => {
        if (!userResponse) {
          return;
        }
        tryDisableAdmin(id).then(({ isOk }) => {
          if (isOk) {
            Dispatch(
              showMessage({
                message: 'Administrador eliminado correctamente',
                autoHideDuration: 4000,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
                variant: 'success',
              })
            );
            tryGetAdmins();
          }
        });
      })
      .catch((error) => console.log(error));
  };

  const handleUpdate = (id) => {
    history.push(`/admin/edit/${id}`);
  };

  return (
    <Root
      header={
        <ComposedHeader
          breadcrumb
          title="Administradores"
          description="Información general de accesos autorizados"
        >
          <div className="px-4 py-4">
            <Button
              className="whitespace-nowrap mx-4"
              variant="contained"
              color="primary"
              onClick={() => handleCreateAdmin()}
              startIcon={<AdminPanelSettingsOutlinedIcon />}
            >
              Agregar administrador
            </Button>
          </div>
          <div className="px-4 py-4">
            <ButtonWithExpand
              title="Descargar"
              className="whitespace-nowrap mx-4"
              variant="contained"
              color="success"
              startIcon={<SimCardDownloadOutlinedIcon />}
            >
              {catalogFormats.map((option, index) => (
                <ButtonWithExpand.Item
                  key={`${index}_${option.value}_doc`}
                  text={`Documento ${option.label}`}
                  onClick={() => handleExportFile(option.value)}
                />
              ))}
            </ButtonWithExpand>
          </div>
        </ComposedHeader>
      }
      content={
        <Grid container className="px-32 pb-32 pt-0 flex flex-row w-full justify-center">
          <Grid item xs={12} md={12} className="flex w-full">
            <Card className="pb-24 px-24 mt-16 w-full">
              <CardContent className="flex w-full h-full flex-col justify-between">
                <div className="flex flex-col mt-24">
                  <Grid container className="w-full flex justify-center items-center py-8 mb-28">
                    <Grid item xs={12} md={7}>
                      <div className="flex flex-row flex-wrap justify-start items-center">
                        <div className="ml-4 mr-8 my-2 w-8/12 md:my-0">
                          <InputSearch
                            value={querySearch}
                            onChange={(query) => handleSearchQuery(query)}
                          />
                        </div>
                        <div className="ml-4 my-2 md:my-0">
                          <Button
                            fullWidth
                            size="medium"
                            variant="contained"
                            color="primary"
                            onClick={() => handleClearAllFilters()}
                          >
                            Limpiar filtros
                          </Button>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <div className="flex flex-row justify-center md:justify-end items-center md:mt-0 mt-8">
                        <LocalizationProvider>
                          <DateRangePicker
                            slotProps={{ textField: { size: 'small' } }}
                            localeText={{
                              start: 'Fecha inicial',
                              end: 'Fecha final',
                            }}
                            value={datePickerValue}
                            onChange={(current) => setDatePickerValue(current)}
                            disableFuture
                          />
                        </LocalizationProvider>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="p-2">
                    <Grid item xs={12} md={6} className="p-8 mb-24">
                      <div className="flex flex-row flex-wrap justify-start items-center">
                        <div className="px-4">
                          <BasicSelect
                            data={statusAdminsData}
                            onChange={(data) => setAdminStatus(data)}
                            title="Estatus"
                            value={adminStatus}
                            size="small"
                          />
                        </div>
                        {/*
                          Agrega mas selects
                        */}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} className="p-8 mb-24">
                      <div className="flex flex-row justify-end">
                        <Paginator
                          totalPages={totalPages}
                          totalItems={totalItems}
                          onChangeCurrentPage={(page) => handleChangePage(page)}
                          onChangeItemsPerPage={(items) => handleChangeItems(items)}
                          currentPage={currentPage}
                          currentItemsPerPage={currentItemsPerPage}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <div className="flex flex-col justify-center">
                    <ComposedTable
                      isLoading={isLoading}
                      className="w-full"
                      headers={tableRowScheme}
                      data={adminList}
                    >
                      <ComposedTable.Column
                        content={({ _id }) => (
                          <Tooltip title={_id}>
                            <span>{_id.toString().substr(-8)}</span>
                          </Tooltip>
                        )}
                      />
                      <ComposedTable.Column
                        content={(item) => (
                          <ComposedTable.Thumbail
                            color={item.background}
                            name={item.name}
                            baseUri={window.envpaths.prod.apiHost}
                            path={_.has(item, 'avatar') ? item.avatar.fullPathThumb : undefined}
                            alt={`perfil de ${item.name}`}
                          />
                        )}
                      />
                      <ComposedTable.Column
                        content={({ _id, name }) => (
                          <Button
                            size="small"
                            className="font-normal transition-colors duration-100 ease-in-out hover:text-blue-500"
                            onClick={() => history.push(`/admin/profile/${_id}`)}
                          >
                            {name}
                          </Button>
                        )}
                      />
                      <ComposedTable.Column content={({ email }) => email} />
                      <ComposedTable.Column content={({ phone }) => phone} />
                      <ComposedTable.Column
                        content={({ status }) => (status ? 'Activo' : 'Inactivo')}
                      />
                      <ComposedTable.Column content={() => <ComposedTable.NestedButton />} />
                      <ComposedTable.Column content={(item) => formatDate(item.created_at)} />
                      <ComposedTable.Column
                        fixedRight
                        content={(item) => (
                          <OptionButtons
                            authEmail={authenticatedAdminEmail}
                            admin={item}
                            onDelete={handleDisable}
                            onUpdate={handleUpdate}
                          />
                        )}
                      />
                    </ComposedTable>
                  </div>
                </div>
                <Grid container spacing={1} className="p-2 mt-24 flex">
                  <Grid item xs={12} md={12} className="py-8">
                    <div className="flex flex-row justify-end">
                      <Paginator
                        totalPages={totalPages}
                        totalItems={totalItems}
                        onChangeCurrentPage={(page) => handleChangePage(page)}
                        onChangeItemsPerPage={(items) => handleChangeItems(items)}
                        currentPage={currentPage}
                        currentItemsPerPage={currentItemsPerPage}
                      />
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      }
    />
  );
};
