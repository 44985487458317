const TokenKey = 'AuthTokenGenericFuse';
const appEnvMode = 'AppEnvMode';

// ------- Token Storage ----------------

export function getToken() {
  return localStorage.getItem(TokenKey);
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token);
}

export function removeToken() {
  return localStorage.removeItem(TokenKey);
}

// ------- Environment Mode App ----------

export function getEnvMode() {
  const envModeString = localStorage.getItem(appEnvMode);
  const envModeObj = JSON.parse(envModeString);
  return envModeObj;
}

export function setEnvMode(envModeObj) {
  const envModeString = JSON.stringify(envModeObj);
  return localStorage.setItem(appEnvMode, envModeString);
}

export function removeEnvMode() {
  return localStorage.removeItem(appEnvMode);
}
