import history from '@history';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { ComposedHeader, FormCardLayout, LargeAvatar, TextWithLabel } from 'src/app/components';
import { getAdminById } from 'src/app/api/admin';
import { useRequest } from 'src/app/hooks/useRequest';
import { useEffect, useState } from 'react';
import _ from 'src/@lodash/@lodash';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {},
  '& .FusePageSimple-content': {},
}));
// export const Profile: React.FC<ProfileProps> = () => {
export const ProfileAdmins = () => {
  const { id: previousId } = useParams();
  const [currentAdmin, setCurrentAdmin] = useState({
    _id: '',
    name: '',
    email: '',
    phone: '',
    role: [],
    avatar: undefined,
  });

  const {
    makeRequest: getCurrentAdmin,
    isLoading: isLoadingAdmin,
    response: responseShowAdmin,
  } = useRequest(getAdminById);

  // ------ Funcion para cargar los datos obtenidos de request
  const handleLoadToForm = (previousData) => {
    const result = _.omit(previousData, ['updated_at', 'created_at']);
    setCurrentAdmin(result);
    console.log(result);
  };

  // ------ Realiza request para cargar información del admin
  useEffect(() => {
    if (!previousId) {
      return;
    }
    getCurrentAdmin(previousId);
  }, []);

  // ------ Escucha response de request de Admin
  useEffect(() => {
    console.log(responseShowAdmin);
    if (!responseShowAdmin) {
      return;
    }
    handleLoadToForm(responseShowAdmin);
  }, [responseShowAdmin]);

  return (
    <Root
      header={
        <ComposedHeader
          breadcrumb
          // title="Administradores"
          // description="Información general de accesos autorizados"
        />
      }
      content={
        <Box
          className="flex justify-center items-start"
          sx={{
            width: '100%',
            height: '100%',
          }}
        >
          <FormCardLayout title="Perfil de Adminstrador">
            <FormCardLayout.HalfColum>
              <div className="flex flex-auto flex-col justify-center items-center">
                <LargeAvatar
                  path={
                    currentAdmin.avatar !== undefined ? currentAdmin.avatar.pathThumb : undefined
                  }
                  name={currentAdmin.name}
                />
              </div>
            </FormCardLayout.HalfColum>
            <FormCardLayout.HalfColum>
              <TextWithLabel title="Nombre">{currentAdmin.name}</TextWithLabel>
              <TextWithLabel title="Correo">{currentAdmin.email}</TextWithLabel>
              <TextWithLabel title="Número telefónico">
                {currentAdmin.phone || 'No disponible'}
              </TextWithLabel>
              <TextWithLabel title="Empresa">
                {currentAdmin.company || 'No disponible'}
              </TextWithLabel>
              <TextWithLabel title="Permisos">{currentAdmin.role || 'No disponible'}</TextWithLabel>
            </FormCardLayout.HalfColum>
            <FormCardLayout.RowButton>
              <Button color="info" variant="contained" onClick={() => history.back()}>
                Regresar
              </Button>
              <Button
                color="info"
                variant="contained"
                onClick={() => history.push(`/admin/edit/${previousId}`)}
              >
                Editar información
              </Button>
              <Button
                color="primary"
                onClick={() => history.push(`/admin/recovery/`)}
                variant="contained"
              >
                Cambiar contraseña
              </Button>
            </FormCardLayout.RowButton>
          </FormCardLayout>
        </Box>
      }
    />
  );
};
