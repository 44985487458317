import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getNotifications } from './asyncThunks';

const SOCKET_NOTIFY_ENVPATH = process.env.REACT_APP_SOCKET_NOTIFY_PATH || 'http://notify.intranet.webdecero.dev';

const panelInitialState = {
  visibility: false,
};

const notificationsInitialState = {
  notifySocketPath: SOCKET_NOTIFY_ENVPATH,
  panel: panelInitialState,
  notifications: [],
  isLoadingData: false,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: notificationsInitialState,
  reducers: {
    setVisibility: (state, { payload }) => {
      state.panel.visibility = payload.visibility;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.fulfilled, (state, action) => {
        console.log(action.payload);
        state.notifications = action.payload;
        state.isLoadingData = false;
      })
      .addCase(getNotifications.pending, (state) => {
        state.isLoadingData = true;
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.isLoadingData = false;
      });
  },
});

export default notificationsSlice.reducer;
export const { setVisibility } = notificationsSlice.actions;
