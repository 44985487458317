export const regexName = /^(?!\s)(?!.*\s$)(?!^\s)[A-Za-zñÑáéíóúÁÉÍÓÚ]+(['|\-|\s]?[A-Za-zñÑáéíóúÁÉÍÓÚ]+)*$/;
export const regexTitle = /^(?!\s)(?!.*\s$)(?!^\s)(['"]?[A-Za-zñÑáéíóúÁÉÍÓÚ0-9]+(['|\-_\s]?[A-Za-zñÑáéíóúÁÉÍÓÚ0-9"'|]+)*['"]?)$/;
export const regexEmail = /^[a-z0-9][-a-z0-9._]+@([-a-z0-9]+[.])+[a-z]{2,5}$/;
export const regexPhone = /^(\+?)[0-9]{5,30}$/;
export const regexPassword = /^[\w]{6,30}$/;
export const regexOTP = /^[0-9]{4}$/;
export const regexKeyName = /^([a-zA-Z0-9](_?|-?))+([a-zA-Z0-9])$/;
export const regexObjectKey = /^[a-zA-Z]+(?:[-_][a-zA-Z]+)*$/;
export const regexURL =
  /^(http(s?):\/\/)?(((www\.)?[a-zA-Z0-9.\-_]+(\.[a-zA-Z]{2,3})+)|(\b(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b))(:(\d{1,5}))?(\/[a-zA-Z0-9_\-\s./?%#&=]*)?$/i;
export const regexSerial = /^[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*$/;
export const regextSerialMax39 = /^(?=.{39}$)[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*$/;

export function validateName(name) {
  const nameExp = regexName;
  return nameExp.test(name);
}

export function validateTitle(name) {
  const titleExp = regexTitle;
  return titleExp.test(name);
}

export function validateEmail(email) {
  const emailExp = regexEmail;
  return emailExp.test(email);
}

export function validatePhone(phone) {
  const phoneExp = regexPhone;
  return phoneExp.test(phone);
}

export function validatePassword(password) {
  const passExp = regexPassword;
  return passExp.test(password);
}

export function validatePin(pin) {
  const otpExp = regexOTP;
  return otpExp.test(pin);
}

export function validateNumberInPath(path) {
  const numInPathExp = /\d+/;
  return numInPathExp.test(path);
}

export function validateKeyName(keyName) {
  const keyNameExp = regexKeyName;
  return keyNameExp.test(keyName);
}

export function validateObjectKey(keyName) {
  const keyNameExp = regexObjectKey;
  return keyNameExp.test(keyName);
}

export function validateURL(URL) {
  const urlExp = regexURL;
  return urlExp.test(URL);
}

export function validateSerial(serial) {
  const serialExp = regexSerial;
  return serialExp.test(serial);
}

export function validateSerialMax39(serial) {
  const serialMaxExp = regextSerialMax39;
  return serialMaxExp.test(serial);
}
