import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

export const ContactListItem = ({ contact, onClick = () => { } }) => {
  return (
    <>
      <ListItem
        className="px-32 py-16"
        sx={{
          filter: 'brightness(1)',
          transition: 'filter 0.36s ease',
          bgcolor: 'background.paper',
          cursor: 'pointer',
          '&:hover': {
            filter: 'brightness(0.92)',
          },
        }}
        onClick={onClick}
      >
        <ListItemAvatar>
          <Avatar alt={contact.name} src={contact.avatar} />
        </ListItemAvatar>
        <ListItemText
          classes={{ root: 'm-0', primary: 'font-medium leading-5 truncate' }}
          primary={contact.name}
          secondary={
            <Typography className="inline" component="span" variant="body2" color="text.secondary">
              {contact.title}
            </Typography>
          }
        />
      </ListItem>
      <Divider />
    </>
  );
};
