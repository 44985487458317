import { createSlice } from '@reduxjs/toolkit';
import { getConfigs } from './asyncThunks';

const LOCAL_SOCKET_PATH = process.env.REACT_APP_LOCAL_SOCKET_PATH;

// ------ App Identity | Tiene la informacion del tema customizado e identidad de Company Biometrics --------
const configInitialState = {
  main: {
    sockets: {
      extract: {
        url: LOCAL_SOCKET_PATH || 'localhost:9092',
      },
    },
  },
  config: {
    name: 'Biometrics Manager Company',
    version: '0.1.3',
    description: '',
    company: {
      key: undefined,
      name: undefined,
      isMultiLocation: true,
      isGroupActive: true,
      status: true,
      metadata: {},
    },
    notify: {
      url: undefined,
      options: {
        client: '4',
        transport: 'websocket',
      },
    },
    api: {
      host: undefined,
    },
  },
};

const configSlice = createSlice({
  name: 'config',
  initialState: configInitialState,
  reducers: {
    setConfigState: (state, { payload }) => {
      state.config = payload.config;
    },
    resetConfig: (state) => {
      state.config = configInitialState;
    },
    setApiHost: (state, { payload }) => {
      state.config.api.host = payload.api.host;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConfigs.fulfilled, (state, { payload }) => {
      state.config.api.host = payload.prod.apiHost;
      state.config.notify.url = payload.prod.notifyHost;
    });
  },
});

export default configSlice.reducer;
export const { setConfigState, resetConfig, setApiHost } = configSlice.actions;
