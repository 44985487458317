import FuseLoading from '@fuse/core/FuseLoading';
import { Navigate } from 'react-router-dom';
import { generateRoutesFromAsyncList } from '../utils/routes';
import {
  AdminConfig,
  BillingConfig,
  ConfigurationConfig,
  DashboardConfig,
  Error404Page,
  ErrorPage,
  FingerprintsConfig,
  GroupsConfig,
  KiosksConfig,
  LocationsConfig,
  NetPayConfig,
  ShopConfig,
  SignInConfig,
  SignOutConfig,
  SignUpConfig,
  StripeConfig,
  TurnstilesConfig,
  UserRegistersConfig,
  UsersConfig,
  PasswordRecoveryConfig,
} from '../views';

// ---- Rutas estáticas base que no requieren autenticación
export const staticRoutes = [
  {
    path: 'loading',
    element: <FuseLoading />,
  },
  {
    path: '404',
    element: <Error404Page />,
  },
  {
    path: 'error',
    element: <ErrorPage />,
  },
  {
    path: '*',
    element: <Navigate to="/404" />,
  },
];

// ---- Aqui se agregan las rutas que se vayan requiriendo en la app --------
const mergedRoutes = [
  DashboardConfig,
  SignOutConfig,
  SignInConfig,
  SignUpConfig,
  GroupsConfig,
  UsersConfig,
  FingerprintsConfig,
  BillingConfig,
  ShopConfig,
  StripeConfig,
  NetPayConfig,
  KiosksConfig,
  TurnstilesConfig,
  UserRegistersConfig,
  LocationsConfig,
  AdminConfig,
  ConfigurationConfig,
  PasswordRecoveryConfig,
];

// ------ Exporta rutas al contexto global de la App -------------
export const asyncRoutes = [...generateRoutesFromAsyncList(mergedRoutes), ...mergedRoutes];

// ----- Lista blanca de rutas dinamicas que no requieren de autenticacion para acceder ------------
export const whiteList = ['sign-in', 'sign-out', 'sign-up', '500', '404', 'account-recovery'];

// ----- Ruta por default para redirigir cuando usuario este sesión ----------
export const defaultLoginRedirect = '/dashboard';
// ----- Ruta por default para redirigir cuando usuario cerre sesión ----------
export const defaultLogoutRedirect = '/sign-in';
