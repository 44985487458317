import { createSlice } from '@reduxjs/toolkit';

const URL_ENV = process.env.REACT_APP_API_BASE_PATH;
const URL_BASE =
  URL_ENV !== undefined && URL_ENV !== null ? URL_ENV : 'https://api.bio-metrics.net';
// ------ Authentication --------
const userInitialState = {
  token: null,
  urlFiles: URL_BASE,
  isAuthenticated: true,
  isCheckingAuth: false,
  authTimeLeftVisibility: true,
  authTimeLeftOverflow: false,
  authCredentials: true,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: userInitialState,
  reducers: {
    login: (state, { payload }) => {
      state.token = payload.token;
      state.isAuthenticated = payload.isAuthenticated;
      // state.isCheckingAuth = false;
    },
    logout: (state) => {
      state.token = null;
      state.isAuthenticated = false;
      state.isCheckingAuth = false;
    },
    checkingCredentials: (state) => {
      state.isCheckingAuth = true;
    },
    resetToken: (state) => {
      state.token = null;
      state.isCheckingAuth = false;
      state.isAuthenticated = false;
    },
    loadTokenToStore: (state, { payload }) => {
      state.token = payload.token;
      state.isCheckingAuth = false;
      state.isAuthenticated = payload.isAuthenticated;
    },
    finishLoad: (state) => {
      state.isCheckingAuth = false;
    },
    setRedirectAuthPath: (state, { payload }) => {
      state.redirectAuthPath = payload.redirectAuthPath;
    },
    setTimeLeftVisibility: (state, { payload }) => {
      state.authTimeLeftVisibility = payload.authTimeLeftVisibility;
    },
    setTimeLeftOverflow: (state, { payload }) => {
      state.authTimeLeftOverflow = payload.authTimeLeftOverflow;
    },
    setCredentialStatus: (state, { payload }) => {
      state.authCredentials = payload.authCredentials;
    },
  },
});

export default authSlice.reducer;
export const {
  login,
  logout,
  checkingCredentials,
  resetToken,
  loadTokenToStore,
  finishLoad,
  setTimeLeftVisibility,
  setTimeLeftOverflow,
  setCredentialStatus,
} = authSlice.actions;
