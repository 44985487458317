import { Children, cloneElement, createContext, memo, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import _ from 'lodash';
import { use } from 'i18next';

const stickyRightStyled = 'md:sticky md:right-0 md:border-l-1';
const stickyLeftStyled = 'md:sticky md:left-0 md:border-r-1 z-50';

const TableContext = createContext({});
const { Provider } = TableContext;

const WithProvider = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  return <Provider value={{ isCollapsed, setIsCollapsed }}>{children}</Provider>;
};

const Collapsed = ({ nestedData }) => {
  const { isCollapsed } = useContext(TableContext);
  const [metadata, setMetadata] = useState([]);

  const transformMetadata = (metadataField = {}) => {
    const transformedMetadata = Object.keys(metadataField).map((key) => ({
      keyObj: key,
      valueObj: metadataField[key],
    }));
    setMetadata(transformedMetadata);
  };

  useEffect(() => {
    if (!nestedData) {
      return;
    }
    transformMetadata(nestedData);
  }, [nestedData]);

  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={isCollapsed} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            {metadata.length > 0 ? (
              <Table size="small" aria-label="metadata">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: '100%' }}>Clave</TableCell>
                    <TableCell sx={{ width: '100%' }}>Valor</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {metadata.map((rowItem, index) => (
                    <TableRow key={`nestedtable_${rowItem.keyObj}_${index}`}>
                      <TableCell component="th" scope="row">
                        {rowItem.keyObj}
                      </TableCell>
                      <TableCell>{rowItem.valueObj}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <div className="flex flex-auto w-full py-16 flex-col justify-center items-center">
                <Typography variant="subtitle1" gutterBottom>
                  No hay datos disponibles
                </Typography>
              </div>
            )}
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export const ComposedTableFlat = ({
  selectable = false,
  onRowClick = () => { },
  children,
  headers = [],
  data,
  metadata = () => { },
  isLoading,
  size = 'small',
  maxHeight,
  headerTextColor,
  headerBgColor,
}) => {
  return (
    <TableContainer
      sx={{
        borderRadius: '0.4rem',
        position: 'relative',
        maxHeight: maxHeight || '180rem',
        minHeight: '16rem',
      }}
    >
      <Table aria-label="composed table component" size={size} stickyHeader>
        <TableHead>
          <TableRow>
            {headers.map(
              (
                { title, width, minWidth, maxWidth, fixedRight, fixedLeft, align = 'center' },
                index
              ) => (
                <TableCell
                  key={`${title}_${index}`}
                  align={align}
                  style={{
                    width,
                    minWidth,
                    maxWidth,
                    color: headerTextColor || undefined,
                    backgroundColor: headerBgColor || undefined,
                  }}
                  className={`${fixedRight && !fixedLeft ? `${stickyRightStyled} md:border-l-neutral-700 md:shadow-md` : ''} ${!fixedRight && fixedLeft ? `${stickyLeftStyled} md:border-r-neutral-700 md:shadow-md` : ''
                  }`}
                >
                  {title}
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, indexRow) => (
            <WithProvider key={indexRow}>
              <TableRow
                onClick={() => onRowClick(indexRow, item)}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  cursor: selectable ? 'pointer' : undefined,
                }}
              >
                {Children.map(children, (child) => {
                  return cloneElement(child, { item });
                })}
              </TableRow>
              <Collapsed nestedData={_.has(item, 'metadata') ? item.metadata : undefined} />
            </WithProvider>
          ))}
        </TableBody>
      </Table>
      {!data.length > 0 && !isLoading && (
        <div
          className="bg-slate-950 h-full w-full left-0 top-0 bg-opacity-50 flex flex-auto justify-center items-end"
          style={{ position: 'absolute' }}
        >
          <div className="mt-16 mb-24">
            <Typography variant="body2">No hay datos que mostrar</Typography>
          </div>
        </div>
      )}
      {isLoading && (
        <div
          className="h-full w-full left-0 top-0 flex flex-auto justify-center items-center"
          style={{
            position: 'absolute',
            backgroundColor: 'rgba(56, 56, 56, 0.22)',
            backdropFilter: 'blur(1px)',
            zIndex: 100,
          }}
        >
          <div className="mt-16">
            <CircularProgress sx={{ color: '#FEFEFE' }} size={60} />
          </div>
        </div>
      )}
    </TableContainer>
  );
};

const Column = ({ fixedRight, fixedLeft, content = () => { }, item }) => {
  const handleRenderCol = () => {
    const rendered = content(item);
    return rendered;
  };

  return (
    <TableCell
      align="center"
      className={`${fixedRight && !fixedLeft ? `${stickyRightStyled} md:border-l-neutral-400 md:shadow-md` : ''} ${!fixedRight && fixedLeft ? `${stickyLeftStyled} md:border-r-neutral-400 md:shadow-md` : ''
        } `}
      sx={{ backgroundColor: (_theme) => _theme.palette.background.default }}
    >
      {handleRenderCol()}
    </TableCell>
  );
};

const NestedButton = () => {
  const { isCollapsed, setIsCollapsed } = useContext(TableContext);
  return (
    <IconButton aria-label="expand row" size="small" onClick={() => setIsCollapsed(!isCollapsed)}>
      {!isCollapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </IconButton>
  );
};

const Thumbail = ({ path, alt, name, color, square = false }) => {
  const { urlFiles } = useSelector((state) => state.auth);
  return (
    <div className="flex justify-center items-center">
      {path === undefined ? (
        <Avatar
          alt={alt}
          sx={{ bgcolor: color || undefined }}
          variant={square ? 'square' : undefined}
        >
          {name
            ? name
              .split(' ')
              .map((word) => word[0].toUpperCase())
              .join('')
              .substring(0, 2)
            : 'N/D'}
        </Avatar>
      ) : (
        <Avatar alt={alt} src={`${urlFiles}/storage/${path}`} />
      )}
    </div>
  );
};

const Preview = ({ disk, path = '', alt, width, height }) => {
  const { urlFiles } = useSelector((state) => state.auth);
  const [urlPath, setUrlPath] = useState('');

  useEffect(() => {
    const hasDisk = disk || '';
    setUrlPath(`${urlFiles}/${hasDisk}/${path}`);
  }, [disk, path, urlFiles]);

  useEffect(() => {
    console.log(urlPath);
  }, [urlPath]);

  return (
    <div className="flex justify-center" style={{ width, height }}>
      <img className="w-full object-contain" alt={alt} src={urlPath} />
    </div>
  );
};

ComposedTableFlat.Column = Column;
ComposedTableFlat.NestedButton = memo(NestedButton);
ComposedTableFlat.Thumbail = Thumbail;
ComposedTableFlat.Preview = Preview;
