import { motion } from 'framer-motion';
import Typography from '@mui/material/Typography';

export const SimpleHeader = ({ children, title, description }) => {
  return (
    <div className="relative flex flex-col w-full px-24 sm:px-32">
      <div className="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 flex-wrap py-24">
        <div className="flex flex-auto items-center min-w-0 justify-center md:justify-start">
          <motion.div
            className="flex flex-col min-w-0 mx-16"
            initial={{ x: -20, opacity: 0 }}
            animate={{ x: 0, transition: { delay: 0.3 }, opacity: 1 }}
          >
            <Typography className="text-2xl text-center md:text-left md:text-5xl font-semibold tracking-tight leading-7 md:leading-snug truncate">
              {title || 'Sin título'}
            </Typography>
            <div className="flex items-center">
              {description && (
                <Typography className="mx-6 leading-6 truncate" color="text.secondary">
                  {description}
                </Typography>
              )}
            </div>
          </motion.div>
        </div>
        <motion.div
          className="flex flex-auto items-center justify-center md:justify-end min-w-0"
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
        >
          {children && <div className="flex flex-col min-w-0 mx-16">{children}</div>}
        </motion.div>
      </div>
    </div>
  );
};
