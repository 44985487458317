import request, { prefix } from '../services/request';

export function validateEmail(data) {
  return request({
    url: `/${prefix}/admin/valid/email`,
    method: 'POST',
    data,
  });
}

export function validatePhone(data) {
  return request({
    url: `/${prefix}/admin/valid/phone`,
    method: 'POST',
    data,
  });
}
