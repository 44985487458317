import { createContext, memo, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import history from '@history';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import NotificationModel from 'app/store/notifications/model/NotificationModel';
import { useSnackbar } from 'notistack';
import { getNotifications } from 'app/store/notifications';
import { useSocket } from '../hooks/useSocket';
import { NotificationTemplate } from '../components/NotificationPanel/NotificationTemplate';

const USER_NAME = 'USER->NOTIFICATIONS';

export const NotificationClientContext = createContext({});
const { Provider } = NotificationClientContext;

export const NotificationClientProvider = memo(({
    children,
    autoReconnect = false,
    uri,
    user = {},
    enabled,
    space = '',
    event = 'notification',
    originPlatform = 'platform',
    externalDeviceId,
    externalNotificationApi,
    onConnected = () => {},
  }) => {
    const Dispatch = useDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [deviceId, setDeviceId] = useState(undefined);
    const [originId, setOriginId] = useState(undefined);
    const [originModel, setOriginModel] = useState(undefined);

    useEffect(() => {
      if (user._id === undefined) return;
      setOriginId(user._id);
    }, [user?._id]);

    useEffect(() => {
      if (user.model === undefined) return;
      setOriginModel(user.model);
    }, [user?.model]);

    const {
      emitEvent: notifyLicenseEmit,
      listenEvent: notifylicenseOn,
      socketState: notifyLicenseState,
      updateUrl: notifyUpdateUrl,
      updateQuery: notifyLicenseSetQuery,
      tryConnect: notifyTryConnect,
      tryDisconnect: notifyTryDisconnect,
      socketRef: notifySocketRef,
      socketStatus,
    } = useSocket(uri, space, {
      name: USER_NAME,
      autoReconnect,
    });

    useEffect(() => {
      if (notifyLicenseState === undefined) return;
      if (notifyLicenseState === socketStatus.CONNECTED) {
        onConnected(true);
      } else {
        onConnected(false);
      }
    }, [notifyLicenseState]);

    // -------- Handlers ----------
    const handleSetNotification = (payload) => {
      if (payload === undefined) return;
      const filteredItem = _.omit(payload, [
        'targetModel',
        'targetId',
        'targetPlatform',
        'updated_at',
        'deleted_at',
      ]);
      const item = NotificationModel(filteredItem);
      if (externalNotificationApi && typeof externalNotificationApi === 'function') {
        externalNotificationApi(item);
      }
      enqueueSnackbar(item.title, {
        key: item._id,
        autoHideDuration: '8000',
        content: () => (
          <NotificationTemplate
            item={item}
            onClose={() => {
              closeSnackbar(item._id);
            }}
            onClick={(route) => history.push(route)}
          />
        ),
      });
      const timeNotify = setTimeout(() => {
        Dispatch(getNotifications());
        clearTimeout(timeNotify);
      }, 800);
    };

    // -------- Watchers ----------
    useEffect(() => {
      if (externalDeviceId === undefined) {
        const newId = uuidv4();
        setDeviceId(newId);
      } else {
        setDeviceId(externalDeviceId);
      }
    }, [externalDeviceId]);

    useEffect(() => {
      notifyTryDisconnect();
    }, []);

    // -------- Si setup autoriza uso de App se inicializa socket
    useEffect(() => {
      if (!enabled) {
        notifyTryDisconnect();
      } else {
        notifyTryConnect();
      }
    }, [enabled]);

    // ------ Actualizacion de parametros por Query para autorizacion de conexion con Notify
    useEffect(() => {
      console.table(originId, originModel, originPlatform, deviceId);
      if (
        originId === undefined ||
        originModel === undefined ||
        originPlatform === undefined ||
        !enabled
      )
        return;
      notifyLicenseSetQuery({
        originId,
        originModel,
        originPlatform,
        deviceId,
      })
        .then((resQuery) => {
          console.log('Changed query?', resQuery);
        })
        .catch((err) => console.log('socker query err', err));
    }, [enabled, originId, originModel, originPlatform, deviceId]);

    // ------ Actualizacion de referencia de url de conexion para socket Notify acorde archivo de configuracion envpaths.
    useEffect(() => {
      if (uri !== undefined) return;
      notifyUpdateUrl(`${uri}`).then((resUri) => {
        console.log('Changed Uri? ', resUri);
      });
    }, [uri]);

    // -------- Inicializando Listeners ON ---
    useEffect(() => {
      if (event === undefined) return;
      notifylicenseOn(event, (res) => {
        console.log(res);
        if (res !== undefined) {
          handleSetNotification(res);
        }
      });
    }, [event]);

    return <Provider value={{ notifyLicenseState }}>{children}</Provider>;
  }
);
