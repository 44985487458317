import { keyframes, styled } from '@mui/material/styles';

const animLoader = keyframes`
  0%, 100% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    transform: scale(1);
    opacity: 0;
  }
`;

export const PointLoader = styled('div')(({ theme, sx, childSx }) => ({
  width: sx && sx.width ? sx.width : '20px',
  height: sx && sx.height ? sx.height : '20px',
  display: 'inline-block',
  position: 'relative',

  '&::after, &::before': {
    content: '""',
    width: sx && sx.width ? sx.width : '20px',
    height: sx && sx.height ? sx.height : '20px',
    borderRadius: '50%',
    backgroundColor: theme.palette.background.default,
    position: 'absolute',
    left: 0,
    top: 0,
    boxSizing: 'border-box',
    animation: `${animLoader} 2s ease-in-out infinite`,
    ...childSx,
  },

  '&::after': {
    animationDelay: '1s',
  },
  ...sx,
}));
