export const socketConnectionEnum = {
  CONNECTING: 'connecting',
  CONNECTED: 'connected',
  DISCONNECTED: 'disconnected',
  ERROR: 'error',
};

export const licenseStatusEnum = {
  Initialized: {
    value: 'Initialized',
    color: 'primary',
  },
  PendingActive: {
    value: 'PendingActive',
    color: 'warning',
  },
  PendingDeactive: {
    value: 'PendingDeactive',
    color: 'warning',
  },
  Active: {
    value: 'Active',
    color: 'success',
  },
  Deactive: {
    value: 'Deactive',
    color: 'error',
  },
};

export const typeResourceEnum = {
  scorm: {
    symbol: 'scorm',
    label: 'Scorm',
    color: 'warning',
    format: {
      'application/zip': ['.zip'],
      'application/x-rar-compressed': ['.rar'],
    },
  },
  pdf: {
    symbol: 'pdf',
    label: 'PDF',
    color: 'error',
    format: { 'application/pdf': ['.pdf'] },
  },
  video: {
    symbol: 'video',
    label: 'Video',
    color: 'info',
    format: { 'video/mp4': ['.mp4'] },
  },
};

export const typeDownloadEnum = {
  txt: {
    symbol: 'TXT',
    label: 'Text',
    color: 'primary',
    format: 'plain/text',
    extension: 'txt',
  },
  xlsx: {
    symbol: 'XLSX',
    label: 'Excel',
    color: 'success',
    format: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    extension: 'xlsx',
  },
  csv: {
    symbol: 'CSV',
    label: 'CSV',
    color: 'primary',
    format: 'text/csv',
    extension: 'csv',
  },
  html: {
    symbol: 'HTML',
    label: 'HTML',
    color: 'warning',
    format: 'text/html',
    extension: 'html',
  },
  pdf: {
    symbol: 'PDF',
    label: 'PDF',
    color: 'error',
    format: 'application/pdf',
    extension: 'pdf',
  },
};