import { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import history from '@history';
import { useRequest } from 'src/app/hooks/useRequest';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {
  BasicSelect,
  ButtonWithExpand,
  ComposedHeader,
  ComposedTable,
  InputSearch,
  Paginator,
} from 'src/app/components';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { useSimpleAlert } from 'src/app/hooks/useSimpleAlert';
import { showMessage } from 'app/store/fuse/messageSlice';
import { formatDate } from 'src/app/utils/dates';
import { deleteGroup, exportFile, searchPage } from 'src/app/api/group';
import _ from 'lodash';
import { useDownloadBlob } from 'src/app/hooks/useDownloadBlob';
import { typeDownloadEnum } from 'src/app/utils/enums';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {},
  '& .FusePageSimple-content': {},
}));

const statusGroupsData = [
  {
    label: 'Todo',
    value: '',
  },
  {
    label: 'Activo',
    value: 'true',
  },
  {
    label: 'Inactivo',
    value: 'false',
  },
];

const locationsData = [
  {
    label: 'Todo',
    value: '',
  },
  {
    label: 'San Jerónimo',
    value: 'SJ',
  },
  {
    label: 'Sátelite',
    value: 'SAT',
  },
];

const tableRowScheme = [
  {
    title: 'ID',
    minWidth: '80px',
  },
  {
    title: 'Perfil',
    width: '80px',
  },
  {
    title: 'Título',
    minWidth: '180px',
    width: '200px',
  },
  {
    title: 'Descripción',
    width: '260px',
    minWidth: '200px',
  },
  {
    title: 'Dirección',
    width: '240px',
    minWidth: '200px',
  },
  {
    title: 'Teléfono',
    width: '120px',
  },
  {
    title: 'No de Usuarios',
    width: '60px',
  },
  {
    title: 'Sucursales',
    minWidth: '200px',
  },
  {
    title: 'Estatus',
    width: '60px',
  },
  {
    title: 'Información adicional',
    minWidth: '130px',
  },
  {
    title: 'Fecha de Registro',
    minWidth: '160px',
  },
  {
    title: 'Opciones',
    // minWidth: '320px',
    minWidth: '240px',
    fixedRight: true,
  },
];

const OptionButtons = memo(({ onDelete = () => {}, onUpdate = () => {}, onShow = () => {} }) => {
  return (
    <div className="flex w-full flex-wrap flex-row justify-around items-center">
      <Button
        className="my-3"
        onClick={() => onShow()}
        size="small"
        variant="contained"
        color="info"
        style={{ width: 'auto' }}
        startIcon={<PeopleAltOutlinedIcon />}
      >
        Usuarios
      </Button>
      <Tooltip title="Editar grupo">
        <Button
          className="my-3"
          onClick={() => onUpdate()}
          size="small"
          variant="contained"
          color="info"
          sx={{ minWidth: '0.8rem', padding: '0.36rem' }}
        >
          <EditOutlinedIcon />
        </Button>
      </Tooltip>
      <Tooltip title="Eliminar grupo">
        <Button
          className="my-3"
          onClick={() => onDelete()}
          size="small"
          variant="contained"
          color="error"
          sx={{ minWidth: '0.8rem', padding: '0.36rem' }}
        >
          <DeleteForeverIcon />
        </Button>
      </Tooltip>
    </div>
  );
});

export const Groups = () => {
  const Dispatch = useDispatch();
  const openAlert = useSimpleAlert();
  // ------ States correspondiente a componentes de filtrado -------
  const [dataList, setDataList] = useState([]);
  const [querySearch, setQuerySearch] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentItemsPerPage, setCurrentItemsPerPage] = useState(25);
  const [datePickerValue, setDatePickerValue] = useState([null, null]);
  const [form, setForm] = useState({ query: '', initDate: '', endDate: '', paginate: undefined });
  // ----- Instancia de request que se usaran en la vista junto a sus valores de estado ---------
  // ------- Selects --------------
  const [statusSelect, setStatusSelect] = useState('');
  const [locationSelect, setLocationSelect] = useState('');
  // ------ State general de formulario de buqueda ------------
  const { makeRequest: tryGetData, isLoading, response } = useRequest(searchPage);
  const { makeRequest: tryDelete } = useRequest(deleteGroup);

  // ----- File List exports -----
  const { catalogFormats, downloadBlobFile } = useDownloadBlob(typeDownloadEnum, exportFile);

  const handleExportFile = async (format = 'txt') => {
    await downloadBlobFile(format, 'contactos');
  };

  // ---- Borra todos los filtros ------
  const handleClearAllFilters = () => {
    setQuerySearch('');
    setStatusSelect('');
    setCurrentPage(undefined);
    setCurrentItemsPerPage(25);
    setDatePickerValue([null, null]);
    setLocationSelect('');
  };

  // base URl
  useEffect(() => {
    console.log('base Url: ', window.envpaths.prod.apiHost);
  }, []);

  // ----- Actualiza el formulario acorde cambio de filtros
  useEffect(() => {
    setForm((current) => {
      return {
        ...current,
        query: querySearch,
        paginate: currentItemsPerPage,
        initDate: datePickerValue[0] === null ? '' : datePickerValue[0],
        endDate: datePickerValue[1] === null ? '' : datePickerValue[1],
        status: statusSelect === '' ? null : statusSelect.toString(),
        locations: locationSelect === '' ? null : locationSelect.toString(),
      };
    });
  }, [querySearch, currentItemsPerPage, datePickerValue, statusSelect, locationSelect]);

  // ------ Actualiza la informacion de la tabla despues de la peticion -------
  useEffect(() => {
    console.log(response);
    if (!response) {
      return;
    }
    setDataList(response.data);
    setTotalPages(response.last_page);
    setTotalItems(response.total);
    setCurrentPage(response.current_page);
  }, [response]);

  // -------- Realiza la peticion de datos al haber un cambio en el formulario -----
  useEffect(() => {
    if (form === undefined) {
      return;
    }
    console.log('FROM | QUERY => ', form);
    tryGetData(form, currentPage);
  }, [currentPage, form]);

  // ------ Inicializacion de la vista con toda la informacion disponible -------
  useEffect(() => {
    tryGetData(form);
    return () => {
      setDataList([]);
    };
  }, []);

  // ------ Funciones para actualizar los filtros ------
  const handleSearchQuery = (query = '') => {
    if (!query.length > 3) {
      return;
    }
    setQuerySearch(query);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleChangeItems = (items) => {
    setCurrentItemsPerPage(items);
  };

  // ----- Acciones de botones en la tabla ----------
  const handleCreate = () => {
    history.push('/groups/create');
  };

  const handleDelete = async (id, name) => {
    openAlert({
      title: 'Atención',
      description: `Está seguro que desea eliminar el grupo ${name}?`,
    })
      .then((userResponse) => {
        if (!userResponse) {
          return;
        }
        tryDelete(id).then(({ isOk }) => {
          if (isOk) {
            Dispatch(
              showMessage({
                message: 'Grupo eliminado correctamente',
                autoHideDuration: 3000,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
                variant: 'success',
              })
            );
            tryGetData();
          }
        });
      })
      .catch((error) => console.log(error));
  };

  const handleUpdate = (id) => {
    history.push(`/groups/edit/${id}`);
  };

  const handleShow = () => {
    history.push('/users');
  };

  return (
    <Root
      header={
        <ComposedHeader
          breadcrumb
          title="Grupos"
          description="Información general de grupos registrados"
        >
          <div className="px-4 py-4">
            <ButtonWithExpand
              title="Descargar"
              className="whitespace-nowrap mx-4"
              variant="contained"
              color="success"
              startIcon={<SimCardDownloadOutlinedIcon />}
            >
              {catalogFormats.map((option, index) => (
                <ButtonWithExpand.Item
                  key={`${index}_${option.value}_doc`}
                  text={`Documento ${option.label}`}
                  onClick={() => handleExportFile(option.value)}
                />
              ))}
            </ButtonWithExpand>
          </div>
        </ComposedHeader>
      }
      content={
        <Grid container className="px-32 pb-32 pt-0 flex flex-row w-full justify-center">
          <Grid item xs={12} md={12} className="flex w-full">
            <Card className="pb-24 px-24 mt-16 w-full">
              <CardContent className="flex w-full h-full flex-col justify-between">
                <div className="flex flex-col mt-24">
                  <Grid container className="w-full flex justify-center items-center py-8 mb-28">
                    <Grid item xs={12} md={7}>
                      <div className="flex flex-row flex-wrap justify-start items-center">
                        <div className="ml-4 mr-8 my-2 w-8/12 md:my-0">
                          <InputSearch
                            value={querySearch}
                            onChange={(query) => handleSearchQuery(query)}
                          />
                        </div>
                        <div className="ml-4 my-2 md:my-0">
                          <Button
                            fullWidth
                            size="medium"
                            variant="contained"
                            color="primary"
                            onClick={() => handleClearAllFilters()}
                          >
                            Limpiar filtros
                          </Button>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <div className="flex flex-row justify-center md:justify-end items-center md:mt-0 mt-8">
                        <LocalizationProvider>
                          <DateRangePicker
                            slotProps={{ textField: { size: 'small' } }}
                            localeText={{ start: 'Fecha inicial', end: 'Fecha final' }}
                            value={datePickerValue}
                            onChange={(current) => setDatePickerValue(current)}
                            disableFuture
                          />
                        </LocalizationProvider>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="p-2">
                    <Grid item xs={12} md={6} className="p-8 mb-24">
                      <div className="flex flex-row flex-wrap justify-start items-center">
                        <div className="px-4">
                          <BasicSelect
                            data={statusGroupsData}
                            onChange={(data) => setStatusSelect(data)}
                            title="Estatus"
                            value={statusSelect}
                            size="small"
                          />
                        </div>
                        <div className="px-4">
                          <BasicSelect
                            data={locationsData}
                            onChange={(data) => setLocationSelect(data)}
                            title="Sucursal"
                            value={locationSelect}
                            size="small"
                          />
                        </div>
                        {/*
                          Agrega mas selects
                        */}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} className="p-8 mb-24">
                      <div className="flex flex-row justify-end">
                        <Paginator
                          totalPages={totalPages}
                          totalItems={totalItems}
                          onChangeCurrentPage={(page) => handleChangePage(page)}
                          onChangeItemsPerPage={(items) => handleChangeItems(items)}
                          currentPage={currentPage}
                          currentItemsPerPage={currentItemsPerPage}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <div className="flex flex-col justify-center">
                    <ComposedTable
                      isLoading={isLoading}
                      className="w-full"
                      headers={tableRowScheme}
                      data={dataList}
                    >
                      <ComposedTable.Column
                        content={({ _id }) => (
                          <Tooltip title={_id}>
                            <p>{_id.toString().substr(-8)}</p>
                          </Tooltip>
                        )}
                      />
                      <ComposedTable.Column
                        content={(item) => (
                          <ComposedTable.Thumbail
                            name={item.title}
                            baseUri={window.envpaths.prod.apiHost}
                            path={_.has(item, 'image') ? item.image.fullPathThumb : undefined}
                            alt={`perfil de ${item.title}`}
                          />
                        )}
                      />
                      <ComposedTable.Column content={({ title }) => title} />
                      <ComposedTable.Column content={({ description }) => description} />
                      <ComposedTable.Column content={({ address }) => address} />
                      <ComposedTable.Column content={({ phone }) => phone} />
                      <ComposedTable.Column
                        content={(group) => (
                          <Button
                            size="small"
                            className="font-normal transition-colors duration-100 ease-in-out hover:text-blue-500"
                            onClick={() => console.log(group._id)}
                          >
                            {_.has(group, 'users') ? group.users : 0}
                          </Button>
                        )}
                      />
                      <ComposedTable.Column content={({ locations }) => locations} />
                      <ComposedTable.Column
                        content={({ status }) => (status ? 'Activo' : 'Inactivo')}
                      />
                      <ComposedTable.Column content={() => <ComposedTable.NestedButton />} />
                      <ComposedTable.Column content={(item) => formatDate(item.created_at)} />
                      <ComposedTable.Column
                        fixedRight
                        content={({ _id, title }) => (
                          <OptionButtons
                            onDelete={() => handleDelete(_id, title)}
                            onUpdate={() => handleUpdate(_id)}
                            onShow={() => handleShow()}
                          />
                        )}
                      />
                    </ComposedTable>
                  </div>
                </div>
                <Grid container spacing={1} className="p-2 mt-24 flex">
                  <Grid item xs={12} md={12} className="py-8">
                    <div className="flex flex-row justify-end">
                      <Paginator
                        totalPages={totalPages}
                        totalItems={totalItems}
                        onChangeCurrentPage={(page) => handleChangePage(page)}
                        onChangeItemsPerPage={(items) => handleChangeItems(items)}
                        currentPage={currentPage}
                        currentItemsPerPage={currentItemsPerPage}
                      />
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      }
    />
  );
};
