import _ from 'lodash';
import { resetRoutes, setRoutes } from './routesSlice';

export const storeRoutes = (asyncCurrentRoutes = []) => {
  return async (dispatch, getState) => {
    const currentRoutes = getState().routes.routes;
    const storedRoutes = _.unionBy([...currentRoutes, ...asyncCurrentRoutes], 'path');
    dispatch(setRoutes({ asyncNewRoutes: storedRoutes }));
  };
};

export const clearRoutes = () => {
  return async (dispatch) => {
    dispatch(resetRoutes());
  };
};
