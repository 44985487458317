import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useRequest } from './useRequest';

export const useDownloadBlob = (catalogTypes = {}, catalogFunction = () => { }) => {
  const Dispatch = useDispatch();
  const { makeRequest: tryExportFile } = useRequest(catalogFunction);

  const catalogFormats = [
    {
      label: 'PDF',
      value: 'pdf',
    },
    {
      label: 'Excel',
      value: 'xlsx',
    },
    {
      label: 'HTML',
      value: 'html',
    },
    {
      label: 'CSV',
      value: 'csv',
    },
  ];

  async function downloadBlobFile(type, name = 'document') {
    try {
      const { isOk, successContent } = await tryExportFile({ format: catalogTypes[type]?.symbol });
      console.log(isOk, successContent);
      if (!isOk) {
        Dispatch(
          showMessage({
            message: 'Ocurrió un error al intentar descargar el archivo',
            variant: 'primary',
          })
        );
        throw new Error('Failed to obtain file from server');
      } else {
        const url = window.URL.createObjectURL(
          new Blob([successContent], { type: catalogTypes[type].format })
        );
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        const fileName = `${name}.${catalogTypes[type].extension}`;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return {
    catalogFormats,
    downloadBlobFile,
  };
};
