import {
  Configuration,
  Dashboard,
  ProductQuotations,
  Products,
  Purchases,
  Warehouse,
} from './Children';

export const ShopConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      id: 'shopGroup',
      path: 'shop',
      children: [
        {
          id: 'shopGroup.dashboard',
          path: 'dashboard',
          element: <Dashboard />,
        },
        {
          id: 'shopGroup.products',
          path: 'products',
          element: <Products />,
        },
        {
          id: 'shopGroup.purchases',
          path: 'purchases',
          element: <Purchases />,
        },
        {
          id: 'shopGroup.quotations',
          path: 'quotations',
          element: <ProductQuotations />,
        },
        {
          id: 'shopGroup.warehouse',
          path: 'warehouse',
          element: <Warehouse />,
        },
        {
          id: 'shopGroup.configuration',
          path: 'configuration',
          element: <Configuration />,
        },
      ],
    },
  ],
};
