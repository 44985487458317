import { Configuration, Dashboard, Events, Payments, Plans, Suscriptions } from './Children';

export const NetPayConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      id: 'netpayGroup',
      path: 'netpay',
      children: [
        {
          id: 'netpayGroup.dashboard',
          path: 'dashboard',
          element: <Dashboard />,
        },
        {
          id: 'netpayGroup.payments',
          path: 'payments',
          element: <Payments />,
        },
        {
          id: 'netpayGroup.suscriptions',
          path: 'suscriptions',
          element: <Suscriptions />,
        },
        {
          id: 'netpayGroup.plans',
          path: 'plans',
          element: <Plans />,
        },
        {
          id: 'netpayGroup.events',
          path: 'events',
          element: <Events />,
        },
        {
          id: 'netpayGroup.configuration',
          path: 'configuration',
          element: <Configuration />,
        },
      ],
    },
  ],
};
