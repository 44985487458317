import _ from 'lodash';
import { removeUserInfo, setUserInfo } from './userSlice';

export const removeAdminInfo = () => {
  return async (dispatch, getState) => {
    const { user } = getState();
    dispatch(removeUserInfo());
  };
};

export const setAdminInfo = (adminInfo) => {
  return async (dispatch) => {
    if (typeof adminInfo === 'object' && adminInfo !== undefined) {
      const adminInformation = _.omit(adminInfo, ['updated_at', 'created_at']);
      dispatch(
        setUserInfo({
          ...adminInformation,
          role: ['Administrator'],
        })
      );
    }
  };
};
