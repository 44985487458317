import { Locations } from './Children';


export const LocationsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      id: 'locationsGroup',
      path: 'locations',
      children: [
        {
          id: 'locationsGroup.locations',
          path: '',
          element: <Locations />,
        },
      ],
    },
  ],
};
