import history from '@history';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { ComposedHeader, FormCardLayout, LargeAvatar, TextWithLabel } from 'src/app/components';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {},
  '& .FusePageSimple-content': {},
}));
// export const Profile: React.FC<ProfileProps> = () => {
export const Profile = () => {
  const { _id, name, email, phone, company, avatar, role } = useSelector((state) => state.user);

  return (
    <Root
      header={
        <ComposedHeader
          breadcrumb
          // title="Administradores"
          // description="Información general de accesos autorizados"
        />
      }
      content={
        <Box
          className="flex justify-center items-start"
          sx={{
            width: '100%',
            height: '100%',
          }}
        >
          <FormCardLayout title="Perfil de Adminstrador">
            <FormCardLayout.HalfColum>
              <div className="flex flex-auto flex-col justify-center items-center">
                <LargeAvatar path={avatar ? avatar.path : undefined} name={name} />
              </div>
            </FormCardLayout.HalfColum>
            <FormCardLayout.HalfColum>
              <TextWithLabel title="Nombre">{name}</TextWithLabel>
              <TextWithLabel title="Correo">{email}</TextWithLabel>
              <TextWithLabel title="Número telefónico">{phone}</TextWithLabel>
              <TextWithLabel title="Empresa">{company}</TextWithLabel>
              <TextWithLabel title="Permisos">{role[0]}</TextWithLabel>
            </FormCardLayout.HalfColum>
            <FormCardLayout.RowButton>
              <Button
                variant="contained"
                color="info"
                onClick={() => history.push(`/admin/edit/${_id}`)}
              >
                Editar información
              </Button>
              <Button
                variant="contained"
                color="info"
                onClick={() => history.push(`/admin/recovery/${_id}`)}
              >
                Cambiar contraseña
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push('/admin/access')}
              >
                Ver más administradores
              </Button>
            </FormCardLayout.RowButton>
          </FormCardLayout>
        </Box>
      }
    />
  );
};
