import { lighten, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

/**
 * Card without colors to represent state value on dashboards.
 *
 * @component
 * @param {Object} props - Generic object of functional component
 * @param {string} props.title - Descriptive text of Card
 * @param {string} props.value - Value to represent current state
 * @returns {JSX.Element} Returns JSX Elements render of MUI.
 *
 */
export const CardNumberSolid = ({ title, value }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: (_theme) =>
          _theme.palette.mode === 'light'
            ? lighten(theme.palette.background.default, 0.4)
            : lighten(theme.palette.background.default, 0.02),
      }}
      className="flex flex-col items-center justify-center py-32 px-4 rounded-2xl"
    >
      <Typography className="text-5xl font-semibold leading-none tracking-tight">
        {value}
      </Typography>
      <Typography className="mt-4 text-sm font-medium text-center">{title}</Typography>
    </Box>
  );
};
