import { Children, cloneElement } from 'react';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const stickyRightStyled = 'md:sticky md:right-0 md:border-l-1';
const stickyLeftStyled = 'md:sticky md:left-0 md:border-r-1';

export const DashboardTable = ({ style, children, headers = [], data = [], isLoading, headerTitle = 'Tabla dashboard', small = false }) => {
  const theme = useTheme();
  return (
    <Paper
      style={style}
      className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden align-center justify-start px-14 py-14"
    >
      <div className="flex flex-col md:flex-col sm:flex-col items-center justify-between pb-14">
        <Typography className="text-lg font-medium tracking-tight leading-6 truncate">
          {headerTitle}
        </Typography>
      </div>
      {data.length > 0 && headers.length > 0 ? (
        <TableContainer>
          <Table aria-label="simple table" stickyHeader size={small ? 'small' : 'medium'}>
            <TableHead>
              <TableRow>
                {headers.map(
                  (
                    { title, width, minWidth, maxWidth, fixedRight, fixedLeft, align = 'center' },
                    index
                  ) => (
                    <TableCell
                      key={`${title}_${index}`}
                      style={{ width, minWidth, maxWidth, backgroundColor: theme.palette.background.paper , borderBottomWidth: '1px', borderBottomColor: '#DDD' }}
                      align={align}
                      className={`${fixedRight && !fixedLeft ? `${stickyRightStyled} md:border-l-neutral-700 md:shadow-md` : ''} ${!fixedLeft && fixedLeft ? `${stickyLeftStyled} md:border-r-neutral-700 md:shadow-md` : ''
                      }`}
                    >
                      {title}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, indexRow) => (
                <TableRow key={`indx_tbl_${indexRow}`}>
                  {Children.map(children, (child) => {
                    return cloneElement(child, { item });
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {!data.length > 0 && !isLoading && (
            <div
              className="bg-slate-950 h-full w-full left-0 top-0 bg-opacity-50 flex flex-auto justify-center items-end"
              style={{ position: 'absolute' }}
            >
              <div className="mt-16 mb-24">
                <Typography variant="body2">No hay datos que mostrar</Typography>
              </div>
            </div>
          )}
          {isLoading && (
            <div
              className="h-full w-full left-0 top-0 flex flex-auto justify-center items-center"
              style={{
                position: 'absolute',
                backgroundColor: 'rgba(56, 56, 56, 0.22)',
                backdropFilter: 'blur(1px)',
                zIndex: 100,
              }}
            >
              <div className="mt-16">
                <CircularProgress sx={{ color: '#FEFEFE' }} size={60} />
              </div>
            </div>
          )}
        </TableContainer>
      ) : (
        <div className="flex flex-col justify-center items-center h-full w-full">
          <Typography variant="body2">Tabla sin esquema configurado</Typography>
        </div>
      )}
    </Paper>
  );
};

const Column = ({ fixedRight, fixedLeft, content = () => { }, item }) => {
  const handleRenderCol = () => {
    const rendered = content(item);
    return rendered;
  };

  return (
    <TableCell
      align="center"
      className={`${fixedRight && !fixedLeft ? `${stickyRightStyled} md:border-l-neutral-400 md:shadow-md` : ''} ${!fixedLeft && fixedLeft ? `${stickyLeftStyled} md:border-r-neutral-400 md:shadow-md` : ''
      } `}
    >
      {handleRenderCol()}
    </TableCell>
  );
};

DashboardTable.Column = Column;
