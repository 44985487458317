import { useEffect, useState } from 'react';
import history from '@history';
import { useLocation } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { validateNumberInPath } from 'src/app/utils/validations';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export const ComposedBreadcrumb = ({ disableRoute = false }) => {
  const [locations, setLocations] = useState([]);
  const { pathname } = useLocation();

  const handleGetPaths = (currentPath) => {
    const parts = currentPath.split('/').filter((part) => part !== '');
    const result = parts.reduce((acumulator, part, index) => {
      const hasNumbers = validateNumberInPath(part);
      if (!hasNumbers) {
        const name = part;
        const path = `/${parts.slice(0, index + 1).join('/')}`;
        acumulator.push({ name, path });
      }
      return acumulator;
    }, []);
    return result;
  };

  const handleLink = (path = '') => {
    history.push(path);
  };

  useEffect(() => {
    if (!pathname) {
      return;
    }
    const objectPath = handleGetPaths(pathname);
    setLocations(objectPath);
  }, [pathname]);

  const handleBack = () => {
    history.back();
  };

  return (
    <div role="presentation" className="flex flex-row flexwrap items-center">
      <Tooltip title="Regresar a página anterior">
        <IconButton aria-label="delete" size="small" onClick={() => handleBack()}>
          <FuseSvgIcon size={26}>heroicons-outline:arrow-sm-left</FuseSvgIcon>
        </IconButton>
      </Tooltip>
      {!disableRoute && (
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
          sx={{
            li: {
              marginLeft: '0px',
              marginRight: '0px',
            },
          }}
        >
          {locations.map((location, index) => (
            <Button
              className="p-0"
              key={`location_${index}`}
              size="small"
              onClick={() => handleLink(location.path)}
              variant="text"
              disabled={index === locations.length - 1}
              sx={{ fontSize: '1.6rem' }}
            >
              {location.name}
            </Button>
          ))}
        </Breadcrumbs>
      )}
    </div>
  );
};
