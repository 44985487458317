import request, { prefix } from '../services/request';

export function search(params) {
  return request({
    url: `/${prefix}/group/search`,
    method: 'POST',
    params,
  });
}

export function searchPage(params, page) {
  return request({
    url: `/${prefix}/group/search?page=${page}`,
    method: 'POST',
    params,
  });
}

export function storeGroup(data) {
  return request({
    url: `/${prefix}/group`,
    method: 'POST',
    data,
  });
}

export function deleteGroup(id) {
  return request({
    url: `/${prefix}/group/${id}`,
    method: 'DELETE',
  });
}

export function getGroupById(id) {
  return request({
    url: `/${prefix}/group/${id}`,
    method: 'GET',
  });
}

export function updateGroup(data, id) {
  return request({
    url: `/${prefix}/group/${id}`,
    method: 'PUT',
    data,
  });
}

export function exportFile(params) {
  return request({
    url: `/${prefix}/group/export`,
    method: 'POST',
    responseType: 'blob',
    params,
  });
}

export const cataloglocation = () => {
  return request({
    url: `/api-registry/group/catalog/locations`,
    method: 'GET',
  });
};
