import {
  Access,
  AccessMonitoring,
  Configuration,
  Dashboard,
  Events,
  Licenses,
  Terminals,
} from './Children';

export const TurnstilesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      id: 'turnstilesGroup',
      path: 'turnstiles',
      scopes: ['admin-torniquet-show', 'admin-torniquet-config'],
      children: [
        {
          id: 'turnstilesGroup.dashboard',
          path: 'dashboard',
          element: <Dashboard />,
        },
        {
          id: 'turnstilesGroup.access',
          path: 'access',
          element: <Access />,
        },
        {
          id: 'turnstilesGroup.accessMonitoring',
          path: 'access-monitoring',
          element: <AccessMonitoring />,
        },
        {
          id: 'turnstilesGroup.events',
          path: 'events',
          element: <Events />,
        },
        {
          scopes: ['admin-torniquet-show'],
          id: 'turnstilesGroup.terminals',
          path: 'terminals',
          element: <Terminals />,
        },
        {
          id: 'turnstilesGroup.licenses',
          path: 'licenses',
          element: <Licenses />,
        },
        {
          scopes: ['admin-torniquet-config'],
          id: 'turnstilesGroup.configuration',
          path: 'configuration',
          element: <Configuration />,
        },
      ],
    },
  ],
};
