import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

export const ButtonWithLoader = ({
  type,
  disabled,
  color,
  children,
  onClick = () => {},
  isLoading = false,
  ...props
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isLoading === undefined) return;
    setLoading(isLoading);
  }, [isLoading]);

  const handleClick = (event) => {
    onClick(event);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Button
        {...props}
        variant="contained"
        className={`text-white ${loading && 'grayscale'}`}
        color={color}
        disabled={loading || disabled}
        type={type}
        onClick={handleClick}
      >
        {children}
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          color={color}
          sx={{
            // color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
    </Box>
  );
};
