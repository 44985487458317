import { memo, useEffect, useLayoutEffect, useState } from 'react';
import history from '@history';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { ButtonWithLoader, ComposedHeader, FormCardLayout, ImagePicker, ListCheckers, MetadataExpandForm } from 'src/app/components';
import { showMessage } from 'app/store/fuse/messageSlice';
import { getScopes, storeAdmin } from 'src/app/api/admin';
import _ from 'src/@lodash/@lodash';
import { regexName } from 'src/app/utils/validations';
import { useWithApiValidation } from 'src/app/hooks/useWithApiValidation';
import { validateEmail, validatePhone } from 'src/app/api/validations';
import { useRequest } from 'src/app/hooks/useRequest';
import { generateHexColor } from 'src/app/utils/generators';
import { Divider, Grid } from '@mui/material';

const Root = styled(FusePageSimple)(({ theme }) => ({
  backgroundColor: '#FFF',
  '& .FusePageSimple-header': {},
  '& .FusePageSimple-content': {},
}));

const permissionsData = [
  {
    label: 'Crear/Editar Licencias',
    value: 'licenses_x',
  },
  {
    label: 'Editar Interfaces',
    value: 'customs_x',
  },
];

const schema = yup.object().shape({
  name: yup
    .string()
    .required('El nombre no puede quedar vacío')
    .min(3, 'El nombre debe contener al menos 3 letras')
    .matches(regexName, 'Solo letras y espacios entre nombres'),
  email: yup
    .string()
    .email('Debes ingresar un correo válido')
    .required('El correo no puede quedar vacío'),
  phone: yup
    .string()
    .min(10, 'Verifique que el número tenga 10 cifras al menos')
    .required('El número telefónico no puede quedar vacío'),
  password: yup
    .string()
    .required('Debes incluir una contraseña')
    .min(4, 'Contraseña muy corta - debe contener al menos 4 caracteres.'),
  password_confirmation: yup
    .string()
    .required('Debes confirmar la contraseña')
    .oneOf([yup.ref('password'), null], 'Las contraseñas no coinciden'),
  scopes: yup.array().notRequired(),
  role: yup.array(),
  status: yup.boolean(),
});

const defaultValues = {
  name: '',
  email: '',
  phone: '',
  status: true,
  password: '',
  password_confirmation: '',
  scopes: [],
  role: ['admin'],
};

const PermissionsCollection = memo(({ field, errors, catalogScopes, ...props }) => {
  return (
    <Grid {...props} container spacing={1} className="mb-8 flex justify-center items-start">
      <Grid item xs={12}>
        <ListCheckers
          {...field}
          horizontal
          selectAllChecker
          selectAllLabel="Agregar todos los permisos"
          labelPlacement="end"
          fieldset
          onChange={field.onChange}
          values={field.value}
          className="pt-0 w-full flex"
          innerClassName="flex justify-center flex-row"
          data={[]}
        />
      </Grid>
      {catalogScopes.map((catalog, index) => {
        const arrCatalog = Object.keys(catalog.scopes).map((key) => ({
          value: key,
          label: catalog.scopes[key],
        }));
        return (
          <Grid
            item
            key={`label_${catalog?.label}_${index}`}
            xs={12}
            md={6}
            className="flex justify-center items-center flex-col"
          >
            <ListCheckers
              {...field}
              fieldset
              onChange={field.onChange}
              values={field.value}
              className="w-full flex"
              title={catalog?.label}
              description={catalog?.description}
              error={errors}
              data={arrCatalog}
            />
          </Grid>
        );
      })}
    </Grid>
  )
});

export const Create = () => {
  const Dispatch = useDispatch();
  const { config } = useSelector((state) => state.config);
  const { api } = config;
  const [metadata, setMetadata] = useState({});
  const [metadataError, setMetadataError] = useState(false);
  const [catalogScopes, setCatalogScopes] = useState([]);
  const [avatar, setAvatar] = useState(undefined);
  // ----- Inicializacion de validadores de formulario
  const { control, formState, handleSubmit, watch } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const watchScopes = watch('scopes');
  // ------ Request Store Admin ------
  const { makeRequest: tryStore, isLoading } = useRequest(storeAdmin);

  // ------- Request Get Scopes Admin -----------
  const { makeRequest: tryGetScopes, response: responseGetScopes } = useRequest(getScopes);

  // ------- Watchers scopes ----------
  useLayoutEffect(() => {
    tryGetScopes();
  }, []);

  useLayoutEffect(() => {
    if (responseGetScopes === undefined) return;
    console.log(responseGetScopes);
    setCatalogScopes(responseGetScopes);
  }, [responseGetScopes]);

  // ----- Handler get image -------
  const handleLoadImage = (image) => {
    setAvatar(image);
  };

  const handleUpdate = (form) => {
    const background = generateHexColor();
    const builtForm = {
      ...form,
      avatar,
      metadata,
      background: `#${background}`,
    };
    console.log(JSON.stringify(builtForm, null, 3));
    tryStore(builtForm).then(({ isOk }) => {
      if (isOk) {
        Dispatch(
          showMessage({
            message: 'Administrador agregado correctamente',
            autoHideDuration: 4000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            variant: 'success',
          })
        );
        history.replace('/admin/access');
      }
    });
  };

  const {
    isUnique: uniqueEmail,
    errorMessage: errorMessageEmail,
    validateField: validateFieldEmail,
  } = useWithApiValidation('email', validateEmail);
  const {
    isUnique: uniquePhone,
    errorMessage: errorMessagePhone,
    validateField: validateFieldPhone,
  } = useWithApiValidation('phone', validatePhone);

  return (
    <Root
      header={<ComposedHeader breadcrumb />}
      content={
        <Box
          className="flex justify-center items-start"
          sx={{
            width: '100%',
            height: '100%',
          }}
        >
          <FormCardLayout title="Agregar adminstrador">
            <FormCardLayout.FullColum>
              <ImagePicker
                onAfterUpload={(image) => handleLoadImage(image)}
                baseUri={api.host}
                adminMode
              />
            </FormCardLayout.FullColum>
            <FormCardLayout.FullColum>
              <form
                name="adminCreateForm"
                noValidate
                className="flex flex-col justify-center w-full"
                autoComplete="off"
              >
                <div className="flex flex-col">
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-32"
                        label="Nombre"
                        autoFocus
                        type="text"
                        error={!!errors.name}
                        helperText={errors?.name?.message}
                        variant="outlined"
                        required
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-32"
                        label="Correo electrónico"
                        autoFocus
                        type="email"
                        error={!!errors.email || !uniqueEmail}
                        helperText={errors?.email?.message || errorMessageEmail}
                        variant="outlined"
                        required
                        fullWidth
                        onChange={(current) => {
                          field.onChange(current);
                          validateFieldEmail(current);
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-32"
                        label="Número telefónico"
                        autoFocus
                        type="number"
                        error={!!errors.phone || !uniquePhone}
                        helperText={errors?.phone?.message || errorMessagePhone}
                        variant="outlined"
                        required
                        fullWidth
                        onChange={(current) => {
                          field.onChange(current);
                          validateFieldPhone(current);
                        }}
                      />
                    )}
                  />

                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-32"
                        label="Contraseña"
                        type="password"
                        error={!!errors.password}
                        helperText={errors?.password?.message}
                        variant="outlined"
                        required
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="password_confirmation"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-32"
                        label="Confirmar contraseña"
                        type="password"
                        error={!!errors.password_confirmation}
                        helperText={errors?.password_confirmation?.message}
                        variant="outlined"
                        required
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <>
                        <FormLabel component="legend">Estatus del administrador</FormLabel>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={field.value}
                              {...field}
                              inputProps={{ 'aria-label': 'Estatus' }}
                            />
                          }
                          label={field.value ? 'Habilitado' : 'Deshabilitado'}
                        />
                      </>
                    )}
                  />
                  <Divider className="mb-8 mt-12">Permisos para administrador</Divider>
                  <Controller
                    name="scopes"
                    control={control}
                    render={({ field }) => (
                      <PermissionsCollection
                        field={field}
                        errors={!!errors.scopes}
                        catalogScopes={catalogScopes}
                      />
                    )}
                  />
                </div>
              </form>
            </FormCardLayout.FullColum>
            <FormCardLayout.FullColum>
              <div>
                <MetadataExpandForm
                  onChange={(metafields) => setMetadata(metafields)}
                  onError={(metaError) => setMetadataError(metaError)}
                />
              </div>
            </FormCardLayout.FullColum>
            <FormCardLayout.RowButton>
              <Button
                onClick={() => history.push('/admin/access')}
                variant="contained"
                className="w-full md:w-auto"
                color="error"
              >
                Cancelar
              </Button>
              <ButtonWithLoader
                onClick={handleSubmit(handleUpdate)}
                color="success"
                aria-label="Agregar administrador"
                variant="contained"
                disabled={_.isEmpty(dirtyFields) || !isValid || metadataError}
                type="submit"
                isLoading={isLoading}
              >
                Agregar
              </ButtonWithLoader>
            </FormCardLayout.RowButton>
          </FormCardLayout>
        </Box>
      }
    />
  );
};
