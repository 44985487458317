import { styled } from '@mui/material/styles';
import history from '@history';
import { Button, Grid, Typography } from '@mui/material';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import FusePageSimple from '@fuse/core/FusePageSimple';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import { ButtonWithExpand, CardNumberColored, DashboardTable, DashboardWidgets, GaugeCart, ListCheckers, Report, SimpleHeader } from 'src/app/components';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { CardNumberSolid } from 'src/app/components/CardNumberSolid/CardNumberSolid';
import { useEffect, useState } from 'react';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {},
  '& .FusePageSimple-content': {},
}));

const dashboardResumeTableScheme = [
  {
    title: 'ID',
    width: 'auto',
  },
  {
    title: 'Hora de acceso',
    width: 'auto',
  },
  {
    title: 'Usuario',
    width: 'auto',
  },
  {
    title: 'Tipo de acceso',
    width: 'auto',
  },
];

const dashboardResumeTabledata = [
  { _id: '4892892', created_at: '10:58', name: 'persona 5', type: 'huella' },
  { _id: '3909032', created_at: '10:52', name: 'persona 4', type: 'qr' },
  { _id: '3903203', created_at: '10:47', name: 'persona 3', type: 'qr' },
  { _id: '9392023', created_at: '10:44', name: 'persona 2', type: 'huella' },
  { _id: '8823729', created_at: '10:40', name: 'persona 1', type: 'huella' },
  { _id: '8823729', created_at: '10:40', name: 'persona 1', type: 'huella' },
  { _id: '8823729', created_at: '10:40', name: 'persona 1', type: 'huella' },
  { _id: '8823729', created_at: '10:40', name: 'persona 1', type: 'huella' },
  { _id: '8823729', created_at: '10:40', name: 'persona 1', type: 'huella' },
];

export const Dashboard = () => {
  return (
    <Root
      header={
        <SimpleHeader
          title="Panel de control"
          description="Entradas, registros y control de usuarios"
        >
          <div className="my-32 md:my-0 justify-center" style={{ maxWidth: '40rem' }}>
            <Typography className="text-lg leading-8 text-center truncate">
              Selecciona un rango de fechas
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker localeText={{ start: 'Check-in', end: 'Check-out' }} />
            </LocalizationProvider>
          </div>
        </SimpleHeader>
      }
      content={
        <Grid container spacing={6} className="p-32">
          <Grid item xs={12} md={6}>
            <div>
              <DashboardTable
                headerTitle="Últimos accesos"
                headers={dashboardResumeTableScheme}
                data={dashboardResumeTabledata}
              >
                <DashboardTable.Column content={({ _id }) => _id} />
                <DashboardTable.Column content={(item) => item.created_at} />
                <DashboardTable.Column content={({ name }) => name} />
                <DashboardTable.Column content={({ type }) => type} />
              </DashboardTable>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <DashboardWidgets>
              <DashboardWidgets.Header title="Resumen">
                <ButtonWithExpand
                  title="Descargar"
                  className="whitespace-nowrap mx-4"
                  variant="contained"
                  color="success"
                  onClick={() => console.log('Export excel...')}
                  startIcon={<SimCardDownloadOutlinedIcon />}
                >
                  <ButtonWithExpand.Item text="Documento PDF" />
                  <ButtonWithExpand.Item text="Documento Excel" />
                </ButtonWithExpand>
              </DashboardWidgets.Header>
              <DashboardWidgets.CardsContainer>
                <DashboardWidgets.CardColumn className="col-span-4 sm:col-span-2">
                  <CardNumberColored title="Ingresos" value="84" color="secondary" />
                </DashboardWidgets.CardColumn>
                <DashboardWidgets.CardColumn className="col-span-4 sm:col-span-2">
                  <CardNumberColored title="Denegados" value="6" color="success" />
                </DashboardWidgets.CardColumn>
                <DashboardWidgets.CardColumn className="col-span-2 sm:col-span-1">
                  <CardNumberSolid value="18" title="Grupos" />
                </DashboardWidgets.CardColumn>
                <DashboardWidgets.CardColumn className="col-span-2 sm:col-span-1">
                  <CardNumberSolid value="26" title="Usuarios" />
                </DashboardWidgets.CardColumn>
                <DashboardWidgets.CardColumn className="col-span-2 sm:col-span-1">
                  <CardNumberSolid value="5" title="Sin registro" />
                </DashboardWidgets.CardColumn>
                <DashboardWidgets.CardColumn className="col-span-2 sm:col-span-1">
                  <CardNumberSolid value="10" title="Invitados" />
                </DashboardWidgets.CardColumn>
              </DashboardWidgets.CardsContainer>
              <DashboardWidgets.SingleTable
                headers={dashboardResumeTableScheme}
                data={dashboardResumeTabledata[0]}
              >
                <DashboardWidgets.Column content={({ _id }) => _id} />
                <DashboardWidgets.Column content={(item) => item.created_at} />
                <DashboardWidgets.Column content={({ name }) => name} />
                <DashboardWidgets.Column content={({ type }) => type} />
              </DashboardWidgets.SingleTable>
            </DashboardWidgets>
          </Grid>
          <Grid item xs={12} md={4}>
            <GaugeCart color="success" title="Verificados" />
          </Grid>
          <Grid item xs={12} md={4}>
            <GaugeCart color="error" title="No registrados" />
          </Grid>
          <Grid item xs={12} md={4}>
            <GaugeCart color="warning" title="Acceso denegado" />
          </Grid>
          <Grid item xs={6} md={12}>
            <Report />
          </Grid>
          <Grid item xs={6} md={12}>
            <Report />
          </Grid>
        </Grid>
      }
      scroll="page"
    />
  );
};
