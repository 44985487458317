import { Children, cloneElement } from 'react';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme, styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const stickyRightStyled = 'md:sticky md:right-0 md:border-l-1';
const stickyLeftStyled = 'md:sticky md:left-0 md:border-r-1';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey,
    color: theme.palette.text.primary,
    height: '2rem',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const SimpleTable = ({ children, headers = [], data = [], isLoading, small = false, ...props }) => {
  const theme = useTheme();
  return (
    <TableContainer {...props} style={{ position: 'relative' }}>
      <Table aria-label="simple table" stickyHeader size={small ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            {headers.map(({ title, width, minWidth, maxWidth, fixedRight, fixedLeft, align = 'center' }, index) => (
                <StyledTableCell
                  key={`${title}_${index}`}
                  align={align}
                  style={{ width, minWidth, maxWidth }}
                  className={`${fixedRight && !fixedLeft ? `${stickyRightStyled} md:border-l-neutral-700 md:shadow-md` : ''} ${!fixedLeft && fixedLeft ? `${stickyLeftStyled} md:border-r-neutral-700 md:shadow-md` : ''
                  }`}
                >
                  {title}
                </StyledTableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, indexRow) => (
            <TableRow key={`indx_tbl_${indexRow}`}>
              {Children.map(children, (child) => {
                return cloneElement(child, { item });
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {isLoading && (
        <div
          className="h-full w-full left-0 top-0 flex flex-auto justify-center items-center"
          style={{
            position: 'absolute',
            backgroundColor: 'rgba(56, 56, 56, 0.22)',
            backdropFilter: 'blur(1px)',
            zIndex: 100,
          }}
        >
          <div className="mt-16">
            <CircularProgress sx={{ color: '#FEFEFE' }} size={60} />
          </div>
        </div>
      )}
    </TableContainer>
  );
};

const Column = ({ fixedRight, fixedLeft, content = () => { }, item }) => {
  const handleRenderCol = () => {
    const rendered = content(item);
    return rendered;
  };

  return (
    <TableCell
      align="center"
      className={`${fixedRight && !fixedLeft ? `${stickyRightStyled} md:border-l-neutral-400 md:shadow-md` : ''} ${!fixedLeft && fixedLeft ? `${stickyLeftStyled} md:border-r-neutral-400 md:shadow-md` : ''
        } `}
    >
      {handleRenderCol()}
    </TableCell>
  );
};

SimpleTable.Column = Column;
