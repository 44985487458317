export const priorityEnum = {
  low: {
    symbol: 'low',
    color: 'primary',
    icon: 'material-outline:notifications_none',
    variant: {
      color: (_theme) => _theme.palette.background.paper,
      backgroundColor: (_theme) => _theme.palette.text.primary,
    },
  },
  normal: {
    symbol: 'normal',
    color: 'secondary',
    icon: 'material-outline:notification_important',
    variant: {
      color: 'rgb(255, 255, 255)',
      backgroundColor: (_theme) => _theme.palette.secondary.main,
    },
  },
  high: {
    symbol: 'high',
    color: 'error',
    icon: 'material-outline:notifications_active',
    variant: {
      color: 'rgb(255, 255, 255)',
      backgroundColor: (_theme) => _theme.palette.error.main,
    },
  },
};
