import { createContext, useContext, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

/**
 *  const tabs = [
 *  {
 *    label: 'tab 1',
 *    key: 'tab_1',
 * },
 * {
 *    label: 'tab 2',
 *    key: 'tab_2',
 * }
 * ]
 */

const TabsContext = createContext({});
const { Provider } = TabsContext;

const WithProvider = ({ children }) => {
  const [currentTab, setCurrentTab] = useState(0);
  return <Provider value={{ currentTab, setCurrentTab }}>{children}</Provider>;
};

const TabElement = ({ children, index, ...props }) => {
  const { currentTab } = useContext(TabsContext);

  return (
    <div
      role="tabpanel"
      hidden={index !== currentTab}
      id={`composed-tabpanel-${index}`}
      aria-labelledby={`composed-tab-${index}`}
      {...props}
    >
      {index === currentTab && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export const ComposedTags = ({ children, ...props }) => {
  return (
    <WithProvider>
      <ContainerTabs {...props}>{children}</ContainerTabs>
    </WithProvider>
  );
};

const ContainerTabs = ({ tabs = [], color, children, fontSize, ...props }) => {
  const { currentTab, setCurrentTab } = useContext(TabsContext);

  const handleChange = (event, newTab) => {
    setCurrentTab(newTab);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          aria-label="composed tabs panel"
          textColor={color || 'primary'}
          indicatorColor={color || 'primary'}
          {...props}
        >
          {tabs.map((tab, index) => {
            return (
              <Tab
                wrapped
                key={`composed-tab-${index}`}
                aria-controls={`composed-tabpanel-${tab.key}`}
                id={`composed-tab-${tab.key}`}
                label={tab.label}
                disabled={tab.disabled ? tab.disabled : undefined}
                icon={tab.icon ? tab.icon : undefined}
                iconPosition={tab.iconPosition ? tab.iconPosition : undefined}
                sx={{ fontSize: fontSize || '1rem' }}
              />
            );
          })}
        </Tabs>
      </Box>
      {children}
    </Box>
  );
};

ComposedTags.Element = TabElement;
