import { combineReducers } from '@reduxjs/toolkit';
import fuse from './fuse';
import i18n from './i18nSlice';
import user from './userInfo/userSlice';
import configSlice from './config/configSlice';
import authSlice from './auth/authSlice';
import routesSlice from './routes/routesSlice';
import modalsSlice from './modals/modalsSlice';
import socketsSlice from './sockets/socketsSlice';
import identitySlice from './Identity/identitySlice';
import notificationsSlice from './notifications/notificationsSlice';

const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    notifications: notificationsSlice,
    sockets: socketsSlice,
    routes: routesSlice,
    modals: modalsSlice,
    auth: authSlice,
    identity: identitySlice,
    config: configSlice,
    fuse,
    i18n,
    user,
    ...asyncReducers,
  });

  /*
	Reset the redux store when user logged out
	 */
  if (action.type === 'user/userLoggedOut') {
    // state = undefined;
  }

  return combinedReducer(state, action);
};

export default createReducer;
