import { Configuration, Dashboard, Events, Payments, Plans, Suscriptions } from './Children';

export const StripeConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      id: 'stripeGroup',
      path: 'stripe',
      children: [
        {
          id: 'stripeGroup.dashboard',
          path: 'dashboard',
          element: <Dashboard />,
        },
        {
          id: 'stripeGroup.payments',
          path: 'payments',
          element: <Payments />,
        },
        {
          id: 'stripeGroup.suscriptions',
          path: 'suscriptions',
          element: <Suscriptions />,
        },
        {
          id: 'stripeGroup.plans',
          path: 'plans',
          element: <Plans />,
        },
        {
          id: 'stripeGroup.events',
          path: 'events',
          element: <Events />,
        },
        {
          id: 'stripeGroup.configuration',
          path: 'configuration',
          element: <Configuration />,
        },
      ],
    },
  ],
};
