import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
  {
    id: 'dashboardsGroup',
    title: 'Dashboards',
    subtitle: 'Accesos directos a paneles',
    type: 'group',
    children: [
      {
        id: 'main.dashboard',
        title: 'Panel principal',
        type: 'item',
        icon: 'material-solid:widgets',
        // icon: 'material-outline:grid_view',
        url: 'dashboard',
      },
    ],
  },
  {
    id: 'generalInformation',
    title: 'Panel general',
    subtitle: 'Información de usuarios en el sistema',
    type: 'group',
    children: [
      {
        id: 'groupsGroup.groups',
        title: 'Grupos',
        type: 'item',
        icon: 'material-outline:groups',
        url: 'groups',
      },
      {
        id: 'usersGroup.users',
        title: 'Usuarios',
        type: 'item',
        icon: 'material-outline:people_outline',
        url: 'users',
      },
      {
        id: 'fingerprintsGroup.fingerprints',
        title: 'Huellas',
        type: 'item',
        icon: 'material-outline:fingerprint',
        url: 'fingerprints',
      },
    ],
  },
  {
    id: 'onlineShop',
    title: 'Tienda virtual',
    subtitle: 'Información de comercio',
    type: 'group',
    children: [
      {
        id: 'shopGroup',
        title: 'TPV',
        type: 'collapse',
        icon: 'material-outline:storefront',
        children: [
          {
            id: 'shopGroup.dashboard',
            title: 'Dashboard',
            type: 'item',
            icon: 'material-outline:grid_view',
            url: '/shop/dashboard',
          },
          {
            id: 'shopGroup.products',
            title: 'Productos',
            type: 'item',
            icon: 'material-outline:shop_2',
            url: '/shop/products',
          },
          {
            id: 'shopGroup.purchases',
            title: 'Compras',
            type: 'item',
            icon: 'material-outline:local_grocery_store',
            url: '/shop/purchases',
          },
          {
            id: 'shopGroup.quotations',
            title: 'Cotizaciones',
            type: 'item',
            icon: 'material-outline:fact_check',
            url: '/shop/quotations',
          },
          {
            id: 'shopGroup.warehouse',
            title: 'Almacén',
            type: 'item',
            icon: 'material-outline:precision_manufacturing',
            url: '/shop/warehouse',
          },
          {
            id: 'shopGroup.configuration',
            title: 'Configuration',
            type: 'item',
            icon: 'heroicons-outline:cog',
            url: '/shop/configuration',
          },
        ],
      },
      {
        id: 'billingGroup',
        title: 'Facturación',
        type: 'collapse',
        icon: 'material-outline:price_check',
        children: [
          {
            id: 'billingGroup.dashboard',
            title: 'Dashboard',
            type: 'item',
            icon: 'material-outline:grid_view',
            url: '/billing/dashboard',
          },
          {
            id: 'billingGroup.bills',
            title: 'Facturas',
            type: 'item',
            icon: 'material-outline:library_books',
            url: '/billing/bills',
          },
          {
            id: 'billingGroup.reports',
            title: 'Reportes',
            type: 'item',
            icon: 'material-outline:library_books',
            url: '/billing/reports',
          },
          {
            id: 'billingGroup.configuration',
            title: 'Configuración',
            type: 'item',
            icon: 'heroicons-outline:cog',
            url: '/billing/configuration',
          },
        ],
      },
      {
        id: 'stripeGroup',
        title: 'Stripe',
        type: 'collapse',
        icon: 'material-outline:payments',
        children: [
          {
            id: 'stripeGroup.dashboard',
            title: 'Panel',
            type: 'item',
            icon: 'material-outline:payments',
            url: 'stripe/dashboard',
          },
          {
            id: 'stripeGroup.payments',
            title: 'Pagos',
            type: 'item',
            icon: 'material-outline:payments',
            url: 'stripe/payments',
          },
          {
            id: 'stripeGroup.suscriptions',
            title: 'Suscripciones',
            type: 'item',
            icon: 'material-outline:payments',
            url: 'stripe/suscriptions',
          },
          {
            id: 'stripeGroup.plans',
            title: 'Planes / Precios',
            type: 'item',
            icon: 'material-outline:payments',
            url: 'stripe/plans',
          },
          {
            id: 'stripeGroup.events',
            title: 'Eventos',
            type: 'item',
            icon: 'material-outline:payments',
            url: 'stripe/events',
          },
          {
            id: 'stripeGroup.configuration',
            title: 'Configuración',
            type: 'item',
            icon: 'heroicons-outline:cog',
            url: 'stripe/configuration',
          },
        ],
      },
      {
        id: 'netpayGroup',
        title: 'NetPay',
        type: 'collapse',
        icon: 'material-outline:payments',
        children: [
          {
            id: 'netpayGroup.dashboard',
            title: 'Panel',
            type: 'item',
            icon: 'material-outline:payments',
            url: 'netpay/dashboard',
          },
          {
            id: 'netpayGroup.payments',
            title: 'Pagos',
            type: 'item',
            icon: 'material-outline:payments',
            url: 'netpay/payments',
          },
          {
            id: 'netpayGroup.suscriptions',
            title: 'Suscripciones',
            type: 'item',
            icon: 'material-outline:payments',
            url: 'netpay/suscriptions',
          },
          {
            id: 'netpayGroup.plans',
            title: 'Planes / Precios',
            type: 'item',
            icon: 'material-outline:payments',
            url: 'netpay/plans',
          },
          {
            id: 'netpayGroup.events',
            title: 'Eventos',
            type: 'item',
            icon: 'material-outline:payments',
            url: 'netpay/events',
          },
          {
            id: 'netpayGroup.configuration',
            title: 'Configuración',
            type: 'item',
            icon: 'heroicons-outline:cog',
            url: 'netpay/configuration',
          },
        ],
      },
    ],
  },
  {
    id: 'terminals',
    title: 'Terminales',
    subtitle: 'Información de terminales disponibles',
    type: 'group',
    children: [
      {
        id: 'kiosksGroup',
        title: 'Kioscos',
        type: 'collapse',
        icon: 'material-outline:system_security_update',
        children: [
          {
            id: 'kiosksGroup.dashboard',
            title: 'Dashboard',
            type: 'item',
            icon: 'material-outline:grid_view',
            url: 'kiosks/dashboard',
          },
          {
            id: 'kiosksGroup.access',
            title: 'Accesos',
            type: 'item',
            icon: 'material-outline:double_arrow',
            url: 'kiosks/access',
          },
          {
            id: 'kiosksGroup.events',
            title: 'Eventos',
            type: 'item',
            icon: 'material-outline:grid_view',
            url: 'kiosks/events',
          },
          {
            id: 'kiosksGroup.terminals',
            title: 'Terminales',
            type: 'item',
            icon: 'material-outline:perm_device_information',
            url: 'kiosks/terminals',
          },
          {
            id: 'kiosksGroup.licenses',
            title: 'Licencias',
            type: 'item',
            icon: 'material-outline:local_police',
            url: 'kiosks/licenses',
          },
          {
            id: 'kiosksGroup.configuration',
            title: 'Configuración',
            type: 'item',
            icon: 'heroicons-outline:cog',
            url: 'kiosks/configuration',
          },
        ],
      },
      {
        id: 'turnstilesGroup',
        title: 'Torniquetes',
        type: 'collapse',
        icon: 'material-outline:device_hub',
        children: [
          {
            id: 'turnstilesGroup.dashboard',
            title: 'Dashboard',
            type: 'item',
            icon: 'material-outline:grid_view',
            url: 'turnstiles/dashboard',
          },
          {
            id: 'turnstilesGroup.access',
            title: 'Accesos',
            type: 'item',
            icon: 'material-outline:double_arrow',
            url: 'turnstiles/access',
          },
          {
            id: 'turnstilesGroup.accessMonitoring',
            title: 'Monitoreo de Accesos',
            type: 'item',
            icon: 'material-outline:grid_view',
            url: 'turnstiles/access-monitoring',
          },
          {
            id: 'turnstilesGroup.events',
            title: 'Eventos',
            type: 'item',
            icon: 'material-outline:grid_view',
            url: 'turnstiles/events',
          },
          {
            id: 'turnstilesGroup.terminals',
            title: 'Terminales',
            type: 'item',
            icon: 'material-outline:perm_device_information',
            url: 'turnstiles/terminals',
          },
          {
            id: 'turnstilesGroup.licenses',
            title: 'Licencias',
            type: 'item',
            icon: 'material-outline:local_police',
            url: 'turnstiles/licenses',
          },
          {
            id: 'turnstilesGroup.configuration',
            title: 'Configuración',
            type: 'item',
            icon: 'heroicons-outline:cog',
            url: 'turnstiles/configuration',
          },
        ],
      },
      {
        id: 'userRegistersGroup',
        title: 'Puntos de registro',
        type: 'collapse',
        icon: 'material-outline:desktop_mac',
        children: [
          {
            id: 'userRegistersGroup.dashboard',
            title: 'Dashboard',
            type: 'item',
            icon: 'material-outline:grid_view',
            url: 'user-registers/dashboard',
          },
          {
            id: 'userRegistersGroup.events',
            title: 'Eventos',
            type: 'item',
            icon: 'material-outline:grid_view',
            url: 'user-registers/events',
          },
          {
            id: 'userRegistersGroup.terminals',
            title: 'Terminales',
            type: 'item',
            icon: 'material-outline:perm_device_information',
            url: 'user-registers/terminals',
          },
          {
            id: 'userRegistersGroup.licenses',
            title: 'Licencias',
            type: 'item',
            icon: 'material-outline:local_police',
            url: 'user-registers/licenses',
          },
          {
            id: 'userRegistersGroup.configuration',
            title: 'Configuración',
            type: 'item',
            icon: 'heroicons-outline:cog',
            url: 'user-registers/configuration',
          },
        ],
      },
    ],
  },
  {
    id: 'companyGroup',
    title: 'Configuración compañía',
    subtitle: 'Información general para administradores',
    type: 'group',
    children: [
      {
        id: 'adminGroup.access',
        title: 'Administradores',
        type: 'item',
        icon: 'material-outline:admin_panel_settings',
        url: '/admin/access',
      },
      {
        id: 'locationsGroup.locations',
        title: 'Sucursales',
        type: 'item',
        icon: 'material-outline:maps_home_work',
        url: '/locations',
      },
      {
        id: 'configuration',
        title: 'Configuración',
        type: 'item',
        icon: 'heroicons-outline:cog',
        url: '/configuration',
      },
    ],
  },
];

export default navigationConfig;
