import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const useSimpleAlert = () => {
  const dispatch = useDispatch();

  const openAlert = ({ title, description }) => {
    return new Promise((resolve) => {
      dispatch(
        openDialog({
          children: (
            <>
              <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    dispatch(closeDialog());
                    resolve(false);
                  }}
                  color="primary"
                >
                  Cancelar
                </Button>
                <Button
                  onClick={() => {
                    dispatch(closeDialog());
                    resolve(true);
                  }}
                  color="warning"
                  autoFocus
                >
                  Aceptar
                </Button>
              </DialogActions>
            </>
          ),
        })
      );
    });
  };

  return openAlert;
};
