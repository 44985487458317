import Avatar from '@mui/material/Avatar';

export const LargeAvatar = ({ baseUri, path, name = '' }) => {
  return (
    <div
      className="flex flex-auto flex-1 w-full h-full"
      style={{ width: '100%', height: undefined, aspectRatio: '1/1', maxWidth: '24rem' }}
    >
      {path !== undefined ? (
        <Avatar
          sx={{
            backgroundColor: 'background.paper',
            color: 'text.secondary',
            width: '100%',
            height: '100%',
          }}
          className="avatar text-32 font-bold"
          src={`${baseUri || ''}${baseUri ? '/' : ''}${path}`}
          alt="Imagen de perfil de administrador"
        />
      ) : (
        name && (
          <Avatar
            className="avatar text-32 font-bold"
            sx={{
              width: '100%',
              height: '100%',
            }}
          >
            <p style={{ fontSize: '3.2em', fontWeight: 'normal' }}>
              {name
                .split(' ')
                .map((word) => word[0])
                .join('')
                .substring(0, 2)
                .toUpperCase()}
            </p>
          </Avatar>
        )
      )}
    </div>
  );
};
