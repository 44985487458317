import moment from 'moment';

export const formatDate = (timestamp) => {
  if (!timestamp) {
    return '';
  }
  return moment(String(timestamp)).format('YYYY/MM/D');
};

export const formatDateHour = (timestamp) => {
  if (!timestamp) {
    return '';
  }
  return moment(String(timestamp)).format('MMMM Do YYYY, h:mm:ss');
};

export const timeStringToDate = (timeString) => {
  const time = moment(timeString, 'HH:mm:ss');
  const now = moment();
  now.hours(time.hours());
  now.minutes(time.minutes());
  now.seconds(time.seconds());
  now.milliseconds(0);
  return now.toDate();
};
