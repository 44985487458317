import { Configuration } from './Children';

export const ConfigurationConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      id: 'configuration',
      path: 'configuration',
      element: <Configuration />,
    },
  ],
};
