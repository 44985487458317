import request, { prefix } from '../services/request';

export function getLocations() {
  return request({
    url: `/${prefix}/location?projects[name]=1&projects[key]=1`,
    method: 'GET',
  });
}

export function searchPage(params, page) {
  return request({
    url: `/${prefix}/location/search?page=${page}`,
    method: 'POST',
    params,
  });
}

export function search(params) {
  return request({
    url: `/${prefix}/location/search`,
    method: 'POST',
    params,
  });
}

export function storeLocation(data) {
  return request({
    url: `/${prefix}/location`,
    method: 'POST',
    data,
  });
}

export function statusLocation(id, data) {
  return request({
    url: `/${prefix}/location/${id}/status`,
    method: 'PUT',
    data,
  });
}

export function updateLocation(id, data) {
  return request({
    url: `/${prefix}/location/${id}`,
    method: 'PUT',
    data,
  });
}

export function getLocationById(id) {
  return request({
    url: `/${prefix}/location/${id}`,
    method: 'GET',
  });
}

export function indexLocation(params = '') {
  return request({
    url: `/${prefix}/location?${params}`,
    method: 'GET',
  });
}

export function exportFile(params) {
  return request({
    url: `/${prefix}/location/export`,
    method: 'POST',
    responseType: 'blob',
    params,
  });
}
