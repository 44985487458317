import { createSlice } from '@reduxjs/toolkit';

const socketInitialState = {
  notificationClientConnection: false,
};
const socketsSlice = createSlice({
  name: 'sockets',
  initialState: socketInitialState,
  reducers: {
    setNotificationConnState: (state, { payload }) => {
      state.notificationClientConnection = payload.isConnected;
    },
  },
});

export default socketsSlice.reducer;
export const { setNotificationConnState } = socketsSlice.actions;
