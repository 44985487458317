import { Bills, Configuration, Dashboard, Reports } from './Children';

export const BillingConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      id: 'billingGroup',
      path: 'billing',
      children: [
        {
          id: 'billingGroup.dashboard',
          path: 'dashboard',
          element: <Dashboard />,
        },
        {
          id: 'billingGroup.bills',
          path: 'bills',
          element: <Bills />,
        },
        {
          id: 'billingGroup.reports',
          path: 'reports',
          element: <Reports />,
        },
        {
          id: 'billingGroup.configuration',
          path: 'configuration',
          element: <Configuration />,
        },
      ],
    },
  ],
};
