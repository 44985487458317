import _ from '@lodash';

export const hasPermission = (route, userScopes) => {
  // console.log('ROUTE: ', JSON.stringify(route, null, 3));
  if (_.has(route, 'scopes') && Array.isArray(route.scopes)) {
    if (userScopes.length > 0 && userScopes.some((scope) => route.scopes.includes(scope))) {
      return true;
    }

    if (userScopes.length === 0) {
      return false;
    }
  }
  return true;
};

export const filterAsyncRoutes = (nav, scopes) => {
  const includesAll = scopes.includes('*');
  if (includesAll) {
    return nav;
  }
  return nav
    .map((_item) => {
      if (_item.scopes && _item.scopes.length > 0) {
        if (!scopes.some((scope) => _item.scopes.includes(scope))) {
          return null;
        }
      }
      if (_item.children) {
        const filteredChildren = filterAsyncRoutes(_item.children, scopes);
        if (filteredChildren.every((child) => child === null)) {
          return null;
        }
        return { ..._item, children: filteredChildren };
      }
      return { ..._item };
    })
    .filter((s) => s !== null);
};

export const matchingRoutesToNav = (currentNavBarRoutes, currentRoutes) => {
  return currentNavBarRoutes.reduce((filteredRoutes, route) => {
    const navBarRouteRef = { ...route };

    if (navBarRouteRef.children) {
      navBarRouteRef.children = matchingRoutesToNav(navBarRouteRef.children, currentRoutes);
    }

    const isRouteMatched = currentRoutes.some((historyRouteItem) => {
      const doesRouteMatch = checkIfRouteMatches(historyRouteItem, navBarRouteRef);
      return doesRouteMatch;
    });

    if (isRouteMatched) {
      filteredRoutes.push(navBarRouteRef); // Agregar la ruta si coincide con alguna en rutasHistory
    }
    return filteredRoutes;
  }, []);
}

const checkIfRouteMatches = (historyRouteItem, navBarRouteRef) => {
  if (historyRouteItem.id === navBarRouteRef.id || navBarRouteRef.type === 'group') {
    return true;
  }
  if (historyRouteItem.children) {
    // Si historyRouteItem tiene children, iteramos sobre ellos y comprobamos si alguno coincide
    return historyRouteItem.children.some(childRoute => checkIfRouteMatches(childRoute, navBarRouteRef));
  }
  return false;
};

// ----- Extracted from fuse core --------

export const setRoutes = (config) => {
  let routes = [...config.routes];

  routes = routes.map((route) => {
    const settings = _.merge({}, config.settings, route.settings);
    let scopes;
    if (config.scopes) {
      scopes = config.scopes;
    } else if (route.scopes) {
      scopes = route.scopes;
    } else {
      scopes = undefined;
    }

    return {
      ...route,
      settings,
      scopes,
    };
  });

  return [...routes];
};

export const generateRoutesFromAsyncList = (asyncRoutes) => {
  let allRoutes = [];
  asyncRoutes.forEach((asyncRoute) => {
    allRoutes = [...allRoutes, ...setRoutes(asyncRoute)];
  });
  return allRoutes;
};
