import {
  Access,
  Configuration,
  Create,
  Edit,
  Profile,
  ProfileAdmins,
  PasswordRecovery,
} from './Children';

export const AdminConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      id: 'adminGroup',
      path: 'admin',
      scopes: ['admin-admin-show', 'admin-admin-store', 'admin-admin-update'],
      children: [
        {
          id: 'adminGroup.profile',
          path: '',
          element: <Profile />,
        },
        {
          id: 'adminGroup.profileAdmins',
          path: 'profile/:id',
          element: <ProfileAdmins />,
        },
        {
          scopes: ['admin-admin-store'],
          id: 'adminGroup.create',
          path: 'create',
          element: <Create />,
        },
        {
          scopes: ['admin-admin-update'],
          id: 'adminGroup.edit',
          path: 'edit/:id?',
          element: <Edit />,
        },
        {
          scopes: ['admin-admin-show'],
          id: 'adminGroup.access',
          path: 'access',
          element: <Access />,
        },
        {
          id: 'adminGroup.passwordRecovery',
          path: 'recovery/:id?',
          element: <PasswordRecovery />,
        },
        {
          id: 'adminGroup.configuration',
          path: 'configuration',
          element: <Configuration />,
        },
      ],
    },
  ],
};
