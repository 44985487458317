import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { showMessage } from '../fuse/messageSlice';

export const getConfigs = createAsyncThunk(
  'config/get',
  async (empty, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await fetch('./envpaths.json');
      const envManagerPaths = await response.json();
      console.log(envManagerPaths);
      return envManagerPaths;
    } catch (error) {
      dispatch(
        showMessage({
          message: `Error: ${error}`,
          variant: 'success',
        })
      );
      return rejectWithValue(error);
    }
  }
);
