import request, { prefix } from '../services/request';

export function search(params) {
  return request({
    url: `/${prefix}/fingerprint/search`,
    method: 'POST',
    params,
  });
}

export function searchPage(params, page) {
  return request({
    url: `/${prefix}/fingerprint/search?page=${page}`,
    method: 'POST',
    params,
  });
}

export function deleteFingerprint(id) {
  return request({
    url: `/${prefix}/fingerprint/${id}`,
    method: 'DELETE',
  });
}

export function exportFile(params) {
  return request({
    url: `/${prefix}/fingerprint/export`,
    method: 'POST',
    responseType: 'blob',
    params,
  });
}
