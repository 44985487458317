import { Fingerprints } from './Children';

export const FingerprintsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      id: 'fingerprintsGroup',
      path: 'fingerprints',
      scopes: ['admin-fingerprint-show'],
      children: [
        {
          scopes: ['admin-fingerprint-show'],
          id: 'fingerprintsGroup.fingerprints',
          path: '',
          element: <Fingerprints />,
        },
      ],
    },
  ],
};
