import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { selectUser } from 'app/store/userInfo/userSlice';
import _ from 'lodash';

const Root = styled('div')(({ theme }) => ({
  '& .username, & .email': {
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
}));

function UserNavbarHeader(props) {
  const user = useSelector(selectUser);
  const { config } = useSelector((state) => state.config);
  const { api } = config;

  return (
    <Root className="user relative flex flex-col items-center justify-center p-16 pb-14 shadow-0">
      <div
        className="flex flex-auto items-center justify-center mb-24 w-full h-full"
        style={{
          aspectRatio: '1/1',
          maxWidth: '14rem',
        }}
      >
        {user !== undefined && (
          <Avatar
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: _.has(user, 'background') ? user.background : undefined,
              color: 'text.secondary',
            }}
            className="avatar md:mx-4"
            alt={user.name}
            src={
              _.has(user.avatar, 'fullPathThumb')
                ? `${api?.host}/${user.avatar.fullPathThumb}`
                : undefined
            }
          >
            <p style={{ fontSize: '3.2em', fontWeight: 'normal', color: '#FFF' }}>
              {user.name && user.name
                .split(' ')
                .map((word) => word[0])
                .join('')
                .substring(0, 2)
                .toUpperCase()}
            </p>
          </Avatar>
        )}
      </div>
      <Typography className="username text-14 whitespace-nowrap font-medium">
        {user.name}
      </Typography>
      <Typography className="email text-13 whitespace-nowrap font-medium" color="text.secondary">
        {user.email}
      </Typography>
    </Root>
  );
}

export default UserNavbarHeader;
