/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from '@lodash';
import settingsConfig from 'app/configs/settingsConfig';

export const setUser = createAsyncThunk('user/setUser', async (user, { dispatch, getState }) => {
  /*
    You can redirect the logged-in user to a specific route depending on his role
    */
  if (user.loginRedirectUrl) {
    settingsConfig.loginRedirectUrl = user.loginRedirectUrl; // for example '/apps/academy'
  }

  return user;
});

export const updateUserSettings = createAsyncThunk(
  'user/updateSettings',
  async (settings, { dispatch, getState }) => {
    const { user } = getState();
    const newUser = _.merge({}, user, { data: { settings } });

    // dispatch(updateUserData(newUser));

    return newUser;
  }
);

export const updateUserShortcuts = createAsyncThunk(
  'user/updateShortucts',
  async (shortcuts, { dispatch, getState }) => {
    const { user } = getState();
    const newUser = {
      ...user,
      data: {
        ...user,
        shortcuts,
      },
    };
    // dispatch(updateUserData(newUser));
    return newUser;
  }
);

const initialState = {
  _id: '',
  name: '',
  email: '',
  phone: '',
  company: '',
  avatar: undefined,
  model: 'Admin',
  role: [],
  scopes: [],
  status: false,
  metadata: {},
  background: '#666',
  shortcuts: ['apps.calendar', 'apps.mailbox', 'apps.contacts', 'apps.tasks'],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    removeUserInfo: (state) => initialState,
    setUserInfo: (state, { payload }) => {
      state._id = payload._id;
      state.name = payload.name;
      state.email = payload.email;
      state.phone = payload.phone;
      state.company = payload.company;
      state.avatar = payload.avatar;
      state.role = payload.role;
      state.scopes = payload.scopes;
      state.status = payload.status;
      state.metadata = payload.metadata;
      state.background = payload.background;
      // state.shortcuts = payload.shortcuts;
    },
    updateShortcuts: (state, { payload }) => {
      state.shortcuts = payload.shortcuts;
    },
    setRole: (state, { payload }) => {
      state.role = payload.role;
    },
  },
});

export const { removeUserInfo, setUserInfo, updateShortcuts, setRole } = userSlice.actions;

export const selectUser = ({ user }) => user;

export const selectUserShortcuts = ({ user }) => user.shortcuts;

export default userSlice.reducer;
