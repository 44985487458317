import { forwardRef, useCallback } from 'react';
import _ from '@lodash';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import clsx from 'clsx';
import { Typography } from '@mui/material';

function arraysEqualIgnoringOrder(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  return _.isEqual(_.sortBy(arr1), _.sortBy(arr2));
}

const fieldsetRootStyle = (_theme, fieldset) => {
  if (!fieldset) return {};
  return {
    border: `1px solid ${_theme.palette.divider}`,
    padding: '10px',
    position: 'relative',
    margin: '10px 0',
    borderRadius: '4px',
    '.MuiFormLabel-root': {
      padding: '0 14px',
      position: 'absolute',
      top: '-10px',
      backgroundColor: _theme.palette.common.white,
      borderRadius: '4px',
      // border: `1px solid ${_theme.palette.divider}`,
    },
  };
};

export const ListCheckers = forwardRef(function ListCheckers(
  {
    onChange = () => { },
    sx,
    labelPlacement = 'end',
    fieldset = false,
    selectAllChecker = false,
    selectAllLabel,
    values = [],
    innerClassName,
    className,
    title,
    description,
    data = [],
    horizontal,
    helperText,
    error,
    ...props
  },
  ref
) {
  const handleChangeList = (currentCheck) => (event) => {
    let newPermissionList = [];
    const isEverything = currentCheck === '*';

    if (event.target.checked && !values.includes(currentCheck)) {
      newPermissionList = !isEverything ? [...values, currentCheck] : ['*'];
    } else {
      const filteredPermissions = !isEverything ? values.filter((val) => val !== currentCheck) : [];
      newPermissionList = filteredPermissions;
    }

    const flatData = data.map((item) => item.value);
    const isComplete = arraysEqualIgnoringOrder(newPermissionList, flatData);

    console.log('Is complete?', isComplete);
    if (isComplete && selectAllChecker) {
      const result = event.target.checked ? ['*'] : [];
      onChange(result);
      return;
    }

    onChange(newPermissionList);
  };

  const handleFilterChecker = useCallback(
    (permission) => {
      const hasEverything = values.includes('*');
      const hasPermission = values.includes(permission);
      return hasEverything || hasPermission;
    },
    [values]
  );

  return (
    <FormControl
      component="fieldset"
      variant="standard"
      error={error}
      className={clsx(className, 'px-16')}
      sx={(__theme) => fieldsetRootStyle(__theme, fieldset)}
      {...props}
    >
      {title && (
        <FormLabel
          sx={{ color: (_theme) => _theme.palette.text.primary }}
          className="text-base"
          component="legend"
        >
          {title}
        </FormLabel>
      )}
      <FormGroup
        ref={ref}
        className={clsx(
          innerClassName,
          'flex w-full items-start flex-wrap',
          horizontal ? 'flex-row' : 'flex-col',
          fieldset && 'pt-12'
        )}
      >
        {description && (
          <div className="flex w-full flex-row justify-start">
            <Typography
              sx={{ color: (_theme) => _theme.palette.text.secondary }}
              className="text-sm text-left line-clamp-2"
            >
              {description}
            </Typography>
          </div>
        )}
        {selectAllChecker && (
          <FormControlLabel
            labelPlacement={labelPlacement}
            label={selectAllLabel || 'Todo'}
            control={
              <Checkbox
                inputProps={{ 'aria-label': selectAllLabel || 'Todo' }}
                onChange={handleChangeList('*')}
                checked={handleFilterChecker('*')}
              />
            }
          />
        )}
        {data.map((item, index) => {
          return (
            <FormControlLabel
              labelPlacement={labelPlacement}
              key={`${item.value}_key_${index}`}
              label={item.label}
              control={
                <Checkbox
                  disabled={values.includes('*') && item.values !== '*'}
                  inputProps={{ 'aria-label': item.label }}
                  onChange={handleChangeList(item.value)}
                  checked={handleFilterChecker(item.value)}
                />
              }
            />
          );
        })}
      </FormGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
});
